import React, { useMemo, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, IconButton } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
// @ts-ignore on purpose we do not use a library with types for react-mentions because it is broken - we will use any & unknown to type it
import { Mention, MentionsInput } from 'react-mentions';
import { ReplyText } from './ReplyText';
import { COLORS } from '../../../theme';
import { useAppState } from '../../../state';
import useCirclesChatContext from '../../../hooks/useCirclesChatContext/useCirclesChatContext';
import { ChatMentionData, ChatMessage } from '../chat.types';
import clsx from 'clsx';
import { InMeetingEvent } from '../../../state/useApi/api.types';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { track } from '../../../services/analytics';

const useStyles = makeStyles(theme => ({
  bottomBar: {
    alignItems: 'center',
    backgroundColor: COLORS.WHITE,
    boxShadow: `0 0 ${theme.typography.pxToRem(12)} rgba(0, 0, 0, 0.1)`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    zIndex: 1000,
  },
  content: {
    width: '100%',
    maxWidth: 684,
    display: 'flex',
    position: 'relative',
  },
  textField: {
    margin: theme.spacing(1),
    '& fieldset': {
      borderRadius: 20,
    },
  },
  sendButton: {
    verticalAlign: 'bottom',
    color: theme.colors.BRAND_PURPLE.NORMAL,
    height: 48,
    width: 48,
    margin: 'auto',
  },
  mentions: {
    width: '100%',
    position: 'relative',
    margin: theme.spacing(1),

    '&__control': {
      border: '1px solid rgba(0, 0, 0, 0.23)',
      borderRadius: 20,
      minHeight: '48px',
      maxWidth: '620px',
      fontSize: '18px',
      padding: '14px 14px',
      color: 'rgba(0, 0, 0, 0.87)',
      fontWeight: 400,
    },

    '&__highlighter': {
      overflow: 'hidden',
      border: 'none !important',
    },

    '&__input': {
      margin: 0,
      border: 0,
      outline: 0,
      padding: '14px 14px',
      minHeight: '20px',
    },

    '&__suggestions': {
      width: 'calc(100% + 28px)',
      maxWidth: '684px',
    },

    '&__suggestions__list': {
      position: 'absolute',
      bottom: '20px',
      width: 'calc(100% + 28px)',
      maxWidth: '684px',
      maxHeight: '240px',
      overflow: 'auto',
      backgroundColor: '#FFFFFF',
    },

    '&__suggestions__item': {
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      padding: '10px 5px',
      borderBottom: '1px solid rgba(0, 0, 0, 0.15)',
      height: '80px',

      '&:last-of-type': {
        borderBottom: 0,
      },
    },

    '&__suggestions__item--focused': {
      backgroundColor: '#F0F0F0',
    },
  },
  suggestion: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '10px',
  },
}));

const ENTER_KEY_CODE = 13;

interface IChatInputBar {
  className?: string;
  placeholder: string;
}

export const ChatInputBar = ({ className, placeholder }: IChatInputBar) => {
  const classes = useStyles();

  const inputRef = useRef(null);

  const { account, chatApi } = useAppState();
  const currentUserId = account ? account.id : null;

  const { groupInstanceId, members, replyMessageId, setMessages, setReplyMessageId } = useCirclesChatContext();

  const { localDataTrack } = useVideoContext();

  const [message, setMessage] = useState<string>('');
  const [mentionData, setMentionData] = useState<ChatMentionData | null>(null);

  const mentionSuggestions = useMemo(
    () =>
      Object.values(members)
        .filter(({ id }) => id !== currentUserId)
        .map(({ id, name, picture }) => {
          return {
            id,
            display: name,
            picture,
          };
        }),
    [currentUserId, members]
  );

  const sendMessageHandler = async () => {
    if (currentUserId && groupInstanceId && chatApi?.isReady && message && mentionData) {
      const { newPlainTextValue, mentions } = mentionData;

      mentions.forEach(({ display, id }) => {
        track('click mention on chat message', { name: display, id });
      });

      const requestBody = {
        senderId: currentUserId,
        message: newPlainTextValue,
        privateChat: false,
        replyTo: replyMessageId || null,
        mentions: mentions.length > 0 ? mentions : null,
      };

      await chatApi.sendChatMessage(groupInstanceId, requestBody).then(
        ({ kind, data }) => {
          if (kind === 'ok' && data) {
            localDataTrack.send(
              JSON.stringify({
                data,
                event: InMeetingEvent.CirclesChatNewMessage,
              })
            );

            const { name, picture, ...messageData } = data; // we do not want enriched message

            setMessages((prevState: ChatMessage[]) =>
              [...prevState, messageData].sort(
                (messageA: ChatMessage, messageB: ChatMessage) => messageB.timestamp - messageA.timestamp
              )
            );

            setMessage('');
            setReplyMessageId('');
            setMentionData(null);
          }
        },
        () => console.log('sendMessageHandler - chatApi.sendChatMessage - error')
      );
    }
  };

  // type any here because of react-mentions
  const handleChange = (event: { target: { value: any } }, newValue: any, newPlainTextValue: any, mentions: any) => {
    setMessage(newValue);
    setMentionData({ newValue, newPlainTextValue, mentions });
  };

  // type any here because of react-mentions
  const handleKeyDown = (event: any) => {
    if (event.keyCode === ENTER_KEY_CODE) {
      event.preventDefault();
      sendMessageHandler();
    }
  };

  const onSendClick = () => {
    // @ts-ignore
    inputRef.current && inputRef.current.focus();
    sendMessageHandler();
  };

  return (
    <div className={clsx(classes.bottomBar, className || '')}>
      <ReplyText replyMessageId={replyMessageId} setReplyMessageId={setReplyMessageId} />
      <div className={classes.content}>
        <MentionsInput
          id="message-input"
          className={classes.mentions}
          inputRef={inputRef}
          placeholder={placeholder}
          autoFocus={true}
          value={message}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          markup="@{{__type__||__id__||__display__}}"
        >
          <Mention
            type="member"
            trigger="@"
            data={mentionSuggestions}
            renderSuggestion={(suggestion: any, search: unknown, highlightedDisplay: any) => (
              <div className={classes.suggestion}>
                <Avatar alt={suggestion.name} src={suggestion.picture} />
                <div>{highlightedDisplay}</div>
              </div>
            )}
            displayTransform={(id: unknown, display: unknown) => `@${display}`}
          />
        </MentionsInput>
        <IconButton disabled={!message} onClick={onSendClick} className={classes.sendButton}>
          <SendIcon />
        </IconButton>
      </div>
    </div>
  );
};
