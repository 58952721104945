const MAX_PARTICIPANTS_PER_ROW = 5;
export const MAX_PARTICIPANTS_PER_SCREEN = 9;

export function calcMaxParticipantsPerRow(numOfParticipants: number): number {
  if (numOfParticipants < 3) {
    return numOfParticipants;
  }
  if (numOfParticipants <= MAX_PARTICIPANTS_PER_ROW) {
    return Math.ceil(numOfParticipants / 2);
  }

  let numOfRows = Math.ceil(numOfParticipants / MAX_PARTICIPANTS_PER_ROW);
  let participantPerRow = Math.ceil(numOfParticipants / numOfRows);
  while (participantPerRow >= MAX_PARTICIPANTS_PER_ROW) {
    numOfRows++;
    participantPerRow = Math.ceil(numOfParticipants / numOfRows);
  }
  return participantPerRow;
}

export function calcColumns({
  numOfParticipants,
  maxParticipantPerRow,
  participantIdx,
}: {
  numOfParticipants: number;
  maxParticipantPerRow: number;
  participantIdx: number;
}): number {
  const rowNum = Math.floor(participantIdx / maxParticipantPerRow) + 1;
  const fullLine = rowNum * maxParticipantPerRow <= numOfParticipants;
  let xs = fullLine
    ? Math.floor(12 / maxParticipantPerRow)
    : Math.floor(12 / (numOfParticipants - maxParticipantPerRow * (rowNum - 1)));
  if (xs < 6 && numOfParticipants <= maxParticipantPerRow) {
    xs = 6;
  }
  if (xs > 6 && numOfParticipants > maxParticipantPerRow) {
    xs = Math.floor(12 / maxParticipantPerRow);
  }

  return xs;
}
