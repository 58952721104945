import { ApisauceInstance, create } from 'apisauce';
import { getGeneralApiProblem } from './api-problem';
import { ApiConfig, DEFAULT_API_CONFIG } from './api-config';
import { recordError, ErrorType } from '../../utils/exceptions';

export class Api {
  apisauce: ApisauceInstance;
  config: ApiConfig;

  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config;
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: 'application/json',
      },
    });
  }

  setAuthorizationHeader = (newToken?: string) => {
    if (newToken) {
      this.apisauce.setHeader('Authorization', `${newToken}`);
    } else {
      this.apisauce.deleteHeader('Authorization');
    }
  };

  processResponse = async (response: any): Promise<any> => {
    // the typical ways to die when calling an api
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) {
        recordError(ErrorType.API, {
          ...response,
          message: response.config.url,
        });

        if (problem.kind === 'unauthorized') {
          if (this.config.unit === 'main') {
            window.localStorage.removeItem('accessToken');
            this.setAuthorizationHeader();
          }
        }

        // ensure data exists
        if (!('data' in problem)) {
          problem['data'] = {};
        }

        return problem;
      }
    }

    const data = response.data;
    return { kind: 'ok', data };
  };

  get = async <T>(url: string): Promise<T> => {
    const response = await this.apisauce.get(url);
    return this.processResponse(response);
  };

  patch = async <T>(url: string, data?: unknown): Promise<T> => {
    const response = await this.apisauce.patch(url, data);
    return this.processResponse(response);
  };

  post = async <T>(url: string, data: unknown): Promise<T> => {
    const response = await this.apisauce.post(url, data);
    return this.processResponse(response);
  };
}
