import React from 'react';
import { Typography } from '@material-ui/core';
import { TextProps } from './text.props';
import Presets from './text.presets';

import { translate } from '../../../i18n/translate';

export function Text(props: TextProps) {
  const { children, text, tx, txOptions, preset, ...rest } = props;

  const i18nText = tx && translate(tx, txOptions);
  const content = text || i18nText || children;

  const variant = Presets[preset];

  return (
    <Typography {...rest} variant={variant as any}>
      {content}
    </Typography>
  );
}
