import React, { useCallback, useRef } from 'react';

import { Button } from '../../DesignSystem';
import VideoOffIcon from '../../../icons/VideoOffIcon';
import VideoOnIcon from '../../../icons/VideoOnIcon';

import useDevices from '../../../hooks/useDevices/useDevices';
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';

import { track } from '../../../services/analytics';

export default function ToggleVideoButton(props: {
  disabled?: boolean;
  className?: string;
  iconColor: string;
  iconDisableLineColor: string;
}) {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { hasVideoInputDevices } = useDevices();

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  return (
    <Button
      className={props.className}
      onClick={() => {
        const eventName = isVideoEnabled ? 'click disable video' : 'click enable video';
        track(eventName);
        toggleVideo();
      }}
      disabled={!hasVideoInputDevices || props.disabled}
    >
      {isVideoEnabled ? (
        <VideoOnIcon color={props.iconColor} />
      ) : (
        <VideoOffIcon color={props.iconColor} lineColor={props.iconDisableLineColor} />
      )}
    </Button>
  );
}
