import { io, Socket } from 'socket.io-client';
import { useEffect, useMemo, useState } from 'react';
import useVideoContext from '../useVideoContext/useVideoContext';

export interface ICurrentActiveUser {
  from: number; // millis
  name: string;
  userId: string;
}

enum Events {
  Connect = 'connect',
  ConnectError = 'connect_error',
  Disconnect = 'disconnect',
  DominantSpeakerChange = 'dominant-speaker-change',
}

export const useMeetingAnalyzerSocket = () => {
  const [socket, setSocket] = useState<Socket | undefined>(undefined);
  const [currentActiveUser, setCurrentActiveUser] = useState<ICurrentActiveUser | undefined>(undefined);
  const { room, meetingSocketToken } = useVideoContext();
  const groupInstanceId = useMemo(() => room?.name || '', [room]);

  useEffect(() => {
    if (groupInstanceId && meetingSocketToken) {
      const SOCKET_URL = process.env.REACT_APP_MEETING_ANALYZER_SOCKET_URL || '';

      const newSocket = io(SOCKET_URL, {
        auth: {
          token: meetingSocketToken,
        },
        query: { groupInstanceId },
        transports: process.env.REACT_APP_ENV === 'local' ? ['polling'] : ['websocket'],
      });
      setSocket(newSocket);
    }
  }, [groupInstanceId, meetingSocketToken]);

  useEffect(() => {
    if (socket) {
      socket.on(Events.Connect, data => {
        console.debug(`${Events.Connect}`, { data });
      });

      socket.on(Events.ConnectError, data => {
        console.debug(`${Events.ConnectError}`, { data });
      });

      socket.on(Events.Disconnect, data => {
        console.debug(`${Events.Disconnect}`, { data });
      });

      socket.on(Events.DominantSpeakerChange, (data: ICurrentActiveUser) => {
        setCurrentActiveUser(data);
      });
    }

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [socket]);

  return { currentActiveUser };
};
