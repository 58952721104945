import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core';
import { Transition } from 'react-transition-group';

import { Text } from '../DesignSystem';

import { getParticipantBkgColor, getParticipantName } from '../../utils';

import HuggingEmoji from '../../assets/images/emotionalEmojis/hugging.png';
import RedHeartEmoji from '../../assets/images/emotionalEmojis/red-heart.png';
import AngryEmoji from '../../assets/images/emotionalEmojis/angry.png';
import AstonishedEmoji from '../../assets/images/emotionalEmojis/astonished.png';
import CryingEmoji from '../../assets/images/emotionalEmojis/crying.png';
import SmilingEyesEmoji from '../../assets/images/emotionalEmojis/smiling-eyes.png';
import TearsOfJoyEmoji from '../../assets/images/emotionalEmojis/tears-of-joy.png';

import { EmotionalEmojis } from '../VideoProvider/useEmotionalEmojis/useEmotionalEmojis';

interface ReceivedEmojiProps {
  emoji: EmotionalEmojis;
  centerOffset: { left: number; right: number };
  senderName: string;
  onAnimationEnd: () => void;
}

const ANIMATION_DURATION = '3s';
const BOTTOM_START = 20;
const BOTTOM_END = '800px';
const WRAPPER_CIRCLE_SIZE_END = '200px';
const IMAGE_SIZE_END = '160px';

interface StyleProps {
  left: number;
  right: number;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    paddingLeft: ({ left }) => `${left}px`,
    paddingRight: ({ right }) => `${right}px`,
  },
  avatar: {
    position: 'absolute',
    bottom: BOTTOM_START,
    width: theme.avatarCircleSize,
    height: theme.avatarCircleSize,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 100,
  },
  avatarName: {
    color: theme.colors.WHITE,
  },
  animationStart: {
    zIndex: 99,
    margin: 'auto',
    position: 'absolute',
    backgroundColor: '#FFFFFF',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: `opacity ${ANIMATION_DURATION}, width ${ANIMATION_DURATION}, height ${ANIMATION_DURATION}, bottom ${ANIMATION_DURATION}`,
    opacity: 1,
    width: theme.avatarCircleSize - 2,
    height: theme.avatarCircleSize - 2,
    bottom: BOTTOM_START + 1,
  },
  animationEnd: {
    opacity: 0.5,
    width: WRAPPER_CIRCLE_SIZE_END,
    height: WRAPPER_CIRCLE_SIZE_END,
    bottom: BOTTOM_END,
  },
  imageAnimationStart: {
    transition: `opacity ${ANIMATION_DURATION}, width ${ANIMATION_DURATION}, height ${ANIMATION_DURATION}`,
    opacity: 1,
    width: (theme.avatarCircleSize - 2) / 2,
    height: (theme.avatarCircleSize - 2) / 2,
  },
  imageAnimationEnd: {
    opacity: 0.5,
    width: IMAGE_SIZE_END,
    height: IMAGE_SIZE_END,
  },
}));

export default function ReceivedEmoji({ emoji, senderName, onAnimationEnd, centerOffset }: ReceivedEmojiProps) {
  const classes = useStyles({ ...centerOffset });
  const [inProp, setInProp] = useState(false);

  useEffect(() => {
    setInProp(true);
  }, []);

  const emojisMap = {
    [EmotionalEmojis.ANGRY]: AngryEmoji,
    [EmotionalEmojis.ASTONISHED]: AstonishedEmoji,
    [EmotionalEmojis.CRYING]: CryingEmoji,
    [EmotionalEmojis.HUGGING]: HuggingEmoji,
    [EmotionalEmojis.RED_HEART]: RedHeartEmoji,
    [EmotionalEmojis.SMILIING_EYES]: SmilingEyesEmoji,
    [EmotionalEmojis.TEARS_OF_JOY]: TearsOfJoyEmoji,
  };

  return (
    <div className={classes.container}>
      <Transition in={inProp} timeout={100}>
        {state => {
          return (
            <div className={clsx(classes.animationStart, state === 'entered' ? classes.animationEnd : null)}>
              <img
                src={emojisMap[emoji]}
                alt=""
                className={clsx(classes.imageAnimationStart, state === 'entered' ? classes.imageAnimationEnd : null)}
              />
            </div>
          );
        }}
      </Transition>
      <Transition in={inProp} timeout={800}>
        {state => {
          return (
            <div className={clsx(classes.animationStart, state === 'entered' ? classes.animationEnd : null)}>
              <img
                src={emojisMap[emoji]}
                alt=""
                className={clsx(classes.imageAnimationStart, state === 'entered' ? classes.imageAnimationEnd : null)}
              />
            </div>
          );
        }}
      </Transition>
      <Transition
        in={inProp}
        timeout={1600}
        onEntered={() => {
          setTimeout(() => {
            onAnimationEnd();
          }, 3000);
        }}
      >
        {state => {
          return (
            <div className={clsx(classes.animationStart, state === 'entered' ? classes.animationEnd : null)}>
              <img
                src={emojisMap[emoji]}
                alt=""
                className={clsx(classes.imageAnimationStart, state === 'entered' ? classes.imageAnimationEnd : null)}
              />
            </div>
          );
        }}
      </Transition>
      <div className={classes.avatar} style={{ backgroundColor: getParticipantBkgColor(senderName) }}>
        <Text preset="Paragraph" text={getParticipantName(senderName)} className={classes.avatarName} />
      </div>
    </div>
  );
}
