import React from 'react';
import { Text } from '../DesignSystem/Text';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContainer: {
      background: theme.colors.WHITE,
      paddingTop: '2em',
      paddingBottom: '2em',
      width: 500,
      position: 'relative',
      borderRadius: 4,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        height: 447,
        width: 280,
      },
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
    },
    close: {
      position: 'absolute',
      right: '8px',
      top: '8px',
    },
    thankYouText: {
      width: '80%',
      justifyContent: 'center',
      alignItems: 'center',
      paddingLeft: 'auto',
      paddingRight: 'auto',
    },
  })
);

interface SurveySubmittedPopUpProps {
  handleSurveySubmittedPopUpClose: () => void;
}

const SurveySubmittedPopUp = ({ handleSurveySubmittedPopUpClose }: SurveySubmittedPopUpProps) => {
  const classes = useStyles();

  const handleClose = () => {
    handleSurveySubmittedPopUpClose();
  };

  return (
    <Modal
      open={true}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.modal}>
        <div className={classes.modalContainer}>
          <div className={classes.close} onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </div>
          <Text preset="SubHeader" tx="survey.thankYouTitle" gutterBottom />
          <Text preset="Paragraph" tx="survey.thankYou" className={classes.thankYouText} />
        </div>
      </div>
    </Modal>
  );
};

export default SurveySubmittedPopUp;
