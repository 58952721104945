import { CalendarEvent } from './calendar.types';
import { isAndroid, isIOS } from 'react-device-detect';

const isMobile = isAndroid || isIOS ? true : false;

export enum SHARE_SITES {
  GOOGLE = 'Google',
  ICAL = 'iCal',
  OUTLOOK = 'Outlook',
  YAHOO = 'Yahoo',
}

export const formatDate = (date: string) => date && date.replace('+00:00', 'Z');

export const isInternetExplorer = () =>
  /MSIE/.test(window.navigator.userAgent) || /Trident/.test(window.navigator.userAgent);

export const escapeICSDescription = (description: string) => description.replace(/(\r?\n|<br ?\/?>)/g, '\\n');

const googleShareUrl = ({ description, endDatetime, location, startDatetime, timezone, title, recur }: CalendarEvent) =>
  `https://calendar.google.com/calendar/render?action=TEMPLATE&dates=${startDatetime}/${endDatetime}${timezone &&
    `&ctz=${timezone}`}&location=${location}&text=${title}&details=${description}&recur=${recur}`;

const yahooShareUrl = ({ description, duration, location, startDatetime, title }: CalendarEvent) =>
  `https://calendar.yahoo.com/?v=60&view=d&type=20&title=${title}&st=${startDatetime}&dur=${duration}&desc=${description}&in_loc=${location}`;

const buildShareFile = ({
  description,
  endDatetime,
  location = '',
  startDatetime,
  timezone = '',
  title,
  recur,
}: CalendarEvent) => {
  const content = [
    'BEGIN:VCALENDAR',
    'VERSION:2.0',
    'BEGIN:VEVENT',
    `URL:${document.URL}`,
    'METHOD:PUBLISH',
    timezone === '' ? `DTSTART:${startDatetime}` : `DTSTART;TZID=${timezone}:${startDatetime}`,
    timezone === '' ? `DTEND:${endDatetime}` : `DTEND;TZID=${timezone}:${endDatetime}`,
    `SUMMARY:${title}`,
    `DESCRIPTION:${escapeICSDescription(description)}`,
    `LOCATION:${location}`,
    recur,
    'END:VEVENT',
    'END:VCALENDAR',
  ].join('\n');
  return isMobile ? encodeURI(`data:text/calendar;charset=utf8,${content}`) : content;
};

export const buildShareUrl = (
  {
    description,
    duration,
    endDatetime = '',
    location = '',
    startDatetime,
    timezone = '', // blank means UTC
    title,
    recur,
  }: CalendarEvent,
  type: SHARE_SITES
) => {
  const encodeURI = type !== SHARE_SITES.ICAL && type !== SHARE_SITES.OUTLOOK;

  const data = {
    description: encodeURI ? encodeURIComponent(description) : description,
    duration,
    endDatetime: formatDate(endDatetime),
    location: encodeURI ? encodeURIComponent(location) : location,
    startDatetime: formatDate(startDatetime),
    timezone,
    title: encodeURI ? encodeURIComponent(title) : title,
    recur: encodeURI ? encodeURIComponent(recur) : recur,
  };

  switch (type) {
    case SHARE_SITES.GOOGLE:
      return googleShareUrl(data);
    case SHARE_SITES.YAHOO:
      return yahooShareUrl(data);
    default:
      return buildShareFile(data);
  }
};
