import React from 'react';

export const MessagesIcon = () => {
  return (
    <svg width="27" height="27" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.25 4C1.25 2.20507 2.70507 0.75 4.5 0.75H22.5C24.2949 0.75 25.75 2.20507 25.75 4V15.2704C25.75 17.0653 24.2949 18.5204 22.5 18.5204H6.07176C5.52246 18.5204 5.00503 18.7783 4.67436 19.2169L5.27325 19.6684L4.67436 19.2169L1.69963 23.1628C1.55519 23.3544 1.25 23.2523 1.25 23.0123L1.25 19.2704V4Z"
        stroke="#9E9E9E"
        strokeWidth="1.5"
      />
      <path
        d="M5.94611 5.53122H21.5045M5.94611 9.81251H21.5045M5.94611 14.0938H12.9666"
        stroke="#9E9E9E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
