export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
  width: 1280,
  height: 720,
  frameRate: 24,
};

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'TwilioVideoApp-selectedAudioInput';
export const SELECTED_AUDIO_OUTPUT_KEY = 'TwilioVideoApp-selectedAudioOutput';
export const SELECTED_VIDEO_INPUT_KEY = 'TwilioVideoApp-selectedVideoInput';

export const PARTICIPANT_JOINED_NOTIFICATIION = 5000;

export const ROOM_NOTIFICATION = 5000;

export const GOOGLE_PLAY_URL = 'https://play.google.com/store/apps/details?id=com.sevenchairs';
export const APP_STORE_URL = 'https://apps.apple.com/us/app/circles-support/id1540905564';
