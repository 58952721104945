// Error classifications used to sort errors on error reporting services.
export enum ErrorType {
  // An API service related error.
  API,

  // An error that would normally cause a red screen in dev and forced the user to signout and restart.
  FATAL,

  // An error caught by try/catch where defined using Reactotron.tron.error.
  HANDLED,
}

// Manually record a handled error.
export const recordError = (type: ErrorType, error: object) => {
  console.error('recordError() Type:', type, ' error:', error);
};

// Handle uncaught exceptions
export const handleExceptions = (allowInDev = false) => {
  console.error('handleExceptions() allowInDev:', allowInDev);
};
