import React, { useState } from 'react';
import { makeStyles, Grid, Theme } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
import SettingsMenu from './SettingsMenu/SettingsMenu';
import { Steps } from '../PreJoinScreens';
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import { useAppState } from '../../../state';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { track } from '../../../services/analytics';

import { Text, Button } from '../../DesignSystem';

import { COLORS } from '../../../theme';
import { strSnakeToCamel } from '../../../utils';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '8px',
  },
  localPreviewContainer: {
    width: '290px',
    filter: 'drop-shadow(0px 8px 24px rgba(0, 0, 0, 0.12))',
    backgroundColor: theme.colors.WHITE,
  },
  previewText: {
    color: theme.colors.GRAY[500],
    marginBottom: '4px',
  },
  audioVideoButtonsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '8px',
  },
  audioVideoButton: {
    border: `1.5px solid ${theme.colors.BLACK}`,
    margin: '4px',
    width: '44px',
    minWidth: 'unset',
    height: '44px',
    minHeight: 'unset',
  },
  joinButton: {
    width: '164px',
    marginTop: '8px',
  },
  spinner: {
    color: theme.colors.GRAY[500],
  },
}));

interface DeviceSelectionScreenProps {
  name: string;
  roomId?: string;
  roomName?: string;
  setStep?: (step: Steps) => void;
}

export default function DeviceSelectionScreen({ name, roomId, roomName = '' }: DeviceSelectionScreenProps) {
  const classes = useStyles();
  const { mainApi, analyzerApi, setError } = useAppState();
  const { connect: chatConnect } = useChatContext();
  const { connect: videoConnect, isAcquiringLocalTracks, isConnecting, setMeetingSocketToken } = useVideoContext();
  const [isFetching, setIsFetching] = useState(false);
  const disableButtons = !roomId || isFetching || isAcquiringLocalTracks || isConnecting;

  const handleJoin = () => {
    track('click join meeting');

    setIsFetching(true);
    mainApi?.getRoomToken(roomId!).then(({ kind, data }) => {
      if (kind === 'forbidden') {
        const errorMessage = data?.message ? strSnakeToCamel(data.message) : 'default';
        setError({ tx: `errors.${errorMessage}`, code: 403, message: kind, name: 'getRoomToken' });
        track('view join meeting error', { error: errorMessage });
      }
      const token = data?.token;
      const meetingStatsToken = data?.meetingStatsToken;
      const meetingSocketToken = data?.meetingSocketToken;
      const connectOptions = data?.connectOptions || {};
      setIsFetching(false);
      if (token) {
        // add to localStorage the time member joined the meeting
        const joinedSessions = window.localStorage.getItem('joinedSessions');
        if (
          !joinedSessions ||
          (joinedSessions && moment().diff(moment(JSON.parse(joinedSessions)[roomId!]), 'days') > 0)
        ) {
          window.localStorage.setItem('joinedSessions', JSON.stringify({ [roomId!]: Date.now() }));
        }

        videoConnect(token, connectOptions);
        process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && chatConnect(token);
      }
      if (meetingStatsToken) {
        analyzerApi?.setAuthToken(meetingStatsToken);
      }
      if (meetingSocketToken) {
        setMeetingSocketToken(meetingSocketToken);
      }
    });
  };

  if (isFetching || isConnecting) {
    return (
      <Grid container justify="center" alignItems="center" direction="column" style={{ height: '100%' }}>
        <div>
          <CircularProgress variant="indeterminate" className={classes.spinner} />
        </div>
        <div>
          <Text preset="SubHeader" tx="preJoin.joiningMeeting" />
        </div>
      </Grid>
    );
  }

  return (
    <>
      <Text preset="Label1" tx="preJoin.joinRoom" txOptions={{ roomName }} className={classes.gutterBottom} />
      <Text preset="Label2" tx="common.preview" className={classes.previewText} />
      <div className={classes.localPreviewContainer}>
        <LocalVideoPreview identity={name} />
        <div className={classes.audioVideoButtonsWrapper}>
          <ToggleAudioButton
            iconColor={COLORS.BLACK}
            iconDisableLineColor={COLORS.RED[500]}
            className={classes.audioVideoButton}
            disabled={disableButtons}
          />
          <ToggleVideoButton
            iconColor={COLORS.BLACK}
            iconDisableLineColor={COLORS.RED[500]}
            className={classes.audioVideoButton}
            disabled={disableButtons}
          />
        </div>
      </div>
      <SettingsMenu />
      <Grid container direction="column" alignItems="center">
        <Button
          variant="contained"
          color="primary"
          className={classes.joinButton}
          data-cy-join-now
          onClick={handleJoin}
          disabled={disableButtons}
          tx="preJoin.joinNow"
        />
      </Grid>
    </>
  );
}
