import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { LocalAudioTrack, LocalVideoTrack, Participant, RemoteAudioTrack, RemoteVideoTrack } from 'twilio-video';
import AudioLevelIndicator from '../AudioLevelIndicator/AudioLevelIndicator';
import NetworkQualityLevel from '../NetworkQualityLevel/NetworkQualityLevel';
import PinIcon from './PinIcon/PinIcon';
import ScreenShareIcon from '../../icons/ScreenShareIcon';
import { Text } from '../DesignSystem';
import GameCard from '../GameCard/GameCard';
import AddEmojiStrip from '../EmotionalEmojis/AddEmojiStrip';
import RemoteReceivedEmoji from '../EmotionalEmojis/RemoteReceivedEmoji';
import useIsTrackEnabled from '../../hooks/useIsTrackEnabled/useIsTrackEnabled';
import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff';
import usePublications from '../../hooks/usePublications/usePublications';
import useTrack from '../../hooks/useTrack/useTrack';
import useParticipantIsReconnecting from '../../hooks/useParticipantIsReconnecting/useParticipantIsReconnecting';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { useAppState } from '../../state';
import { track } from '../../services/analytics';
import { getParticipantName, getParticipantBkgColor } from '../../utils';
import { PARTICIPANT_JOINED_NOTIFICATIION } from '../../constants';
import { CardStatus } from '../VideoProvider/useCardGame/useCardGame';
import { EmotionalEmoji } from '../VideoProvider/useEmotionalEmojis/useEmotionalEmojis';

interface ParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
  onClick?: () => void;
  isSelected?: boolean;
  isLocalParticipant?: boolean;
  hideParticipant?: boolean;
  isDominantSpeaker?: boolean;
  joinedTime?: number;
  emotionalEmojis?: EmotionalEmoji[];
  onEmojiDisplayed?: (receiverId: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      flex: 1,
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      overflow: 'hidden',
      marginBottom: '2em',
      '& video': {
        filter: 'none',
      },
      borderRadius: '2px',
      border: `${theme.participantBorderWidth}px solid ${theme.colors.GRAY[800]}`,
      background: 'black',
      [theme.breakpoints.down('sm')]: {
        marginRight: '2px',
        marginLeft: '2px',
        marginBottom: '0',
        fontSize: '10px',
      },
    },
    dominantSpeakerContainer: {
      border: `${theme.dominantSpeakerBorderWidth}px solid #9900FA`,
    },
    innerContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    infoContainer: {
      position: 'absolute',
      zIndex: 2,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      width: '100%',
      background: 'transparent',
      top: 0,
    },
    avatarContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: theme.colors.LIGHT_BLACK,
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      zIndex: 1,
      [theme.breakpoints.down('sm')]: {
        '& svg': {
          transform: 'scale(0.7)',
        },
      },
    },
    avatar: {
      width: theme.avatarCircleSize,
      height: theme.avatarCircleSize,
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    avatarName: {
      color: theme.colors.WHITE,
    },
    reconnectingContainer: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(40, 42, 43, 0.75)',
      zIndex: 1,
    },
    screenShareIconContainer: {
      background: 'rgba(0, 0, 0, 0.5)',
      padding: '0.18em 0.3em',
      marginRight: '0.3em',
      display: 'flex',
      '& path': {
        fill: 'white',
      },
    },
    identity: {
      background: 'rgba(0, 0, 0, 0.5)',
      color: 'white',
      padding: '0.18em 0.3em',
      margin: 0,
      display: 'flex',
      alignItems: 'center',
    },
    infoRowBottom: {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'absolute',
      bottom: 0,
      left: 0,
    },
    networkQualityContainer: {
      width: '28px',
      height: '28px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(0, 0, 0, 0.5)',
    },
    newJoinContainer: {
      position: 'absolute',
      top: '8px',
      left: '36px',
      right: '36px',
      paddingLeft: '16px',
      paddingRight: '16px',
      backgroundColor: theme.colors.GREEN[300],
      borderRadius: '4px',
    },
    newJoinText: {
      color: theme.colors.WHITE,
      textAlign: 'center',
    },
    typeography: {
      color: theme.colors.WHITE,
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.75rem',
      },
    },
    hideParticipant: {
      display: 'none',
    },
    cursorPointer: {
      cursor: 'pointer',
    },
    gameCardContainer: {
      position: 'absolute',
      bottom: 20,
      right: 20,
    },
    gameCardNextMemberContainer: {
      position: 'absolute',
      top: '24px',
      left: '36px',
      right: '36px',
      paddingLeft: '16px',
      paddingRight: '16px',
      backgroundColor: theme.colors.VIBRANT_BLUE.NORMAL,
      borderRadius: '4px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    gameCardNextMemberText: {
      color: theme.colors.WHITE,
      fontSize: '18px',
      fontWeight: 600,
    },
    emotionalEmojiContainer: {
      position: 'absolute',
      bottom: 16,
      right: 16,
    },
  })
);

export default function ParticipantInfo({
  participant,
  onClick,
  isSelected,
  children,
  isLocalParticipant,
  hideParticipant,
  isDominantSpeaker = false,
  joinedTime,
  emotionalEmojis = [],
  onEmojiDisplayed = () => null,
}: ParticipantInfoProps) {
  const { isMeetingHost, account, group } = useAppState();
  const { cardGameService, emotionalEmojisService } = useVideoContext();
  const { nextMember, cards, flipCard } = cardGameService;
  const { sendEmoji } = emotionalEmojisService;

  const publications = usePublications(participant);

  const audioPublication = publications.find(p => p.kind === 'audio');
  const videoPublication = publications.find(p => p.trackName.includes('camera'));

  const isVideoEnabled = Boolean(videoPublication);
  const isScreenShareEnabled = publications.find(p => p.trackName.includes('screen'));

  const videoTrack = useTrack(videoPublication);

  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack as LocalVideoTrack | RemoteVideoTrack);
  const isVideoTrackEnabled = useIsTrackEnabled(videoTrack as LocalVideoTrack | RemoteVideoTrack);

  const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack | undefined;
  const isParticipantReconnecting = useParticipantIsReconnecting(participant);

  const participantName = group?.members[participant.identity]?.name || 'Anon';

  const classes = useStyles();

  return (
    <div
      className={clsx(classes.container, {
        [classes.hideParticipant]: hideParticipant,
        [classes.cursorPointer]: Boolean(onClick),
        [classes.dominantSpeakerContainer]: isDominantSpeaker,
      })}
      onClick={onClick}
      data-cy-participant={participant.identity}
    >
      <div className={classes.infoContainer}>
        <div className={classes.networkQualityContainer}>
          <NetworkQualityLevel participant={participant} />
        </div>
        {!isLocalParticipant && (
          <AddEmojiStrip
            onEmojiClicked={emoji => sendEmoji({ emoji, receiverId: participant.sid, senderName: account!.name })}
          />
        )}
        {typeof joinedTime === 'number' && Date.now() - joinedTime < PARTICIPANT_JOINED_NOTIFICATIION && (
          <div className={classes.newJoinContainer}>
            <Text
              preset="Paragraph"
              tx="inMeeting.newParticipantJoin"
              txOptions={{ name: participantName }}
              className={classes.newJoinText}
            />
          </div>
        )}
        <div className={classes.infoRowBottom}>
          {isScreenShareEnabled && (
            <span className={classes.screenShareIconContainer}>
              <ScreenShareIcon />
            </span>
          )}
          <span className={classes.identity}>
            <AudioLevelIndicator audioTrack={audioTrack} />
            <Text
              preset="Paragraph"
              className={classes.typeography}
              tx="inMeeting.myselfName"
              txOptions={{ name: participantName }}
              text={isLocalParticipant ? undefined : participantName}
            />
          </span>
        </div>
        {cards[participant.sid] && (
          <div className={classes.gameCardContainer}>
            <GameCard
              card={cards[participant.sid]}
              clickable={
                (isLocalParticipant || isMeetingHost) &&
                cards[participant.sid].status === CardStatus.Close &&
                nextMember?.sid === participant.sid
              }
              onClick={() => {
                track('click flip card game', { isMeetingHost });
                flipCard(participant.sid, CardStatus.Open, true);
              }}
            />
          </div>
        )}
        {isLocalParticipant &&
          nextMember?.sid === participant.sid &&
          cards[participant.sid]?.status === CardStatus.Close && (
            <div className={classes.gameCardNextMemberContainer}>
              <Text
                preset="Paragraph"
                tx="gfDashboard.cardGames.turnToFlipCard"
                className={classes.gameCardNextMemberText}
              />
            </div>
          )}
        {emotionalEmojis.length > 0 && (
          <div className={classes.emotionalEmojiContainer}>
            <RemoteReceivedEmoji
              senderName={emotionalEmojis[0].senderName}
              emoji={emotionalEmojis[0].emoji}
              onAnimationEnd={() => onEmojiDisplayed(participant.sid)}
            />
          </div>
        )}
        <div>{isSelected && <PinIcon />}</div>
      </div>
      <div className={classes.innerContainer}>
        {(!isVideoEnabled || isVideoSwitchedOff || !isVideoTrackEnabled) && (
          <div className={classes.avatarContainer}>
            <div className={classes.avatar} style={{ backgroundColor: getParticipantBkgColor(participantName) }}>
              <Text preset="Paragraph" text={getParticipantName(participantName)} className={classes.avatarName} />
            </div>
          </div>
        )}
        {isParticipantReconnecting && (
          <div className={classes.reconnectingContainer}>
            <Text preset="Paragraph" className={classes.typeography} tx="inMeeting.reconnecting" />
          </div>
        )}
        {children}
      </div>
    </div>
  );
}
