import React, { useState, useEffect, useMemo } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { RemoteParticipant } from 'twilio-video';

import Participant from './Participant';

import { SessionInfo, SessionInfoUser } from '../../../state/useApi/api.types';

interface ParticipantsStatsProps {
  sessionInfo: SessionInfo;
  participants: RemoteParticipant[];
  accountId: string;
  isMeetingHost: boolean;
  toggleAudioStatus: (userId: string, status: string) => void;
}

const SILENT_ALERT_INTERVAL = 5 * 60 * 1000; // 5 min in millis

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '0px 16px',
    overflowY: 'auto',
    marginTop: '8px',
    marginBottom: '8px',
  },
}));

export default function ParticipantsStats({
  accountId,
  sessionInfo,
  participants,
  isMeetingHost,
  toggleAudioStatus,
}: ParticipantsStatsProps) {
  const classes = useStyles();
  const [maxSeconds, setMaxSeconds] = useState(0);

  useEffect(() => {
    let ms = 0;
    sessionInfo.users.forEach(u => {
      if (u.totalActiveSeconds > ms) {
        ms = u.totalActiveSeconds;
      }
    });
    setMaxSeconds(ms);
  }, [sessionInfo]);

  const participantsMap: Record<string, RemoteParticipant> = useMemo(() => {
    const m: Record<string, RemoteParticipant> = {};
    participants.forEach(p => {
      m[p.sid] = p;
    });
    return m;
  }, [participants]);

  const isSilnetAlert = (participant: SessionInfoUser) => {
    if (sessionInfo.currentActiveUser?.userId === participant.userId) {
      return false;
    }
    if ((participant.left || 0) > (participant.joined || -1)) {
      return false;
    }
    return Date.now() - participant.lastActive > SILENT_ALERT_INTERVAL;
  };

  const isLeft = (participant: SessionInfoUser) => {
    if (participant.accountId === accountId) {
      return false;
    }
    // if (process.env.REACT_APP_ENV === 'local') {
    //   return (participant.left || 0) > (participant.joined || -1);
    // }
    return !participantsMap[participant.userId];
  };

  return (
    <div className={classes.container}>
      {sessionInfo.users.map((u, idx) => {
        const left = isLeft(u);
        return (
          <Participant
            key={`ps-${idx}-${sessionInfo.ts}`}
            participant={u}
            maxSeconds={maxSeconds}
            silnetAlert={!left && isSilnetAlert(u)}
            left={left}
            canMute={isMeetingHost && u.accountId !== accountId}
            remoteParticipant={participantsMap[u.userId]}
            toggleAudioStatus={toggleAudioStatus}
          />
        );
      })}
    </div>
  );
}
