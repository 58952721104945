const Presets = {
  Header: 'h1',
  SubHeader: 'h2',
  Cta: 'button',
  Paragraph: 'body1',
  Label1: 'subtitle1',
  Label2: 'subtitle2',
  Link: 'caption',
};

export type TextPresets = keyof typeof Presets;

export default Presets;
