import React, { useMemo } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { useAppState } from '../../state';
import { Text } from '../DesignSystem';

import { NOTIFICATON_TYPES } from './roomNotifications.types';

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      position: 'absolute',
      top: 0,
      left: 0,
      padding: '20px',
      maxWidth: '640px',
      zIndex: 1000,
    },
    notificationContainer: {
      backgroundColor: theme.colors.GREEN[300],
      borderRadius: '4px',
      marginBottom: '8px',
      padding: '4px 8px',
      overflowWrap: 'break-word',
    },
    notificationText: {
      color: theme.colors.WHITE,
    },
  };
});

export default function RoomNotifications() {
  const classes = useStyles();

  const { roomNotifications } = useVideoContext();

  const { group } = useAppState();

  const contentMap = useMemo(
    () => ({
      [NOTIFICATON_TYPES.MUTED_BY_HOST]: {
        tx: 'roomNotifications.mutedByHost',
        txOptions: { name: group?.groupLeaderName || 'Meeting host' },
      },
      [NOTIFICATON_TYPES.UNMUTED_BY_HOST]: {
        tx: 'roomNotifications.unmutedByHost',
        txOptions: { name: group?.groupLeaderName || 'Meeting host' },
      },
    }),
    [group]
  );

  return (
    <div className={classes.container}>
      {roomNotifications.map(({ ts, type }) => {
        const { tx, txOptions = {} } = contentMap[type];
        return (
          <div key={`not-${ts}`} className={classes.notificationContainer}>
            <Text preset="Label1" tx={tx} txOptions={txOptions} className={classes.notificationText} />
          </div>
        );
      })}
    </div>
  );
}
