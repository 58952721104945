import React, { useCallback, useMemo } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Button, Text } from '../../DesignSystem';
import { MessagesIcon } from '../../../icons/MessagesIcon';
import useCirclesChatContext from '../../../hooks/useCirclesChatContext/useCirclesChatContext';
import { ROLE } from '../../../state/useAccount/useAccount';
import { useAppState } from '../../../state';
import { Theme } from '@material-ui/core';
import { MessagesIconWithIndicator } from '../../../icons/MessagesIconWithIndicator';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: 'transparent',
    },
    buttonLabel: {
      flexDirection: 'column',
    },
    text: {
      color: theme.colors.GRAY[500],
    },
  })
);

export const CirclesChatButton = () => {
  const classes = useStyles();

  const { account, isMeetingHost } = useAppState();
  const {
    setMeetingHostChatSelected,
    setNonMemberChatSelected,
    setMemberChatSelected,
    unreadMessagesCounter,
  } = useCirclesChatContext();

  const GFRole = useMemo(
    () => [ROLE.ADMIN, ROLE.GROUP_LEADER, ROLE.PEER_GROUP_LEADER].includes(account?.role || ROLE.MEMBER),
    [account]
  );

  const onClick = useCallback(() => {
    if (GFRole) {
      if (isMeetingHost) {
        setMeetingHostChatSelected(true);
      } else {
        setNonMemberChatSelected((prevState: boolean) => !prevState);
      }
    } else {
      setMemberChatSelected((prevState: boolean) => !prevState);
    }
  }, [GFRole, setMeetingHostChatSelected, setNonMemberChatSelected, setMemberChatSelected, isMeetingHost]);

  return (
    <>
      <Button className={classes.button} classes={{ label: classes.buttonLabel }} onClick={onClick}>
        {unreadMessagesCounter ? <MessagesIconWithIndicator /> : <MessagesIcon />}
        <Text preset="Label1" tx="menuBar.chat" className={classes.text} />
      </Button>
    </>
  );
};
