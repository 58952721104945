import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Screen } from '../ParticipantListScreens/ParticipantListScreens';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '5px',
      background: theme.colors.BLACK,
    },
    dotCommon: {
      width: '12.7px',
      height: '12.7px',
      borderRadius: '50%',
      cursor: 'pointer',
    },
    dotActive: {
      background: theme.colors.WHITE,
    },
    dotInactive: {
      background: '#424242',
    },
  })
);

interface ParticipantListScreenSwitchProps {
  screens: Screen[];
  currentScreenIndex: number;
  handleSetCurrentScreenIndex: (index: number) => void;
}

export default function ParticipantListScreenSwitch({
  screens,
  currentScreenIndex,
  handleSetCurrentScreenIndex,
}: ParticipantListScreenSwitchProps) {
  const classes = useStyles();

  if (screens.length > 1) {
    return (
      <div className={classes.root}>
        {screens.map(({ index }) => (
          <div
            className={clsx(classes.dotCommon, {
              [classes.dotActive]: index === currentScreenIndex,
              [classes.dotInactive]: index !== currentScreenIndex,
            })}
            onClick={() => handleSetCurrentScreenIndex(index)}
          />
        ))}
      </div>
    );
  }

  return null;
}
