import { useEffect, useCallback } from 'react';
import { RemoteDataTrack, RemoteParticipant } from 'twilio-video';

import { useAppState } from '../../state';

import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

import { track as trackEvent } from '../analytics';

import { InMeetingEvent } from '../../state/useApi/api.types';
import { CardStatus } from '../../components/VideoProvider/useCardGame/useCardGame';
import useCirclesChatContext from '../../hooks/useCirclesChatContext/useCirclesChatContext';
import { ChatMessage } from '../../components/CirclesChat/chat.types';

interface IProps {
  participants: RemoteParticipant[];
}

export const useDataTrackListener = ({ participants }: IProps) => {
  const { isChatFocused, setMessages, setUnreadMessagesCounter } = useCirclesChatContext();
  const { room, cardGameService, emotionalEmojisService, onToggledAudioByHost } = useVideoContext();
  const { flipCard, onCardsChange, onNextMemberChange, onGameEnd } = cardGameService;
  const { onEmojiReceived } = emotionalEmojisService;
  const { setEndedByHost } = useAppState();
  const localParticipant = room!.localParticipant;

  const disconnectByHost = useCallback(() => {
    setEndedByHost(true);
    room!.disconnect();
  }, [room, setEndedByHost]);

  const memberFlipCard = useCallback(
    (userId: string, status: CardStatus) => {
      flipCard(userId, status);
    },
    [flipCard]
  );

  const nextMemberChanged = useCallback(
    (sid: string, name: string) => {
      onNextMemberChange(sid, name);
    },
    [onNextMemberChange]
  );

  const gameCardsChanged = useCallback(
    (cards: any, nextMember: { userId: string; name: string } | null) => {
      onCardsChange(cards, nextMember);
    },
    [onCardsChange]
  );

  const gameEnd = useCallback(() => {
    onGameEnd();
  }, [onGameEnd]);

  const emojiReceived = useCallback(
    (emoji: string, senderName: string, receiverId: string) => {
      onEmojiReceived({ senderName, emoji, receiverId, isLocalParticipant: receiverId === localParticipant.sid });
    },
    [onEmojiReceived, localParticipant]
  );

  const toggleAudioByHost = useCallback(
    (userId: string, status: 'muted' | 'unmuted') => {
      if (userId === localParticipant.sid) {
        trackEvent('host modified audio state', { status });
        onToggledAudioByHost(status);
      }
    },
    [onToggledAudioByHost, localParticipant]
  );

  useEffect(() => {
    const eventHandler = (data: string | ArrayBuffer, track: RemoteDataTrack) => {
      if (track.kind === 'data' && typeof data === 'string') {
        const msg = JSON.parse(data);
        if (msg?.event === InMeetingEvent.EndedByHost) {
          disconnectByHost();
        }
        if (msg?.event === InMeetingEvent.MemberFlipCard && msg?.userId && msg?.status) {
          memberFlipCard(msg.userId, msg.status);
        }
        if (msg?.event === InMeetingEvent.NextMemberCard && msg?.userId && msg?.name) {
          nextMemberChanged(msg.userId, msg.name);
        }
        if (msg?.event === InMeetingEvent.PublishGameCards && msg?.cards) {
          gameCardsChanged(msg.cards, msg.nextMember);
        }
        if (msg?.event === InMeetingEvent.CardGameEnd) {
          gameEnd();
        }
        if (msg?.event === InMeetingEvent.PublishEmitionalEmoji && msg?.emoji && msg?.senderName && msg?.receiverId) {
          emojiReceived(msg.emoji, msg.senderName, msg.receiverId);
        }
        if (msg?.event === InMeetingEvent.ToggleAudioStatus && msg?.userId && msg?.status) {
          toggleAudioByHost(msg.userId, msg.status);
        }
        if (msg?.event === InMeetingEvent.CirclesChatNewMessage && msg?.data) {
          if (!isChatFocused) {
            setUnreadMessagesCounter((prevState: number) => prevState + 1);
          }
          setMessages((prevState: ChatMessage[]) =>
            [...prevState, msg.data].sort(
              (messageA: ChatMessage, messageB: ChatMessage) => messageB.timestamp - messageA.timestamp
            )
          );
        }
        if (msg?.event === InMeetingEvent.CirclesChatDeleteMessage && msg?.data) {
          setMessages((prevState: ChatMessage[]) =>
            prevState.map((chatMessage: ChatMessage) => {
              if (chatMessage.id === msg.data.messageId) {
                return { ...chatMessage, deleted: true };
              } else {
                return chatMessage;
              }
            })
          );
        }
      }
    };

    participants.forEach(participant => participant.on('trackMessage', eventHandler));

    return () => {
      participants.forEach(participant => participant.off('trackMessage', eventHandler));
    };
  }, [
    participants,
    disconnectByHost,
    memberFlipCard,
    nextMemberChanged,
    gameCardsChanged,
    gameEnd,
    emojiReceived,
    toggleAudioByHost,
    setMessages,
    isChatFocused,
    setUnreadMessagesCounter,
  ]);
};
