import React from 'react';
import ParticipantInfo from '../ParticipantInfo/ParticipantInfo';
import ParticipantTracks from '../ParticipantTracks/ParticipantTracks';
import { Participant as IParticipant } from 'twilio-video';

import { EmotionalEmoji } from '../VideoProvider/useEmotionalEmojis/useEmotionalEmojis';

interface ParticipantProps {
  participant: IParticipant;
  videoOnly?: boolean;
  audioOnly?: boolean;
  enableScreenShare?: boolean;
  onClick?: () => void;
  isSelected?: boolean;
  isDominantSpeaker?: boolean;
  isLocalParticipant?: boolean;
  hideParticipant?: boolean;
  joinedTime?: number;
  emotionalEmojis?: EmotionalEmoji[];
  onEmojiDisplayed?: (receiverId: string) => void;
}

export default function Participant({
  participant,
  videoOnly,
  audioOnly,
  enableScreenShare,
  onClick,
  isSelected,
  isLocalParticipant,
  hideParticipant,
  isDominantSpeaker,
  joinedTime,
  emotionalEmojis,
  onEmojiDisplayed,
}: ParticipantProps) {
  return (
    <ParticipantInfo
      participant={participant}
      onClick={onClick}
      isSelected={isSelected}
      isLocalParticipant={isLocalParticipant}
      hideParticipant={hideParticipant}
      isDominantSpeaker={isDominantSpeaker}
      joinedTime={joinedTime}
      emotionalEmojis={emotionalEmojis}
      onEmojiDisplayed={onEmojiDisplayed}
    >
      <ParticipantTracks
        participant={participant}
        videoOnly={videoOnly}
        audioOnly={audioOnly}
        enableScreenShare={enableScreenShare}
        isLocalParticipant={isLocalParticipant}
      />
    </ParticipantInfo>
  );
}
