import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Star, StarBorder } from '@material-ui/icons';
import { Text } from '../DesignSystem/Text';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    starsDiv: {
      display: 'flex',
      alignItems: 'center',
    },
    star: {
      height: '100%',
      padding: 4,
    },
    pointer: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    clickableIcon: {
      fontSize: 48,
      [theme.breakpoints.down('sm')]: {
        fontSize: 36,
      },
    },
    nonClickableIcon: {
      fontSize: 24,
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
      },
    },
    filledStar: {
      color: theme.colors.BRAND_PURPLE.NORMAL,
    },
    unfilledStar: {
      color: theme.colors.GRAY[400],
    },
    indicatorsDiv: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  })
);

interface RateProps {
  highName: string;
  lowName: string;
  scale: number;
  onChange: (newRate: number) => void;
}

const Rate = ({ highName, lowName, scale, onChange }: RateProps) => {
  const classes = useStyles();
  const [stars, setStars] = React.useState<Array<number>>([]);
  const [rate, setRate] = React.useState(0);

  React.useEffect(() => {
    const starsArr = [];
    for (let i = 0; i < scale; i++) {
      starsArr.push(i);
    }
    setStars(starsArr);
  }, [scale]);

  return (
    <div className={classes.root}>
      <div className={classes.starsDiv}>
        {stars.map((s, idx) => {
          const icon =
            idx < rate ? (
              <Star className={clsx(classes.clickableIcon, classes.filledStar)} />
            ) : (
              <StarBorder className={clsx(classes.clickableIcon, classes.unfilledStar)} />
            );
          return (
            <div
              key={`star-${idx}`}
              className={clsx(classes.star, classes.pointer)}
              onClick={() => {
                setRate(idx + 1);
                onChange(idx + 1);
              }}
            >
              {icon}
            </div>
          );
        })}
      </div>
      <div className={classes.indicatorsDiv}>
        <Text preset="Label2">{highName}</Text>
        <Text preset="Label2">{lowName}</Text>
      </div>
    </div>
  );
};

export default Rate;
