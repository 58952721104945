import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';

import CirclesLogo from '../../assets/svgComponents/CirclesLogo';
import CirclesBackground from '../../assets/images/circlesBackground.png';

import { COLORS } from '../../theme';

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: theme.colors.BLACK,
    height: '100%',
  },
  container: {
    position: 'relative',
    flex: '1',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '16px',
      paddingBottom: '16px',
    },
  },
  innerContainer: {
    display: 'flex',
    width: '838px',
    height: '462px',
    borderRadius: '8px',
    overflow: 'hidden',
    position: 'relative',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      height: 'auto',
      width: 'calc(100% - 40px)',
      margin: 'auto',
      maxWidth: '400px',
    },
  },
  logoContainer: {
    position: 'relative',
    display: 'flex',
    width: '364px',
    height: '462px',
    borderRadius: '8px',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      height: '260px',
    },
  },
  backgroundImage: {
    position: 'absolute',
    objectFit: 'none',
    height: '462px',
    width: '364px',
    [theme.breakpoints.down('sm')]: {
      height: '260px',
      width: '100%',
    },
  },
  logo: {
    zIndex: 1000,
  },
  content: {
    background: 'white',
    width: '100%',
    padding: '44px',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      padding: '2em',
    },
  },
  subContentContainer: {
    position: 'absolute',
    marginTop: '1em',
    width: '100%',
  },
}));

interface IntroContainerProps {
  children: React.ReactNode;
  subContent?: React.ReactNode;
}

const IntroContainer = (props: IntroContainerProps) => {
  const classes = useStyles();

  return (
    <div className={classes.background}>
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <div className={classes.logoContainer}>
            <img src={CirclesBackground} className={classes.backgroundImage} alt="" />
            <CirclesLogo bkgCircleColor={COLORS.WHITE} textColor={COLORS.BLACK} className={classes.logo} />
          </div>
          <div className={classes.content}>{props.children}</div>
        </div>
        {props.subContent && <div className={classes.subContentContainer}>{props.subContent}</div>}
      </div>
    </div>
  );
};

export default IntroContainer;
