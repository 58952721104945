import { nanoid } from 'nanoid';
import { useAppState } from '../../../../state';
import { useState, useEffect } from 'react';

export default function useGetPreflightTokens() {
  const { mainApi } = useAppState();
  const [tokens, setTokens] = useState<[string | undefined, string | undefined]>();
  const [tokenError, setTokenError] = useState<Error>();
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (!isFetching && !tokens) {
      const roomName = 'preflight-network-test-' + nanoid();

      setIsFetching(true);

      Promise.all([mainApi?.getRoomToken(roomName), mainApi?.getRoomToken(roomName)])
        .then(newTokens => {
          setTokens([newTokens[0]!.data!.token, newTokens[1]!.data!.token]);
          setIsFetching(false);
        })
        .catch(error => setTokenError(error));
    }
  }, [mainApi, isFetching, tokens]);

  return { tokens, tokenError };
}
