import React, { useCallback, useEffect, useState } from 'react';
import { RemoteParticipant } from 'twilio-video';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import Tabs, { OptionalTabs } from './Tabs/Tabs';
import ParticipantsStats from './ParticipantsStats/ParticipantsStats';
import { useAppState } from '../../state';
import { InMeetingEvent, SessionInfo } from '../../state/useApi/api.types';
import { ActivitiesList } from './Activities/ActivitiesList';
import { ROLE } from '../../state/useAccount/useAccount';
import { GroupChat } from './Activities/GroupChat/GroupChat';
import { Box } from '@material-ui/core';
import useCirclesChatContext from '../../hooks/useCirclesChatContext/useCirclesChatContext';
import clsx from 'clsx';
import { ChatInputBar } from '../CirclesChat/Input/ChatInputBar';
import { translate } from '../../i18n';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.colors.LIGHT_BLACK,
      zIndex: 100,
      display: 'flex',
      flexDirection: 'column',
    },
    chatInputBar: {
      borderRadius: '0 0 8px 8px',
    },
    margin16px: {
      margin: '16px',
    },
    maxHeight80vh: {
      maxHeight: '80vh', // This number guarantee that chat (list + input) will not overflow UI
    },
    overflowYAuto: {
      overflowY: 'auto',
    },
  })
);

let fetchSessionInfoInterval: NodeJS.Timeout | null = null;

const CHAT_FOR_MEMBERS_SCROLL_ID = 'CHAT_FOR_MEMBERS_SCROLL_ID';

interface IAsidePanel {
  participants: RemoteParticipant[];
}

export const AsidePanel = ({ participants }: IAsidePanel) => {
  const classes = useStyles();
  const { analyzerApi, group, account, isMeetingHost } = useAppState();
  const {
    error,
    isLoading,
    isMeetingHostChatSelected,
    isNonMemberChatSelected,
    isMemberChatSelected,
  } = useCirclesChatContext();
  const { localDataTrack } = useVideoContext();
  const [selectedTab, setSelectedTab] = useState<OptionalTabs>(OptionalTabs.ParticipantStats);
  const [sessionInfo, setSessionInfo] = useState<SessionInfo | null>(null);

  const fetchSessionInfo = useCallback(async () => {
    if (analyzerApi?.isReady) {
      const { data } = await analyzerApi!.getSessionInfo(group!.id);
      if (data) {
        setSessionInfo(data);
      }
    }
  }, [analyzerApi, group]);

  useEffect(() => {
    if (selectedTab === OptionalTabs.ParticipantStats) {
      fetchSessionInfo();
      fetchSessionInfoInterval = setInterval(fetchSessionInfo, 10 * 1000);
    } else if (fetchSessionInfoInterval !== null) {
      clearInterval(fetchSessionInfoInterval);
      fetchSessionInfoInterval = null;
    }
    return () => {
      if (fetchSessionInfoInterval !== null) {
        clearInterval(fetchSessionInfoInterval);
        fetchSessionInfoInterval = null;
      }
    };
  }, [selectedTab, fetchSessionInfo]);

  const toggleAudioStatus = (userId: string, status: string) => {
    localDataTrack.send(
      JSON.stringify({
        status,
        userId,
        event: InMeetingEvent.ToggleAudioStatus,
      })
    );
  };

  useEffect(() => {
    if (isMeetingHostChatSelected) {
      setSelectedTab(OptionalTabs.Activities);
    }
  }, [isMeetingHostChatSelected]);

  const GFRole = [ROLE.ADMIN, ROLE.GROUP_LEADER, ROLE.PEER_GROUP_LEADER].includes(account?.role || ROLE.MEMBER);

  return (
    <aside className={classes.container}>
      {GFRole && !isNonMemberChatSelected && (
        <>
          <Tabs
            isMeetingHost={isMeetingHost}
            selectedTab={selectedTab}
            onTabSelect={setSelectedTab}
            numOfParticipants={sessionInfo?.users.length || 0}
          />
          {selectedTab === OptionalTabs.ParticipantStats && sessionInfo && (
            <ParticipantsStats
              sessionInfo={sessionInfo}
              participants={participants}
              accountId={account!.id}
              isMeetingHost={isMeetingHost}
              toggleAudioStatus={toggleAudioStatus}
            />
          )}
          {isMeetingHost && selectedTab === OptionalTabs.Activities && <ActivitiesList />}
        </>
      )}
      {GFRole && isNonMemberChatSelected && (
        <Box className={classes.margin16px}>
          <Box className={clsx(classes.maxHeight80vh, classes.overflowYAuto)} id={CHAT_FOR_MEMBERS_SCROLL_ID}>
            <GroupChat messagesListContainerScrollId={CHAT_FOR_MEMBERS_SCROLL_ID} />
          </Box>
          {!error && !isLoading ? (
            <ChatInputBar className={classes.chatInputBar} placeholder={translate('circlesChat.inputPlaceholder')} />
          ) : null}
        </Box>
      )}
      {!GFRole && isMemberChatSelected && (
        <Box className={classes.margin16px}>
          <Box className={clsx(classes.maxHeight80vh, classes.overflowYAuto)} id={CHAT_FOR_MEMBERS_SCROLL_ID}>
            <GroupChat messagesListContainerScrollId={CHAT_FOR_MEMBERS_SCROLL_ID} />
          </Box>
          {!error && !isLoading ? (
            <ChatInputBar className={classes.chatInputBar} placeholder={translate('circlesChat.inputPlaceholder')} />
          ) : null}
        </Box>
      )}
    </aside>
  );
};
