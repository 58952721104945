import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useAppState } from '../../state';
import { ChatMessage } from './chat.types';
import { GroupMember } from '../../state/useGroup/useGroup';
import { translate } from '../../i18n';

type CirclesChatContextType = {
  error: string;
  groupInstanceId: string;
  isChatFocused: boolean;
  isMeetingHostChatSelected: boolean;
  isNonMemberChatSelected: boolean;
  isLoading: boolean;
  isMemberChatSelected: boolean;
  members: Record<string, GroupMember>;
  messages: ChatMessage[];
  replyMessageId: string;
  setChatFocused: React.Dispatch<React.SetStateAction<boolean>>;
  setMeetingHostChatSelected: React.Dispatch<React.SetStateAction<boolean>>;
  setNonMemberChatSelected: React.Dispatch<React.SetStateAction<boolean>>;
  setMemberChatSelected: React.Dispatch<React.SetStateAction<boolean>>;
  setMessages: React.Dispatch<React.SetStateAction<ChatMessage[]>>;
  setReplyMessageId: React.Dispatch<React.SetStateAction<string>>;
  setUnreadMessagesCounter: React.Dispatch<React.SetStateAction<number>>;
  unreadMessagesCounter: number;
};

export const CirclesChatContext = createContext<CirclesChatContextType>(null!);

export const CirclesChatProvider: React.FC = ({ children }) => {
  const { group, mainApi } = useAppState();

  const groupInstanceId = useMemo(() => (group ? group.id : ''), [group]);
  const members = useMemo(() => (group ? group.members : {}), [group]);

  const [error, setError] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isChatFocused, setChatFocused] = useState<boolean>(false);
  const [isMeetingHostChatSelected, setMeetingHostChatSelected] = useState<boolean>(false);
  const [isNonMemberChatSelected, setNonMemberChatSelected] = useState<boolean>(false);
  const [isMemberChatSelected, setMemberChatSelected] = useState<boolean>(false);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [replyMessageId, setReplyMessageId] = useState<string>('');
  const [unreadMessagesCounter, setUnreadMessagesCounter] = useState<number>(0);

  useEffect(() => {
    if (groupInstanceId && mainApi?.isReady) {
      const openChat = async () => {
        const { data, kind } = await mainApi.fetchChatMessages(groupInstanceId);
        if (data && kind === 'ok') {
          setMessages(
            data[0].sort((messageA: ChatMessage, messageB: ChatMessage) => messageB.timestamp - messageA.timestamp)
          );
        }
      };
      setError('');
      setLoading(true);
      openChat().then(
        () => {
          setLoading(false);
        },
        () => {
          setLoading(false);
          setError(translate('circlesChat.error'));
        }
      );
    }
  }, [groupInstanceId, mainApi]);

  useEffect(() => {
    if (isChatFocused) {
      setUnreadMessagesCounter(0);
    }
  }, [isChatFocused]);

  return (
    <CirclesChatContext.Provider
      value={{
        error,
        groupInstanceId,
        isChatFocused,
        isLoading,
        isMeetingHostChatSelected,
        isNonMemberChatSelected,
        isMemberChatSelected,
        members,
        messages,
        replyMessageId,
        setChatFocused,
        setNonMemberChatSelected,
        setMeetingHostChatSelected,
        setMemberChatSelected,
        setMessages,
        setReplyMessageId,
        setUnreadMessagesCounter,
        unreadMessagesCounter,
      }}
    >
      {children}
    </CirclesChatContext.Provider>
  );
};
