import React from 'react';
import { Text } from '../../DesignSystem/Text';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppleStore from '../../../assets/images/apple.png';
import GoogleStore from '../../../assets/images/google.png';

interface BlockMobileScreenProps {
  isAndroid: boolean;
  storeUrl: string | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    lightText: {
      marginTop: 4,
      marginBottom: 4,
      color: theme.colors.GRAY[500],
    },
    centerText: {
      marginTop: 24,
      color: theme.colors.GRAY[500],
      display: 'flex',
      justifyContent: 'center',
    },
    centerImg: {
      marginTop: 4,
      display: 'flex',
      justifyContent: 'center',
    },
  })
);

const BlockMobileScreen = ({ isAndroid, storeUrl }: BlockMobileScreenProps) => {
  const classes = useStyles();

  const URL_SCHEME = 'circlesup://';

  const onViewClick = () => {
    window.location.replace(URL_SCHEME);
    setTimeout(() => {
      // means app is not installed
      onStoreClick();
    }, 500);
  };

  const onStoreClick = () => {
    if (storeUrl) {
      window.open(storeUrl, '_blank');
    }
    return null;
  };

  return (
    <div>
      <Text preset="SubHeader" tx="blockMobile.title" gutterBottom />
      <Text preset="Paragraph" tx="blockMobile.subtitleDownload" />

      <Text preset="Label2" tx="blockMobile.or" className={classes.lightText} />
      <Text preset="Paragraph" tx="blockMobile.subtitleOpen" />
      <Text preset="Label2" tx="blockMobile.downloadHere" className={classes.centerText} />
      <div className={classes.centerImg} onClick={onViewClick}>
        <a href={storeUrl} target="_blank" rel="noreferrer">
          <img width={129} height={40} src={isAndroid ? GoogleStore : AppleStore} alt="" />
        </a>
      </div>
    </div>
  );
};

export default BlockMobileScreen;
