import React, { useEffect } from 'react';
import { MessagesList } from '../../../CirclesChat/Messages/MessagesList';
import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import useCirclesChatContext from '../../../../hooks/useCirclesChatContext/useCirclesChatContext';
import { Text } from '../../../DesignSystem';

const useStyles = makeStyles(theme => ({
  circularProgress: {
    color: theme.colors.BRAND_PURPLE.NORMAL,
  },
  error: {
    color: theme.colors.WHITE,
  },
  informationWrapper: {
    alignItems: 'center',
    display: 'flex',
    height: '70vh',
    justifyContent: 'center',
  },
  messagesList: {
    borderRadius: '8px 8px 0 0',
  },
}));

interface IGroupChat {
  messagesListContainerScrollId: string;
}

export const GroupChat = ({ messagesListContainerScrollId }: IGroupChat) => {
  const classes = useStyles();

  const { error, isLoading, setChatFocused } = useCirclesChatContext();

  useEffect(() => {
    setChatFocused(true);
    return () => {
      setChatFocused(false);
    };
  }, [setChatFocused]);

  if (error) {
    return (
      <Box className={classes.informationWrapper}>
        <Text className={classes.error} preset="Label1" text={error} />
      </Box>
    );
  }

  if (isLoading) {
    return (
      <Box className={classes.informationWrapper}>
        <CircularProgress className={classes.circularProgress} />
      </Box>
    );
  }

  return (
    <MessagesList className={classes.messagesList} messagesListContainerScrollId={messagesListContainerScrollId} />
  );
};
