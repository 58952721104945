import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import App from './App';
import AppStateProvider, { useAppState } from './state';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import theme from './theme';
import './types';
import { ChatProvider } from './components/ChatProvider';
import { VideoProvider } from './components/VideoProvider';
import useConnectionOptions from './utils/useConnectionOptions/useConnectionOptions';
import UnsupportedBrowserWarning from './components/UnsupportedBrowserWarning/UnsupportedBrowserWarning';

// needed in order to init i18n
import './i18n';

Sentry.init({
  dsn: 'https://58a3652c1c8645048ea47ed60a75d6f4@o480420.ingest.sentry.io/5809951',
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.REACT_APP_ENV,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const VideoApp = () => {
  const { error, setError, mainApi, isMeetingHost, group } = useAppState();
  const connectionOptions = useConnectionOptions();

  return (
    <VideoProvider
      options={connectionOptions}
      onError={setError}
      mainApi={mainApi}
      isMeetingHost={isMeetingHost}
      group={group}
    >
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      <ChatProvider>
        <App />
      </ChatProvider>
    </VideoProvider>
  );
};

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <UnsupportedBrowserWarning>
      <Router>
        <AppStateProvider>
          <Switch>
            <Route exact path="/">
              <VideoApp />
            </Route>
            <PrivateRoute path="/token/:URLAccessToken/room/:URLRoomId">
              <VideoApp />
            </PrivateRoute>
            <PrivateRoute path="/room/:URLRoomId">
              <VideoApp />
            </PrivateRoute>
            <Redirect to="/token/undefined/room" />
          </Switch>
        </AppStateProvider>
      </Router>
    </UnsupportedBrowserWarning>
  </MuiThemeProvider>,
  document.getElementById('root')
);
