import React, { useEffect, useState } from 'react';
import { Box, Grid, IconButton, makeStyles, Theme } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { CardGame } from '../../../state/useApi/api.types';
import { useAppState } from '../../../state';
import GameItem, { GameState } from './CardGames/GameItem';
import GameList from './CardGames/GameList';
import { COLORS } from '../../../theme';
import { CardGameIcon } from '../../../icons/CardGameIcon';
import { MessagesIcon } from '../../../icons/MessagesIcon';
import { Text } from '../../DesignSystem';
import clsx from 'clsx';
import { GroupChat } from './GroupChat/GroupChat';
import useCirclesChatContext from '../../../hooks/useCirclesChatContext/useCirclesChatContext';
import { MessagesIconWithIndicator } from '../../../icons/MessagesIconWithIndicator';
import { translate } from '../../../i18n';
import { ChatInputBar } from '../../CirclesChat/Input/ChatInputBar';

const useStyles = makeStyles((theme: Theme) => ({
  activityCard: {
    alignItems: 'center',
    backgroundColor: COLORS.BLACK,
    borderRadius: '8px',
    cursor: 'pointer',
    display: 'flex',
    marginBottom: '8px',
    minHeight: '73px',
    padding: '16px',
  },
  backContainer: {
    alignItems: 'center',
    backgroundColor: COLORS.BLACK,
    borderRadius: '8px',
    display: 'flex',
    height: '33px',
    justifyContent: 'center',
    marginBottom: '16px',
    width: '33px',
  },
  chatInputBar: {
    borderRadius: '0 0 8px 8px',
  },
  colorGray500: {
    color: theme.colors.GRAY[500],
  },
  fontSize16px: {
    fontSize: '16px',
  },
  marginLeft8px: {
    marginLeft: '8px',
  },
  maxHeight70vh: {
    maxHeight: '70vh', // This number guarantee that chat (list + input) will not overflow UI
  },
  maxHeight75vh: {
    maxHeight: '75vh', // This number guarantee that box for games will not overflow UI
  },
  overflowYAuto: {
    overflowY: 'auto',
  },
  wrapper: {
    margin: '16px',
  },
}));

enum Activity {
  Chat = 'Chat',
  Game = 'Game',
  None = 'None',
}

const CHAT_FOR_GFS_SCROLL_ID = 'CHAT_FOR_GFS_SCROLL_ID';

export const ActivitiesList = () => {
  const classes = useStyles();

  const [activity, setActivity] = useState<Activity>(Activity.None);
  const [cardGames, setCardGames] = useState<CardGame[]>([]);

  const { mainApi } = useAppState();
  const {
    error,
    isMeetingHostChatSelected,
    isLoading,
    setMeetingHostChatSelected,
    unreadMessagesCounter,
  } = useCirclesChatContext();

  const {
    cardGameService: {
      game,
      startGame,
      endGame,
      restartGame,
      nextMember,
      shuffleNextMember,
      canPlay,
      canShuffleNextMember,
    },
  } = useVideoContext();

  useEffect(() => {
    const fetchCardGames = async () => {
      const { data: games } = await mainApi!.getCardGames();
      if (games) {
        setCardGames(games);
      }
    };
    if (mainApi?.isReady) {
      fetchCardGames();
    }
  }, [mainApi]);

  useEffect(() => {
    if (isMeetingHostChatSelected) {
      setActivity(Activity.Chat);
      setMeetingHostChatSelected(false);
    }
  }, [isMeetingHostChatSelected, setMeetingHostChatSelected]);

  return (
    <Box className={classes.wrapper}>
      {activity !== Activity.None && (
        <Grid container>
          <Box className={classes.backContainer}>
            <IconButton aria-label="back" className={classes.colorGray500} onClick={() => setActivity(Activity.None)}>
              <ArrowBackIcon className={classes.fontSize16px} />
            </IconButton>
          </Box>
        </Grid>
      )}
      {activity === Activity.None && (
        <Grid container direction="column">
          <Box className={classes.activityCard} onClick={() => setActivity(Activity.Chat)}>
            {unreadMessagesCounter ? <MessagesIconWithIndicator /> : <MessagesIcon />}
            <Text
              className={clsx(classes.colorGray500, classes.marginLeft8px)}
              preset="Label1"
              tx="activitiesList.groupChat"
            />
            {unreadMessagesCounter ? (
              <Text className={clsx(classes.colorGray500, classes.marginLeft8px)} preset="Label1">
                {`(${unreadMessagesCounter} ${translate('activitiesList.groupChatNewMessages')})`}
              </Text>
            ) : null}
          </Box>
          <Box className={classes.activityCard} onClick={() => setActivity(Activity.Game)}>
            <CardGameIcon />
            <Text
              className={clsx(classes.colorGray500, classes.marginLeft8px)}
              preset="Label1"
              tx="activitiesList.cardGame"
            />
          </Box>
        </Grid>
      )}
      <Box
        className={clsx(
          activity === Activity.Chat ? classes.maxHeight70vh : classes.maxHeight75vh,
          classes.overflowYAuto
        )}
        id={CHAT_FOR_GFS_SCROLL_ID}
      >
        <>
          {activity === Activity.Chat ? <GroupChat messagesListContainerScrollId={CHAT_FOR_GFS_SCROLL_ID} /> : null}
          {activity === Activity.Game && !game ? (
            <GameList cardGames={cardGames} startGame={startGame} canPlay={canPlay} />
          ) : null}
          {activity === Activity.Game && game ? (
            <Box>
              <GameItem
                game={game}
                state={GameState.ACTIVE}
                onEnd={endGame}
                onRestart={restartGame}
                nextMember={nextMember}
                shuffleNextMember={shuffleNextMember}
                canPlay={canPlay}
                canShuffleNextMember={canShuffleNextMember}
              />
            </Box>
          ) : null}
        </>
      </Box>
      {activity === Activity.Chat && !error && !isLoading ? (
        <ChatInputBar className={classes.chatInputBar} placeholder={translate('circlesChat.inputPlaceholder')} />
      ) : null}
    </Box>
  );
};
