import React, { createContext, ReactNode, useCallback, useState } from 'react';
import {
  CreateLocalTrackOptions,
  ConnectOptions,
  LocalDataTrack,
  LocalAudioTrack,
  LocalVideoTrack,
  Room,
} from 'twilio-video';
import { ErrorCallback } from '../../types';
import { SelectedParticipantProvider } from './useSelectedParticipant/useSelectedParticipant';

import AttachVisibilityHandler from './AttachVisibilityHandler/AttachVisibilityHandler';
import useHandleRoomDisconnection from './useHandleRoomDisconnection/useHandleRoomDisconnection';
import useHandleTrackPublicationFailed from './useHandleTrackPublicationFailed/useHandleTrackPublicationFailed';
import useLocalTracks from './useLocalTracks/useLocalTracks';
import useRoom from './useRoom/useRoom';
import useDominantSpeaker, { ExtendedDominantSpeaker } from './useDominantSpeaker/useDominantSpeaker';
import useScreenShareToggle from './useScreenShareToggle/useScreenShareToggle';
import useCardGame, { UseCardGame } from './useCardGame/useCardGame';
import useEmotionalEmojis, { UseEmotionalEmojis } from './useEmotionalEmojis/useEmotionalEmojis';
import { UseMainApiProps } from '../../state/useApi/useMainApi';
import { Group } from '../../state/useGroup/useGroup';

import { NOTIFICATON_TYPES, RoomNotification } from '../RoomNotifications/roomNotifications.types';
import { CirclesConnectOptions } from '../../state/useApi/api.types';

import { PARTICIPANT_JOINED_NOTIFICATIION, ROOM_NOTIFICATION } from '../../constants';

/*
 *  The hooks used by the VideoProvider component are different than the hooks found in the 'hooks/' directory. The hooks
 *  in the 'hooks/' directory can be used anywhere in a video application, and they can be used any number of times.
 *  the hooks in the 'VideoProvider/' directory are intended to be used by the VideoProvider component only. Using these hooks
 *  elsewhere in the application may cause problems as these hooks should not be used more than once in an application.
 */

export interface IVideoContext {
  room: Room | null;
  localTracks: (LocalAudioTrack | LocalVideoTrack)[];
  localDataTrack: LocalDataTrack;
  isConnecting: boolean;
  connect: (token: string, connectOptions: CirclesConnectOptions) => Promise<void>;
  onError: ErrorCallback;
  getLocalVideoTrack: (newOptions?: CreateLocalTrackOptions) => Promise<LocalVideoTrack>;
  getLocalAudioTrack: (deviceId?: string) => Promise<LocalAudioTrack>;
  isAcquiringLocalTracks: boolean;
  removeLocalVideoTrack: () => void;
  isSharingScreen: boolean;
  toggleScreenShare: () => void;
  getAudioAndVideoTracks: () => Promise<void>;
  joinedParticipants: Record<string, number>;
  onParticipantJoined: (sid: string) => void;
  dominantSpeaker: ExtendedDominantSpeaker | null | undefined;
  setDominantSpeaker: (dominantSpeaker: ExtendedDominantSpeaker) => void;
  cardGameService: UseCardGame;
  emotionalEmojisService: UseEmotionalEmojis;
  meetingSocketToken: string;
  setMeetingSocketToken: React.Dispatch<React.SetStateAction<string>>;
  onToggledAudioByHost: (status: 'muted' | 'unmuted') => void;
  roomNotifications: RoomNotification[];
}

export const VideoContext = createContext<IVideoContext>(null!);

interface VideoProviderProps {
  options?: ConnectOptions;
  onError: ErrorCallback;
  mainApi: UseMainApiProps | null;
  group: Group | null;
  isMeetingHost: boolean;
  children: ReactNode;
}

export function VideoProvider({
  options,
  children,
  onError = () => {},
  mainApi,
  group,
  isMeetingHost,
}: VideoProviderProps) {
  const [joinedParticipants, setJoinedParticipants] = useState<Record<string, number>>({});
  const [meetingSocketToken, setMeetingSocketToken] = useState<string>('');
  const [roomNotifications, setRoomNotifications] = useState<RoomNotification[]>([]);

  const onErrorCallback: ErrorCallback = useCallback(
    error => {
      console.log(`ERROR: ${error.message}`, error);
      onError(error);
    },
    [onError]
  );

  const onParticipantJoined = (sid: string) => {
    setJoinedParticipants({ ...joinedParticipants, [sid]: Date.now() });
    setTimeout(() => {
      setJoinedParticipants({ ...joinedParticipants, [sid]: 0 });
    }, PARTICIPANT_JOINED_NOTIFICATIION);
  };

  const onToggledAudioByHost = (status: 'muted' | 'unmuted') => {
    const audioTrack = localTracks.find(track => track.kind === 'audio') as LocalAudioTrack;
    if (audioTrack) {
      if (status === 'muted') {
        audioTrack.disable();
      } else {
        audioTrack.enable();
      }

      const ts = Date.now();
      const notificationType = status === 'muted' ? NOTIFICATON_TYPES.MUTED_BY_HOST : NOTIFICATON_TYPES.UNMUTED_BY_HOST;
      setRoomNotifications(prevNotifications => [
        ...prevNotifications.filter(
          not => not.type !== NOTIFICATON_TYPES.MUTED_BY_HOST && not.type !== NOTIFICATON_TYPES.UNMUTED_BY_HOST
        ),
        { ts, type: notificationType },
      ]);
      setTimeout(() => {
        setRoomNotifications(prevNotifications => prevNotifications.filter(not => not.ts !== ts));
      }, ROOM_NOTIFICATION);
    }
  };

  const {
    localTracks,
    localDataTrack,
    getLocalVideoTrack,
    getLocalAudioTrack,
    isAcquiringLocalTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getAudioAndVideoTracks,
  } = useLocalTracks();

  const { room, isConnecting, connect } = useRoom({
    localTracks: [...localTracks, localDataTrack],
    onError: onErrorCallback,
    options,
    mainApi,
    group,
    isMeetingHost,
  });

  const { dominantSpeaker, setDominantSpeaker } = useDominantSpeaker({ room, mainApi, group });

  const cardGameService = useCardGame({ room, localDataTrack, isMeetingHost, group });

  const emotionalEmojisService = useEmotionalEmojis({ localDataTrack });

  const [isSharingScreen, toggleScreenShare] = useScreenShareToggle(room, onError);

  // Register callback functions to be called on room disconnect.
  useHandleRoomDisconnection(
    room,
    onError,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    isSharingScreen,
    toggleScreenShare
  );
  useHandleTrackPublicationFailed(room, onError);

  return (
    <VideoContext.Provider
      value={{
        room,
        localTracks,
        localDataTrack,
        isConnecting,
        onError: onErrorCallback,
        getLocalVideoTrack,
        getLocalAudioTrack,
        connect,
        isAcquiringLocalTracks,
        removeLocalVideoTrack,
        isSharingScreen,
        toggleScreenShare,
        getAudioAndVideoTracks,
        joinedParticipants,
        onParticipantJoined,
        dominantSpeaker,
        setDominantSpeaker,
        cardGameService,
        emotionalEmojisService,
        meetingSocketToken,
        setMeetingSocketToken,
        roomNotifications,
        onToggledAudioByHost,
      }}
    >
      <SelectedParticipantProvider room={room}>{children}</SelectedParticipantProvider>
      {/* 
        The AttachVisibilityHandler component is using the useLocalVideoToggle hook
        which must be used within the VideoContext Provider.
      */}
      <AttachVisibilityHandler />
    </VideoContext.Provider>
  );
}
