export const identify = (id: string) => {
  if (process.env.REACT_APP_ALLOW_ANALYTICS !== 'true') {
    return;
  }
  // @ts-ignore
  analytics.identify(id);
};

export const track = (eventName: string, params: any = {}) => {
  if (process.env.REACT_APP_ALLOW_ANALYTICS !== 'true') {
    return;
  }
  // @ts-ignore
  analytics.track(eventName, params);
};
