import React, { useState } from 'react';

import HangupIcon from '../../../icons/HangupIcon';

import { Button, Text } from '../../DesignSystem';
import { COLORS } from '../../../theme';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

import { Popper, ClickAwayListener, Fade } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core';

import { InMeetingEvent } from '../../../state/useApi/api.types';
import { useAppState } from '../../../state';

import { track } from '../../../services/analytics';

interface StyleProps {
  open: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  popperWrapper: {
    zIndex: ({ open }) => (open ? 10 : 0),
  },
  popper: {
    background: theme.colors.MID_BLACK,
    border: '5px solid rgba(255, 255, 255, 0.12)',
    boxShadow: '0px 8px 25px rgba(0, 0, 0, 0.2)',
    borderRadius: 8,
    padding: '8px 13px',
    '-webkit-background-clip': 'padding-box',
    'background-clip': 'padding-box',
  },
  endForAll: {
    cursor: 'pointer',
    color: theme.colors.RED[400],
  },
  leave: {
    cursor: 'pointer',
    color: theme.colors.GRAY[500],
  },
  hr: {
    height: 0,
    border: 'none',
    borderTop: `1px solid ${theme.colors.GRAY[800]}`,
    width: '100%',
    marginBottom: 4,
    marginTop: 4,
  },
}));

export default function EndCallButton(props: { className?: string }) {
  const { room, localDataTrack, cardGameService } = useVideoContext();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles({ open });
  const { mainApi, group, isMeetingHost } = useAppState();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (isMeetingHost) {
      setAnchorEl(anchorEl ? null : event.currentTarget);
      setOpen(!open);
    } else {
      leaveMeeting();
    }
  };

  const endForAll = () => {
    track('click end meeting for all');
    setOpen(false);
    localDataTrack.send(
      JSON.stringify({
        event: InMeetingEvent.EndedByHost,
      })
    );

    cardGameService.onHostDisconnected();

    mainApi?.endMeeting(group!.id);

    room!.disconnect();
  };

  const leaveMeeting = () => {
    track('click leave meeting');
    setOpen(false);
    if (isMeetingHost) {
      cardGameService.onHostDisconnected();
    }
    room!.disconnect();
  };

  const handleClickAway = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  return (
    <>
      <Popper
        open={open}
        anchorEl={anchorEl}
        className={classes.popperWrapper}
        placement="top-start"
        transition
        popperOptions={{
          modifiers: {
            offset: {
              offset: '30,20',
            },
          },
        }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Fade {...TransitionProps} timeout={350}>
              <div className={classes.popper}>
                <Text preset="Label1" tx="menuBar.endMeeting" className={classes.endForAll} onClick={endForAll} />
                <hr className={classes.hr} />
                <Text preset="Label1" tx="menuBar.leaveMeeting" className={classes.leave} onClick={leaveMeeting} />
              </div>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>

      <Button
        onClick={e => {
          handleClick(e);
        }}
        className={props.className}
        data-cy-disconnect
      >
        <HangupIcon color={COLORS.RED[400]} />
      </Button>
    </>
  );
}
