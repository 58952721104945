import { useState, useEffect } from 'react';

import { sendSurveyAnswersProps, Survey } from '../../components/SessionSurvay/survey.types';
import { Api } from '../../services/api';
import { Account } from '../useAccount/useAccount';
import { Group } from '../useGroup/useGroup';

import * as Types from './api.types';
import { FetchChatMessagesResponse } from '../../components/CirclesChat/chat.types';

export interface UseMainApiProps {
  isReady: boolean;
  isAuthenticated: boolean;
  setAuthToken: (token: string) => void;
  getAccount: () => Promise<{ kind: string; data: Account | null }>;
  getRoomToken: (
    room: string
  ) => Promise<{
    kind: string;
    data: {
      token?: string;
      message?: string;
      meetingStatsToken: string | null;
      meetingSocketToken: string;
      connectOptions: Types.CirclesConnectOptions;
    };
  }>;
  getGroupDetails: (groupId: string) => Promise<{ kind: string; data: Group | null }>;
  sendInMeetingStatus: (props: Types.SendInMeetingStatusParams) => Promise<{ kind: string }>;
  sendInMeetingEvent: (props: Types.SendInMeetingEventParams) => Promise<{ kind: string }>;
  endMeeting: (groupInstanceId: string) => Promise<{ kind: string }>;
  getCardGames: () => Promise<{ kind: string; data: Types.CardGame[] | null }>;
  getGroupSurvey: (groupInstanceId: string) => Promise<{ survey: Survey | null }>;
  sendSurveyAnswers: (props: sendSurveyAnswersProps) => Promise<{ kind: string }>;
  fetchChatMessages: (groupInstanceId: string) => Promise<FetchChatMessagesResponse>;
}

export default function useMainApi(): UseMainApiProps {
  const [api, setApi] = useState<Api | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    setApi(new Api());
  }, []);

  const setAuthToken = (token: string) => {
    if (api) {
      api.setAuthorizationHeader(token);
      window.localStorage.setItem('accessToken', token);
    }
  };

  const getAccount = async () => {
    if (api) {
      const { kind, data } = await api.get('/account');

      if (data?.id) {
        setIsAuthenticated(true);
      }

      return { kind, data: data?.id ? data : null };
    }
    return { kind: 'cannot-connect', data: null };
  };

  const getGroupDetails = async (groupId: string) => {
    if (api) {
      const { kind, data } = await api.get(`/groups/instance/${groupId}?with_members=true`);
      return { kind, data: data?.id ? data : null };
    }
    return { kind: 'cannot-connect', data: null };
  };

  const getRoomToken = async (room: string) => {
    if (api) {
      const { kind, data } = await api.get(`/video/twilio/room/${room}/token`);
      return { kind, data };
    }
    return { kind: 'cannot-connect', data: null };
  };

  const sendInMeetingStatus = async (props: Types.SendInMeetingStatusParams) => {
    const { groupInstanceId, ...rest } = props;
    const { kind } = (await api?.post(`video/twilio/meeting/${groupInstanceId}/user-status`, rest)) || {
      kind: 'cannot-connect',
    };
    return { kind };
  };

  const sendInMeetingEvent = async (props: Types.SendInMeetingEventParams) => {
    const { groupInstanceId, event, ...rest } = props;
    const { kind } = (await api?.post(`video/twilio/meeting/${groupInstanceId}/event`, { event, payload: rest })) || {
      kind: 'cannot-connect',
    };
    return { kind };
  };

  const endMeeting = async (groupInstanceId: string) => {
    const { kind } = (await api?.post(`video/twilio/meeting/${groupInstanceId}/end`, {})) || {
      kind: 'cannot-connect',
    };
    return { kind };
  };

  const getCardGames = async () => {
    if (api) {
      const { kind, data } = await api.get(`facilitator/card-games`);
      return { kind, data: data?.games || null };
    }
    return { kind: 'cannot-connect', data: null };
  };

  const getGroupSurvey = async (groupInstanceId: string) => {
    if (api) {
      const { data } = await api?.get(`groupspace/${groupInstanceId}/survey`);
      return { survey: data };
    }
    return { survey: null };
  };

  const sendSurveyAnswers = async (props: sendSurveyAnswersProps) => {
    const { sessionId, pieceId, groupInstanceId, surveyAnswers } = props;
    const { kind } = (await api?.post(`groupspace/${groupInstanceId}/${sessionId}/survey`, {
      pieceId,
      surveyAnswers,
    })) || {
      kind: 'cannot-connect',
    };
    return { kind };
  };

  const fetchChatMessages = async (groupInstanceId: string): Promise<FetchChatMessagesResponse> => {
    if (api) {
      const { kind, data } = await api.get<FetchChatMessagesResponse>(`/chat/v2/${groupInstanceId}?timestamp=0`);
      return { kind, data };
    }
    return { kind: 'cannot-connect', data: [[], {}] };
  };

  return {
    isReady: api !== null,
    isAuthenticated,
    setAuthToken,
    getAccount,
    getRoomToken,
    getGroupDetails,
    sendInMeetingStatus,
    sendInMeetingEvent,
    endMeeting,
    getCardGames,
    getGroupSurvey,
    sendSurveyAnswers,
    fetchChatMessages,
  };
}
