import { translate } from '../../i18n/translate';
// This function is used to provide error messages to the user that are
// different than the error messages provided by the SDK.
export default function enhanceMessage(message = '', code?: number) {
  switch (code) {
    case 20101: // Invalid token error
      return { message: `${message}. ${translate('errors.invalidToken')}` };
    case 53000:
    case 53001:
    case 53002:
      return {
        title: translate('errors.connectionError.title'),
        message: translate('errors.connectionError.subtitle'),
      };
    default:
      return { message };
  }
}
