import React from 'react';
import { Popper, ClickAwayListener, Grid } from '@material-ui/core';
import { buildShareUrl, isInternetExplorer, SHARE_SITES } from '../../../utils/calendar/calendar';
import { track } from '../../../services/analytics';
import { Text } from '../../DesignSystem';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import CalendarIcon from '../../../assets/images/calendar-icon.svg';
import AppleCalendar from '../../../assets/images/apple-calendar.svg';
import GoogleCalendar from '../../../assets/images/google-calendar.svg';
import { CalendarEvent } from '../../../utils/calendar/calendar.types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      zIndex: 10,
      display: 'flex',
      alignItems: 'center',
      border: 'none',
      padding: '4px',
      backgroundColor: theme.colors.WHITE,
      opacity: '0.9',
      boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.1), -1px 0px 2px rgba(0, 0, 0, 0.1)',
      borderRadius: '4px',
      '&:after': {
        content: '""',
        position: 'absolute',
        top: -4,
        left: '80%',
        marginLeft: -5,
        borderWidth: 5,
        borderStyle: 'solid',
        borderColor: `transparent ${theme.colors.WHITE} ${theme.colors.WHITE} transparent`,
        borderRadius: 2,
        transform: 'rotate(-135deg)',
      },
    },
    modal: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '0 auto',
      transform: 'translate(-50%, -50%)',
      top: '50% !important',
      left: '50% !important',
      boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.3)',
      padding: 30,
      backgroundColor: theme.colors.WHITE,
    },
    addToCalendarModalButton: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    addToCalendarModalText: {
      fontSize: 16,
      textDecorationLine: 'underline',
      cursor: 'pointer',
      marginLeft: '5px',
      color: theme.colors.VIBRANT_BLUE.NORMAL,
    },
    link: {
      width: '50%',
      padding: '4px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '&:last-of-type': {
        border: 'none',
      },
      borderRight: `1px solid ${theme.colors.GRAY[400]}`,
      '& a': {
        textDecoration: 'none',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    linkName: {
      color: theme.colors.BLACK,
    },
  })
);

export default function CalendarModal({ event }: { event: CalendarEvent }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const handleClick = (e: React.MouseEvent) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
    setOpen(!open);
  };

  const handleClickAway = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const id = open ? 'transitions-popper' : undefined;

  function handleCalendarButtonClick(e: React.MouseEvent, cal: string) {
    if (e) {
      track('click add to calendar', { name: cal });
      const filename = 'Circles-meeting';
      e.preventDefault();
      const url = e.currentTarget.getAttribute('href');
      if (url) {
        if (url.startsWith('BEGIN')) {
          const blob = new Blob([url], { type: 'text/calendar;charset=utf-8' });

          if (isInternetExplorer()) {
            window.navigator.msSaveOrOpenBlob(blob, `${filename}.ics`);
          } else {
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute('download', `${filename}.ics`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        } else {
          window.open(url, '_blank');
        }
      }
    }
  }

  return (
    <div>
      <div className={classes.addToCalendarModalButton}>
        <img src={CalendarIcon} alt="" />
        <Text
          preset="Link"
          onClick={handleClick}
          className={classes.addToCalendarModalText}
          tx="calendarModal.addToCalendar"
        />
      </div>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        transition
        placement="bottom-start"
        modifiers={{
          offset: {
            enabled: true,
            offset: '-20, 10',
          },
        }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Fade {...TransitionProps} timeout={350}>
              <Grid container alignItems="center" direction="row" className={classes.paper}>
                <div className={classes.link} onClick={handleClickAway}>
                  <a
                    key="iCal"
                    onClick={e => handleCalendarButtonClick(e, SHARE_SITES.ICAL)}
                    href={buildShareUrl(event, SHARE_SITES.ICAL)}
                  >
                    <img src={AppleCalendar} alt="" />
                    <Text preset="Label2" className={classes.linkName} tx="calendarModal.apple" />
                  </a>
                </div>
                <div className={classes.link} onClick={handleClickAway}>
                  <a
                    key="Google"
                    onClick={e => handleCalendarButtonClick(e, SHARE_SITES.GOOGLE)}
                    target="_blank"
                    rel="noreferrer"
                    href={buildShareUrl(event, SHARE_SITES.GOOGLE)}
                  >
                    <img src={GoogleCalendar} alt="" />
                    <Text preset="Label2" className={classes.linkName} tx="calendarModal.google" />
                  </a>
                </div>
              </Grid>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </div>
  );
}
