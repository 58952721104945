import React from 'react';

interface CirclesLogoProps {
  className?: string;
  bkgCircleColor: string;
  textColor: string;
}

const CirclesLogo = (props: CirclesLogoProps) => (
  <svg width="238" height="121" fill="none" xmlns="http://www.w3.org/2000/svg" className={props.className}>
    <path
      d="M95.4301 116.025C79.6496 125.022 60.5208 119.81 45.8175 111.338C16.5274 94.4509 -19.925 44.8674 13.2374 15.2279C16.2654 12.5202 19.4972 9.72513 23.2822 8.09467C42.6148 -0.232334 63.2868 -3.08564 83.6966 5.1249C102.563 12.6949 117.878 29.1451 124.575 48.1866C134.124 75.2639 120.586 101.672 95.4301 116.025Z"
      fill={props.bkgCircleColor}
    />
    <path
      d="M24.7666 56.63C24.7666 53.8641 25.2324 51.1563 26.1933 48.5068C27.1541 45.8573 28.5516 43.4408 30.3859 41.2571C32.2201 39.0735 34.4911 37.3265 37.1698 36.0455C39.8484 34.7644 42.8764 34.0947 46.2829 34.0947C50.3299 34.0947 53.7655 35.0264 56.6771 36.8607C59.5595 38.695 61.714 41.0824 63.1116 43.9649L58.5987 46.8764C57.6961 44.9548 56.5606 43.4408 55.1922 42.3344C53.8238 41.1989 52.3389 40.4128 50.7375 39.9469C49.1362 39.4811 47.5931 39.2482 46.0499 39.2482C43.546 39.2482 41.3333 39.7722 39.3825 40.7622C37.4609 41.7812 35.8304 43.1496 34.5203 44.8383C33.2101 46.527 32.2201 48.4486 31.5505 50.5449C30.8808 52.6412 30.5606 54.7667 30.5606 56.8921C30.5606 59.2213 30.9391 61.4632 31.7252 63.6469C32.5113 65.8014 33.6177 67.723 35.0152 69.3826C36.4128 71.0422 38.0723 72.3524 39.9939 73.3423C41.9156 74.3322 43.9828 74.798 46.2246 74.798C47.7969 74.798 49.3982 74.536 51.0578 73.9828C52.7174 73.4296 54.2605 72.5853 55.7163 71.3915C57.172 70.2269 58.3075 68.7129 59.1519 66.8495L63.9268 69.4699C62.9951 71.7118 61.5393 73.6334 59.5886 75.2057C57.6379 76.7779 55.4542 77.9716 53.0668 78.7577C50.6793 79.573 48.2918 79.9806 45.9626 79.9806C42.8764 79.9806 40.0231 79.3109 37.4318 77.9716C34.8405 76.6323 32.5986 74.8563 30.7061 72.6144C28.8136 70.3725 27.3579 67.8686 26.3097 65.1026C25.2907 62.2784 24.7666 59.4834 24.7666 56.63Z"
      fill={props.textColor}
    />
    <path
      d="M72.8066 40.1507V33.1339H78.3968V40.1507H72.8066ZM72.8066 79.6312V46.3814H78.3968V79.6312H72.8066Z"
      fill={props.textColor}
    />
    <path
      d="M107.57 51.2728C104.775 51.3019 102.3 52.0298 100.146 53.3982C97.9913 54.7667 96.4773 56.6883 95.6038 59.1048V79.6021H90.0137V46.5561H95.2253V54.0679C96.3608 51.7678 97.8748 49.9044 99.7673 48.5068C101.66 47.0802 103.64 46.2941 105.736 46.1194C106.173 46.1194 106.522 46.1194 106.813 46.1194C107.104 46.1194 107.366 46.1485 107.57 46.1776V51.2728Z"
      fill={props.textColor}
    />
    <path
      d="M129.612 80.2717C127.108 80.2717 124.837 79.8059 122.77 78.9033C120.702 78.0007 118.926 76.7487 117.412 75.1474C115.898 73.546 114.734 71.7118 113.918 69.6446C113.103 67.5774 112.667 65.3937 112.667 63.0645C112.667 59.9492 113.365 57.0959 114.763 54.5337C116.16 51.9715 118.14 49.9043 120.673 48.3612C123.206 46.8181 126.176 46.0611 129.524 46.0611C132.785 46.0611 135.639 46.789 138.084 48.2448C140.53 49.7005 142.335 51.6804 143.529 54.1261L138.055 55.8439C137.211 54.2717 136.017 53.0488 134.503 52.1754C132.989 51.3019 131.271 50.8652 129.408 50.8652C127.37 50.8652 125.506 51.3892 123.847 52.4374C122.158 53.4855 120.848 54.9413 119.858 56.7465C118.868 58.5807 118.402 60.677 118.402 63.0063C118.402 65.3064 118.897 67.4027 119.887 69.2661C120.877 71.1295 122.216 72.6144 123.905 73.7207C125.594 74.8271 127.428 75.3512 129.466 75.3512C130.776 75.3512 132.057 75.1183 133.251 74.6524C134.474 74.1866 135.522 73.546 136.425 72.7599C137.327 71.9738 137.968 71.1295 138.317 70.1687L143.791 71.8282C143.121 73.4296 142.073 74.8853 140.705 76.1373C139.336 77.3893 137.677 78.3792 135.813 79.1071C133.921 79.8932 131.854 80.2717 129.612 80.2717Z"
      fill={props.textColor}
    />
    <path
      d="M152.845 33.1339H158.436V71.1877C158.436 73.022 158.698 74.1575 159.192 74.5651C159.717 75.0018 160.328 75.2057 161.027 75.2057C161.929 75.2057 162.774 75.1183 163.56 74.9145C164.375 74.7107 165.045 74.536 165.598 74.3031L166.5 78.8742C165.569 79.2527 164.462 79.573 163.181 79.835C161.9 80.097 160.794 80.2135 159.804 80.2135C157.649 80.2135 155.932 79.6021 154.68 78.3501C153.428 77.1273 152.787 75.3803 152.787 73.1676V33.1339H152.845Z"
      fill={props.textColor}
    />
    <path
      d="M188.133 80.2717C185.629 80.2717 183.358 79.8059 181.291 78.9033C179.224 78.0007 177.448 76.7487 175.934 75.1765C174.42 73.6043 173.255 71.77 172.44 69.7028C171.625 67.6356 171.188 65.452 171.188 63.1227C171.188 60.0074 171.916 57.1541 173.343 54.5919C174.798 52.0298 176.778 49.9626 179.369 48.3904C181.932 46.8181 184.872 46.0611 188.191 46.0611C191.54 46.0611 194.451 46.8472 196.955 48.4195C199.43 49.9917 201.381 52.0589 202.749 54.621C204.117 57.1832 204.816 59.92 204.816 62.8898C204.816 63.2974 204.816 63.7342 204.787 64.1127C204.758 64.5203 204.729 64.8114 204.7 65.0444H177.098C177.273 67.1989 177.885 69.0914 178.904 70.751C179.952 72.4105 181.291 73.7207 182.951 74.6524C184.61 75.5841 186.386 76.0791 188.308 76.0791C190.346 76.0791 192.268 75.555 194.073 74.5651C195.878 73.546 197.13 72.2067 197.799 70.5472L202.633 71.8865C201.905 73.4878 200.827 74.9436 199.43 76.2247C198.003 77.5057 196.344 78.4957 194.393 79.2235C192.471 79.8932 190.375 80.2717 188.133 80.2717ZM176.924 61.1429H199.605C199.43 59.0175 198.818 57.125 197.77 55.4654C196.722 53.8349 195.354 52.5539 193.694 51.6222C192.035 50.6905 190.2 50.2246 188.22 50.2246C186.27 50.2246 184.465 50.6905 182.805 51.6222C181.145 52.5539 179.806 53.8349 178.758 55.4654C177.681 57.125 177.098 59.0175 176.924 61.1429Z"
      fill={props.textColor}
    />
    <path
      d="M224.993 80.2717C222.227 80.2717 219.636 79.8059 217.219 78.9033C214.803 78.0007 212.707 76.6323 210.989 74.7981L213.143 70.8384C215.065 72.6144 216.957 73.8955 218.879 74.6525C220.801 75.4095 222.751 75.788 224.731 75.788C227.031 75.788 228.895 75.3512 230.321 74.5069C231.777 73.6626 232.476 72.3815 232.476 70.6928C232.476 69.4699 232.097 68.5674 231.37 67.985C230.613 67.4027 229.564 66.9078 228.225 66.5002C226.857 66.0926 225.255 65.6558 223.392 65.1317C221.092 64.4621 219.17 63.7342 217.598 62.9772C216.026 62.2202 214.832 61.2594 214.046 60.1239C213.231 58.9884 212.823 57.5617 212.823 55.8148C212.823 53.7185 213.376 51.9425 214.483 50.4867C215.589 49.0309 217.103 47.9245 219.025 47.1675C220.946 46.4105 223.159 46.032 225.605 46.032C228.079 46.032 230.292 46.4105 232.301 47.1675C234.31 47.9245 236.028 49.06 237.455 50.5449L234.922 54.4173C233.611 53.0489 232.156 52.0589 230.583 51.3893C229.011 50.7196 227.264 50.3994 225.284 50.3994C224.149 50.3994 223.013 50.5449 221.907 50.807C220.801 51.069 219.898 51.564 219.17 52.2627C218.442 52.9615 218.093 53.9223 218.093 55.2034C218.093 56.2515 218.384 57.0959 218.966 57.7073C219.549 58.3187 220.393 58.8428 221.499 59.2504C222.635 59.658 224.003 60.1239 225.605 60.648C228.109 61.3759 230.292 62.0746 232.127 62.8025C233.961 63.5304 235.417 64.433 236.436 65.5685C237.455 66.704 237.95 68.2762 237.95 70.3143C237.95 73.4296 236.756 75.8462 234.398 77.6222C232.039 79.3983 228.895 80.2717 224.993 80.2717Z"
      fill={props.textColor}
    />
  </svg>
);

export default CirclesLogo;
