import React from 'react';

export default function SilentIcon({ color }: { color: string }) {
  return (
    <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.68652 2.68597L18.5362 16.0909"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.52782 12.0303L5.58365 11.8628L5.52203 11.6975C5.33055 11.1837 5.19613 10.6388 5.12749 10.0693C4.66066 6.19547 7.42254 2.6767 11.2963 2.20986C15.1701 1.74302 18.6889 4.50491 19.1558 8.37871C19.6226 12.2525 16.8607 15.7713 12.9869 16.2381C11.0499 16.4716 9.20247 15.8983 7.77959 14.7814L7.52563 14.5821L7.23945 14.7315C6.45605 15.1406 5.56722 15.2304 4.72523 15.1697C4.25428 15.1357 3.80879 15.0554 3.41975 14.9643C3.75687 14.601 4.10221 14.2072 4.41429 13.8175C4.89972 13.2114 5.35157 12.5588 5.52782 12.0303Z"
        stroke={color}
      />
    </svg>
  );
}
