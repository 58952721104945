import { useEffect, useState } from 'react';
import { Api } from '../../services/api';
import {
  ChatSendMessageBody,
  ChatSendMessageResponse,
  ChatDeleteMessageResponse,
} from '../../components/CirclesChat/chat.types';
import { CHAT_API_CONFIG } from '../../services/api/api-config';

export interface UseChatApiProps {
  isReady: boolean;
  deleteChatMessage: (groupInstanceId: string, messageId: string) => Promise<ChatDeleteMessageResponse>;
  sendChatMessage: (groupInstanceId: string, body: ChatSendMessageBody) => Promise<ChatSendMessageResponse>;
  setAuthToken: (token: string) => void;
}

export const useChatApi = (): UseChatApiProps => {
  const [api, setApi] = useState<Api | null>(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    setApi(new Api(CHAT_API_CONFIG));
  }, []);

  const deleteChatMessage = async (groupInstanceId: string, messageId: string): Promise<ChatDeleteMessageResponse> => {
    if (api) {
      const { kind } = await api.patch<ChatDeleteMessageResponse>(`/${groupInstanceId}/message/${messageId}/delete`);
      return { kind };
    }
    return { kind: 'cannot-connect' };
  };

  const sendChatMessage = async (
    groupInstanceId: string,
    body: ChatSendMessageBody
  ): Promise<ChatSendMessageResponse> => {
    if (api) {
      const { kind, data } = await api.post<ChatSendMessageResponse>(`/${groupInstanceId}`, {
        ...body,
        platform: 'video',
      });
      return { kind, data };
    }
    return { kind: 'cannot-connect', data: null };
  };

  const setAuthToken = (token: string) => {
    if (api) {
      api.setAuthorizationHeader(token);
      setIsReady(true);
    }
  };

  return {
    deleteChatMessage,
    isReady,
    sendChatMessage,
    setAuthToken,
  };
};
