import { linkifyMessageText } from './linkifyMessageText';
import { ChatMentions } from '../../chat.types';

interface ISanitizeMessageContent {
  message: string;
  deleted: boolean;
  imageUrl: string | null;
  isCurrentUserAnAuthor: boolean;
  linkStyle: string;
  mentions: ChatMentions | null;
}

const DEFAULT_MESSAGE_WITH_IMAGE_TEXT = '📷  Photo';

export const sanitizeMessageContent = ({
  message,
  deleted,
  imageUrl,
  isCurrentUserAnAuthor,
  linkStyle,
  mentions,
}: ISanitizeMessageContent) => {
  if (!deleted && imageUrl) {
    const text =
      message !== DEFAULT_MESSAGE_WITH_IMAGE_TEXT
        ? linkifyMessageText({
            message,
            deleted,
            isCurrentUserAnAuthor,
            linkStyle,
            mentions,
          })
        : '';

    return `
        <img
          alt='${message}'
          height='auto'
          onclick='window.open(this.src)'
          src='${imageUrl}'
          style='cursor: pointer; max-height: 150px; max-width: 150px'
        />
        ${text !== '' ? '<br/>' : ''}
        ${text}
      `;
  }

  return linkifyMessageText({
    message,
    deleted,
    isCurrentUserAnAuthor,
    linkStyle,
    mentions,
  });
};
