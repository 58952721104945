import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core';

import { track } from '../../services/analytics';

import AddEmojiIcon from '../../icons/AddEmojiIcon';

import HuggingEmoji from '../../assets/images/emotionalEmojis/hugging.png';
import RedHeartEmoji from '../../assets/images/emotionalEmojis/red-heart.png';
import AngryEmoji from '../../assets/images/emotionalEmojis/angry.png';
import AstonishedEmoji from '../../assets/images/emotionalEmojis/astonished.png';
import CryingEmoji from '../../assets/images/emotionalEmojis/crying.png';
import SmilingEyesEmoji from '../../assets/images/emotionalEmojis/smiling-eyes.png';
import TearsOfJoyEmoji from '../../assets/images/emotionalEmojis/tears-of-joy.png';

import { EmotionalEmojis } from '../VideoProvider/useEmotionalEmojis/useEmotionalEmojis';

interface AddEmojiStripProps {
  onEmojiClicked: (emoji: EmotionalEmojis) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'absolute',
    top: 0,
    right: 0,
    direction: 'rtl',
    paddingTop: '16px',
    paddingRight: '12px',
  },
  addEmojiIcon: {
    cursor: 'pointer',
  },
  emojisStrip: {
    marginTop: '4px',
    borderRadius: '4px',
    backgroundColor: 'rgba(251, 250, 250, 0.75)',
    padding: '8px',
    display: 'flex',
    alignItems: 'center',
    direction: 'ltr',
  },
  emojiWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  emojiLeftSpace: {
    marginLeft: '12px',
  },
  emoji: {
    width: '22px',
    height: '22px',
  },
}));

export default function AddEmojiStrip({ onEmojiClicked }: AddEmojiStripProps) {
  const classes = useStyles();
  const [openList, setOpenList] = useState(false);

  const emojisMap = {
    [EmotionalEmojis.ANGRY]: AngryEmoji,
    [EmotionalEmojis.ASTONISHED]: AstonishedEmoji,
    [EmotionalEmojis.CRYING]: CryingEmoji,
    [EmotionalEmojis.HUGGING]: HuggingEmoji,
    [EmotionalEmojis.RED_HEART]: RedHeartEmoji,
    [EmotionalEmojis.SMILIING_EYES]: SmilingEyesEmoji,
    [EmotionalEmojis.TEARS_OF_JOY]: TearsOfJoyEmoji,
  };

  return (
    <div className={classes.container} onClick={() => setOpenList(false)}>
      <div
        className={classes.addEmojiIcon}
        onClick={e => {
          e.stopPropagation();
          track('click open emojis list');
          setOpenList(!openList);
        }}
      >
        <AddEmojiIcon />
      </div>
      {openList && (
        <div className={classes.emojisStrip}>
          {Object.keys(EmotionalEmojis).map((emoji, idx) => (
            <div
              key={`${idx}-${emoji}`}
              className={clsx(classes.emojiWrapper, { [classes.emojiLeftSpace]: idx > 0 })}
              onClick={() => onEmojiClicked(emoji as EmotionalEmojis)}
            >
              <img
                src={emojisMap[EmotionalEmojis[emoji as keyof typeof EmotionalEmojis]]}
                alt=""
                className={classes.emoji}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
