import linkifyHtml from 'linkifyjs/html';
import { sanitizeMessageText } from './sanitizeMessageText';
import { ChatMentions } from '../../chat.types';

interface ILinkifyMessageContent {
  message: string;
  deleted: boolean;
  isCurrentUserAnAuthor: boolean;
  linkStyle: string;
  mentions: ChatMentions | null;
}

export const linkifyMessageText = ({
  message,
  deleted,
  isCurrentUserAnAuthor,
  linkStyle,
  mentions,
}: ILinkifyMessageContent) =>
  linkifyHtml(
    sanitizeMessageText({
      message,
      deleted,
      isCurrentUserAnAuthor,
      mentions,
    }),
    {
      className: linkStyle,
      target: { url: '_blank' },
      validate: true,
    }
  );
