import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

import DeviceSelectionDialog from '../../DeviceSelectionDialog/DeviceSelectionDialog';
import { Button, Text } from '../../DesignSystem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: 'transparent',
    },
    buttonLabel: {
      flexDirection: 'column',
    },
    settingsIcon: {
      color: theme.colors.GRAY[500],
      fontSize: '26px',
    },
    text: {
      color: theme.colors.GRAY[500],
    },
  })
);

export default function SettingsButton() {
  const classes = useStyles();
  const [deviceSettingsOpen, setDeviceSettingsOpen] = useState(false);

  return (
    <>
      <Button
        className={classes.button}
        classes={{ label: classes.buttonLabel }}
        onClick={() => setDeviceSettingsOpen(true)}
        data-cy-share-screen
      >
        <SettingsOutlinedIcon className={classes.settingsIcon} />
        <Text preset="Label1" tx="menuBar.settings" className={classes.text} />
      </Button>
      <DeviceSelectionDialog
        open={deviceSettingsOpen}
        onClose={() => {
          setDeviceSettingsOpen(false);
        }}
      />
    </>
  );
}
