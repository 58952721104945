import React from 'react';
import MainParticipantInfo from '../MainParticipantInfo/MainParticipantInfo';
import ParticipantTracks from '../ParticipantTracks/ParticipantTracks';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

export default function ScreenShareParticipant() {
  const { room } = useVideoContext();
  const localParticipant = room!.localParticipant;
  const screenShareParticipant = useScreenShareParticipant();

  if (!screenShareParticipant || screenShareParticipant.sid === localParticipant.sid) {
    return null;
  }

  return (
    <MainParticipantInfo participant={screenShareParticipant}>
      <ParticipantTracks
        participant={screenShareParticipant}
        enableScreenShare={screenShareParticipant.sid !== localParticipant.sid}
        videoPriority="high"
        isLocalParticipant={screenShareParticipant.sid === localParticipant.sid}
        videoOnly={true} // we want to emit only track with screen cast
      />
    </MainParticipantInfo>
  );
}
