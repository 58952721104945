import React, { useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme, Grid } from '@material-ui/core';
import { Text } from '../../DesignSystem';

import { track } from '../../../services/analytics';

import { COLORS } from '../../../theme';

export enum OptionalTabs {
  Activities = 'activities',
  ParticipantStats = 'participant_stats',
}

interface TabsProps {
  selectedTab: OptionalTabs;
  onTabSelect: (tab: OptionalTabs) => void;
  numOfParticipants: number;
  isMeetingHost: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '40px 16px 0px 16px',
  },
  tab: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '8px',
    cursor: 'pointer',
  },
  selectedTab: {
    borderBottom: `3px solid ${theme.colors.WHITE}`,
  },
}));

export default function Tabs({ selectedTab, onTabSelect, numOfParticipants, isMeetingHost }: TabsProps) {
  const classes = useStyles();

  useEffect(() => {
    track('view gf-dashboard tab', { tab: selectedTab });
  }, [selectedTab]);

  return (
    <Grid container className={classes.container} spacing={1}>
      <Grid
        item
        className={clsx(classes.tab, { [classes.selectedTab]: selectedTab === OptionalTabs.ParticipantStats })}
      >
        <div onClick={() => onTabSelect(OptionalTabs.ParticipantStats)}>
          <Text
            preset="Label1"
            tx="gfDashboard.tabs.participantStats"
            txOptions={{ numOfParticipants }}
            style={{ color: selectedTab === OptionalTabs.ParticipantStats ? COLORS.WHITE : COLORS.GRAY[700] }}
          />
        </div>
      </Grid>
      {isMeetingHost && (
        <Grid item className={clsx(classes.tab, { [classes.selectedTab]: selectedTab === OptionalTabs.Activities })}>
          <div onClick={() => onTabSelect(OptionalTabs.Activities)}>
            <Text
              preset="Label1"
              tx="gfDashboard.tabs.activities"
              style={{ color: selectedTab === OptionalTabs.Activities ? COLORS.WHITE : COLORS.GRAY[700] }}
            />
          </div>
        </Grid>
      )}
    </Grid>
  );
}
