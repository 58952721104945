import React from 'react';
import { Text } from '../../DesignSystem/Text';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    linkText: {
      fontFamily: 'Poppins',
      fontWeight: 400,
      fontSize: 18,
      marginTop: '4px',
    },
  })
);

const AuthFailScreen = () => {
  const classes = useStyles();

  return (
    <div>
      <Text preset="SubHeader" tx="authFail.title" gutterBottom />
      <Text preset="Paragraph" tx="authFail.subtitle" />
      <a href="mailto:hello@circlesup.com" target="_blank" rel="noreferrer" className={classes.linkText}>
        hello@circlesup.com
      </a>
    </div>
  );
};

export default AuthFailScreen;
