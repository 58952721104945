import React from 'react';

interface CirclesLogoProps {
  className?: string;
  bkgCircleColor: string;
  textColor: string;
}

const CirclesLogoSmall = (props: CirclesLogoProps) => (
  <svg width="107" height="55" viewBox="0 0 107 55" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M42.8665 52.1979C35.8332 56.2076 27.3077 53.8848 20.7546 50.1087C7.70021 42.5823 -8.53337 20.4834 6.24684 7.27329C7.5964 6.06648 9.03679 4.82074 10.7237 4.09405C19.3401 0.369803 28.5534 -0.888915 37.6499 2.75748C46.0587 6.13136 52.8843 13.4631 55.8689 21.9497C60.1122 34.0308 54.0782 45.8005 42.8665 52.1979Z"
      fill={props.bkgCircleColor}
    />
    <path
      d="M11.3853 25.7259C11.3853 24.4931 11.5929 23.2863 12.0211 22.1054C12.4493 20.9246 13.0722 19.8475 13.8897 18.8743C14.7072 17.901 15.7194 17.1225 16.9132 16.5515C18.1071 15.9805 19.4566 15.6821 20.9749 15.6821C22.7786 15.6821 24.3098 16.0973 25.6075 16.9148C26.8922 17.7324 27.8524 18.7964 28.4753 20.0811L26.4639 21.3917C26.0617 20.5353 25.5556 19.8605 24.9457 19.3674C24.3358 18.8613 23.674 18.5109 22.9603 18.3033C22.2466 18.0957 21.5588 17.9919 20.8711 17.9919C19.7551 17.9919 18.7689 18.2255 17.8994 18.6667C17.043 19.1208 16.3163 19.7307 15.7324 20.4834C15.1484 21.236 14.7072 22.0925 14.4088 23.0268C14.1103 23.9611 13.9676 24.9083 13.9676 25.8556C13.9676 26.8938 14.1363 27.8929 14.4866 28.8662C14.837 29.8264 15.3301 30.6829 15.953 31.4225C16.5758 32.1622 17.3155 32.7461 18.172 33.1873C19.0284 33.6285 19.9497 33.8362 20.9489 33.8362C21.6497 33.8362 22.3634 33.7194 23.103 33.4728C23.8427 33.2263 24.5304 32.85 25.1793 32.3179C25.8281 31.7989 26.3342 31.1241 26.7105 30.2936L28.8386 31.4615C28.4234 32.4607 27.7746 33.3171 26.9051 34.0178C26.0357 34.7186 25.0625 35.2506 23.9984 35.601C22.9343 35.9643 21.8703 36.146 20.8321 36.146C19.4566 36.146 18.1849 35.8475 17.03 35.2506C15.8751 34.6537 14.8759 33.8621 14.0325 32.8629C13.189 31.8637 12.5402 30.7478 12.073 29.515C11.6188 28.2433 11.3853 26.9976 11.3853 25.7259Z"
      fill={props.textColor}
    />
    <path
      d="M32.7837 18.3812V15.2538H35.2752V18.3812H32.7837ZM32.7837 35.9773V21.1581H35.2752V35.9773H32.7837Z"
      fill={props.textColor}
    />
    <path
      d="M48.2775 23.3382C47.0317 23.3512 45.9287 23.6756 44.9685 24.2855C44.0082 24.8954 43.3334 25.7518 42.9441 26.8289V35.9643H40.4526V21.236H42.7754V24.5839C43.2815 23.5588 43.9563 22.7283 44.7998 22.1054C45.6432 21.4696 46.5256 21.1192 47.4599 21.0414C47.6546 21.0414 47.8103 21.0414 47.9401 21.0414C48.0698 21.0414 48.1866 21.0543 48.2775 21.0673V23.3382Z"
      fill={props.textColor}
    />
    <path
      d="M58.1007 36.2628C56.9847 36.2628 55.9725 36.0552 55.0512 35.6529C54.1299 35.2506 53.3383 34.6926 52.6635 33.9789C51.9887 33.2652 51.4697 32.4477 51.1063 31.5264C50.743 30.605 50.5483 29.6318 50.5483 28.5937C50.5483 27.2052 50.8598 25.9335 51.4826 24.7916C52.1055 23.6496 52.9879 22.7283 54.1169 22.0406C55.2458 21.3528 56.5694 21.0154 58.0617 21.0154C59.5151 21.0154 60.7868 21.3398 61.8768 21.9886C62.9668 22.6375 63.7714 23.5199 64.3034 24.6099L61.8638 25.3755C61.4875 24.6748 60.9555 24.1298 60.2807 23.7405C59.6059 23.3512 58.8403 23.1565 58.0098 23.1565C57.1015 23.1565 56.271 23.3901 55.5313 23.8573C54.7787 24.3244 54.1947 24.9732 53.7535 25.7778C53.3123 26.5953 53.1047 27.5296 53.1047 28.5677C53.1047 29.5929 53.3253 30.5272 53.7665 31.3577C54.2077 32.1882 54.8046 32.85 55.5573 33.3431C56.3099 33.8362 57.1274 34.0698 58.0358 34.0698C58.6197 34.0698 59.1907 33.9659 59.7227 33.7583C60.2677 33.5507 60.7349 33.2652 61.1372 32.9148C61.5394 32.5645 61.8249 32.1882 61.9806 31.7599L64.4202 32.4996C64.1217 33.2133 63.6546 33.8621 63.0447 34.4201C62.4348 34.9781 61.6951 35.4193 60.8646 35.7437C60.0212 36.0941 59.0998 36.2628 58.1007 36.2628Z"
      fill={props.textColor}
    />
    <path
      d="M68.4556 15.2538H70.9471V32.2141C70.9471 33.0316 71.0639 33.5377 71.2845 33.7194C71.5181 33.914 71.7906 34.0049 72.102 34.0049C72.5043 34.0049 72.8806 33.9659 73.231 33.8751C73.5943 33.7843 73.8928 33.7064 74.1393 33.6026L74.5416 35.6399C74.1264 35.8086 73.6333 35.9513 73.0623 36.0681C72.4913 36.1849 71.9982 36.2368 71.557 36.2368C70.5968 36.2368 69.8311 35.9643 69.2732 35.4063C68.7152 34.8613 68.4297 34.0827 68.4297 33.0965V15.2538H68.4556Z"
      fill={props.textColor}
    />
    <path
      d="M84.1832 36.2628C83.0672 36.2628 82.055 36.0552 81.1337 35.6529C80.2124 35.2506 79.4208 34.6926 78.746 33.9919C78.0712 33.2912 77.5522 32.4736 77.1888 31.5523C76.8255 30.631 76.6309 29.6577 76.6309 28.6196C76.6309 27.2311 76.9553 25.9595 77.5911 24.8175C78.2399 23.6756 79.1223 22.7543 80.2772 22.0535C81.4192 21.3528 82.7298 21.0154 84.2091 21.0154C85.7014 21.0154 86.9991 21.3658 88.115 22.0665C89.218 22.7672 90.0875 23.6886 90.6974 24.8305C91.3073 25.9724 91.6187 27.1922 91.6187 28.5158C91.6187 28.6975 91.6187 28.8921 91.6057 29.0608C91.5927 29.2425 91.5798 29.3723 91.5668 29.4761H79.2781C79.3559 30.4363 79.6284 31.2798 80.0826 32.0195C80.5498 32.7591 81.1467 33.3431 81.8863 33.7583C82.626 34.1736 83.4176 34.3942 84.274 34.3942C85.1824 34.3942 86.0388 34.1606 86.8434 33.7194C87.6479 33.2652 88.2059 32.6683 88.5043 31.9286L90.6584 32.5256C90.334 33.2393 89.8539 33.8881 89.231 34.459C88.5952 35.03 87.8555 35.4712 86.9861 35.7956C86.1167 36.0941 85.1824 36.2628 84.1832 36.2628ZM79.1872 27.7372H89.2959C89.218 26.7899 88.9455 25.9465 88.4784 25.2068C88.0112 24.4801 87.4013 23.9092 86.6617 23.4939C85.922 23.0787 85.1045 22.871 84.2221 22.871C83.3527 22.871 82.5481 23.0787 81.8085 23.4939C81.0688 23.9092 80.4719 24.4801 80.0047 25.2068C79.5376 25.9465 79.2651 26.7899 79.1872 27.7372Z"
      fill={props.textColor}
    />
    <path
      d="M100.625 36.2628C99.3917 36.2628 98.2368 36.0551 97.1598 35.6529C96.0827 35.2506 95.1484 34.6407 94.3828 33.8232L95.3431 32.0584C96.1995 32.8499 97.043 33.4209 97.8994 33.7583C98.7559 34.0957 99.6253 34.2644 100.508 34.2644C101.533 34.2644 102.363 34.0697 102.999 33.6934C103.648 33.3171 103.959 32.7461 103.959 31.9935C103.959 31.4485 103.791 31.0462 103.466 30.7867C103.129 30.5271 102.662 30.3066 102.065 30.1249C101.455 29.9432 100.741 29.7486 99.9108 29.515C98.8857 29.2165 98.0292 28.8921 97.3285 28.5547C96.6277 28.2173 96.0957 27.7891 95.7453 27.283C95.382 26.7769 95.2003 26.1411 95.2003 25.3625C95.2003 24.4282 95.4469 23.6366 95.94 22.9878C96.4331 22.339 97.1079 21.8459 97.9643 21.5085C98.8208 21.1711 99.807 21.0024 100.897 21.0024C102 21.0024 102.986 21.1711 103.882 21.5085C104.777 21.8459 105.543 22.352 106.178 23.0138L105.049 24.7396C104.466 24.1297 103.817 23.6885 103.116 23.3901C102.415 23.0916 101.637 22.9489 100.754 22.9489C100.248 22.9489 99.7421 23.0138 99.249 23.1306C98.7559 23.2473 98.3536 23.4679 98.0292 23.7794C97.7048 24.0908 97.5491 24.519 97.5491 25.09C97.5491 25.5572 97.6788 25.9335 97.9384 26.206C98.1979 26.4785 98.5742 26.7121 99.0673 26.8937C99.5734 27.0754 100.183 27.283 100.897 27.5166C102.013 27.841 102.986 28.1525 103.804 28.4769C104.621 28.8013 105.27 29.2035 105.724 29.7096C106.178 30.2157 106.399 30.9164 106.399 31.8248C106.399 33.2133 105.867 34.2903 104.816 35.0819C103.765 35.8735 102.363 36.2628 100.625 36.2628Z"
      fill={props.textColor}
    />
  </svg>
);

export default CirclesLogoSmall;
