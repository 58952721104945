import React from 'react';

import { Button } from '../../DesignSystem';
import MicIcon from '../../../icons/MicIcon';
import MicOffIcon from '../../../icons/MicOffIcon';

import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

import { track } from '../../../services/analytics';

export default function ToggleAudioButton(props: {
  disabled?: boolean;
  className?: string;
  iconColor: string;
  iconDisableLineColor: string;
}) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks } = useVideoContext();
  const hasAudioTrack = localTracks.some(t => t.kind === 'audio');

  return (
    <Button
      className={props.className}
      onClick={() => {
        const eventName = isAudioEnabled ? 'click disable audio' : 'click enable audio';
        track(eventName);
        toggleAudioEnabled();
      }}
      disabled={!hasAudioTrack || props.disabled}
    >
      {isAudioEnabled ? (
        <MicIcon color={props.iconColor} />
      ) : (
        <MicOffIcon color={props.iconColor} lineColor={props.iconDisableLineColor} />
      )}
    </Button>
  );
}
