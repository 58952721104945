import React from 'react';

export const CardGameIcon = () => {
  return (
    <svg width="26" height="32" viewBox="0 0 26 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="0.970322"
        y="16.2405"
        width="13.1679"
        height="17.3743"
        rx="1.5"
        transform="rotate(-17.4307 0.970322 16.2405)"
        stroke="#C4C4C4"
      />
      <path
        d="M11.2386 12.8997L11.7036 11.1646C11.9894 10.0977 13.0861 9.4645 14.153 9.75038L23.9744 12.382C25.0414 12.6679 25.6745 13.7646 25.3887 14.8315L21.6683 28.716C21.3824 29.7829 20.2858 30.4161 19.2188 30.1302L17.2816 29.6111"
        stroke="#C4C4C4"
      />
      <path
        d="M7.27488 5.91651C7.33382 5.73031 7.5973 5.73031 7.65624 5.91651L8.19817 7.62873C8.21781 7.69079 8.26643 7.73941 8.3285 7.75905L10.0407 8.30099C10.2269 8.35992 10.2269 8.62341 10.0407 8.68234L8.3285 9.22428C8.26643 9.24392 8.21781 9.29254 8.19817 9.3546L7.65624 11.0668C7.5973 11.253 7.33382 11.253 7.27488 11.0668L6.73295 9.3546C6.7133 9.29254 6.66468 9.24392 6.60262 9.22428L4.8904 8.68234C4.7042 8.62341 4.7042 8.35992 4.8904 8.30099L6.60262 7.75905C6.66468 7.73941 6.7133 7.69079 6.73295 7.62873L7.27488 5.91651Z"
        fill="#C4C4C4"
      />
      <path
        d="M13.1698 1.33066C13.2287 1.14446 13.4922 1.14446 13.5511 1.33066L14.0931 3.04288C14.1127 3.10494 14.1613 3.15356 14.2234 3.17321L15.9356 3.71514C16.1218 3.77408 16.1218 4.03756 15.9356 4.0965L14.2234 4.63843C14.1613 4.65808 14.1127 4.7067 14.0931 4.76876L13.5511 6.48098C13.4922 6.66718 13.2287 6.66718 13.1698 6.48098L12.6278 4.76876C12.6082 4.7067 12.5596 4.65808 12.4975 4.63843L10.7853 4.0965C10.5991 4.03756 10.5991 3.77408 10.7853 3.71514L12.4975 3.17321C12.5596 3.15356 12.6082 3.10494 12.6278 3.04288L13.1698 1.33066Z"
        fill="#C4C4C4"
      />
    </svg>
  );
};
