import { useContext } from 'react';
import { CirclesChatContext } from '../../components/CirclesChat/CirclesChatProvider';

export default function useCirclesChatContext() {
  const context = useContext(CirclesChatContext);
  if (!context) {
    throw new Error('useCirclesChatContext must be used within a CirclesChatProvider');
  }
  return context;
}
