import React, { useCallback, useEffect } from 'react';
import { ChatMessage, ChatMessageEnriched } from '../chat.types';
import { List, makeStyles } from '@material-ui/core';
import { COLORS } from '../../../theme';
import { Message } from './Message';
import useCirclesChatContext from '../../../hooks/useCirclesChatContext/useCirclesChatContext';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  container: {
    background: COLORS.WHITE,
    display: 'flex',
    flexDirection: 'column-reverse',
    flexFlow: 'column-reverse',
    overflowX: 'hidden',
    overflowY: 'auto',
    marginTop: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
  },
}));

const NO_AVATAR = './assets/no-avatar.svg';

interface IMessagesList {
  className?: string;
  messagesListContainerScrollId: string;
}

export const MessagesList = ({ className, messagesListContainerScrollId, ...rest }: IMessagesList) => {
  const classes = useStyles();

  const { members, messages } = useCirclesChatContext();

  const scrollToNewMessages = useCallback(() => {
    const messagesListContainer = document.getElementById(messagesListContainerScrollId);
    if (!messagesListContainer) {
      return;
    }
    messagesListContainer.scrollTo(0, messagesListContainer.scrollHeight);
  }, [messagesListContainerScrollId]);

  useEffect(() => {
    setTimeout(scrollToNewMessages, 300);
  }, [messages, scrollToNewMessages]);

  return (
    <List className={clsx(classes.container, className || '')} dense {...rest}>
      {messages.map((message: ChatMessage) => {
        const { name, picture } = members[message.senderId] || {};

        const messageProps: ChatMessageEnriched = { ...message, name: name || 'member', picture: picture || NO_AVATAR };

        return <Message key={messageProps.timestamp} {...messageProps} />;
      })}
    </List>
  );
};
