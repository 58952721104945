import { useState } from 'react';

export interface Schedule {
  duration: number;
  hour: number;
  minute: number;
  weekday: number;
  local: {
    hour: number;
    minute: number;
    tz: string;
    weekday: number;
  };
}

export interface GroupMember {
  id: string;
  memberId: string;
  name: string;
  picture: string;
}

export interface Group {
  id: string;
  groupTitle: string;
  leaderAccountId: string;
  groupLeaderName: string;
  schedule: Schedule[];
  members: Record<string, GroupMember>;
}

export default function useGroup() {
  const [group, setGroup] = useState<Group | null>(null);

  const onSetGroup = (groupData: any) => {
    const members = groupData.members.reduce(
      (acc: Record<string, GroupMember>, m: GroupMember) => ({
        ...acc,
        [m.id]: m,
      }),
      {}
    );

    setGroup({
      ...groupData,
      members,
    });
  };

  return { group, setGroup: onSetGroup };
}
