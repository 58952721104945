import { useState } from 'react';
import { LocalDataTrack } from 'twilio-video';

import { track } from '../../../services/analytics';

import { InMeetingEvent } from '../../../state/useApi/api.types';

export enum EmotionalEmojis {
  HUGGING = 'hugging',
  RED_HEART = 'red-heart',
  ANGRY = 'angry',
  ASTONISHED = 'astonished',
  CRYING = 'crying',
  SMILIING_EYES = 'smiling-eyes',
  TEARS_OF_JOY = 'tears-of-joy',
}

export interface EmotionalEmoji {
  senderName: string;
  emoji: EmotionalEmojis;
  centerOffset?: { left: number; right: number };
  key: string; // so ReceivedEmoji will not re-render on queue change
  active: boolean;
}

interface EmojiReceivedParams {
  isLocalParticipant: boolean;
  receiverId?: string;
  senderName: string;
  emoji: string;
}

interface SendEmojiParams {
  emoji: EmotionalEmojis;
  receiverId: string;
  senderName: string;
}

export interface UseEmotionalEmojis {
  emojisQueue: EmotionalEmoji[];
  remoteParticipantsEmojisQueue: Record<string, EmotionalEmoji[]>;
  onEmojiReceived: ({ senderName, emoji }: EmojiReceivedParams) => void;
  emojiDisplayed: (key: string) => void;
  remoteEmojiDisplayed: (receiverId: string) => void;
  sendEmoji: ({ emoji, receiverId, senderName }: SendEmojiParams) => void;
}

export default function useEmotionalEmojis({ localDataTrack }: { localDataTrack: LocalDataTrack }) {
  const [emojisQueue, setEmojisQueue] = useState<EmotionalEmoji[]>([]);
  const [remoteParticipantsEmojisQueue, setRemoteParticipantsEmojisQueue] = useState<Record<string, EmotionalEmoji[]>>(
    {}
  );

  const calcCenterOffset = (index: number) => {
    const space = 60;
    let left = 0;
    let right = 0;

    if (index % 2 === 0) {
      right = (space / 2) * index;
    } else {
      left = space * index;
    }

    return { left, right };
  };

  const onEmojiReceived = ({ senderName, emoji, receiverId, isLocalParticipant }: EmojiReceivedParams) => {
    track('view emotional emoji', { emoji, isLocalParticipant });
    if (isLocalParticipant) {
      setEmojisQueue(prevQueue => [
        ...prevQueue,
        {
          senderName,
          emoji: EmotionalEmojis[emoji as keyof typeof EmotionalEmojis],
          active: true,
          key: `${senderName}-${Date.now()}`,
          centerOffset: calcCenterOffset(prevQueue.length),
        },
      ]);
    } else {
      setRemoteParticipantsEmojisQueue(prevQueue => ({
        ...prevQueue,
        [receiverId!]: [
          ...(prevQueue[receiverId!] || []),
          {
            senderName,
            emoji: EmotionalEmojis[emoji as keyof typeof EmotionalEmojis],
            active: true,
            key: `${senderName}-${Date.now()}`,
          },
        ],
      }));
    }
  };

  const emojiDisplayed = (key: string) => {
    setEmojisQueue(prevEmojisQueue => {
      let activeCount = prevEmojisQueue.filter(e => e.active && e.key !== key).length;
      if (activeCount === 0) {
        return [];
      }
      return prevEmojisQueue.map(e => {
        return e.key === key ? { ...e, active: false } : e;
      });
    });
  };

  const remoteEmojiDisplayed = (receiverId: string) => {
    setRemoteParticipantsEmojisQueue(prevQueue => ({
      ...prevQueue,
      [receiverId]: [...(prevQueue[receiverId] || []).slice(1)],
    }));
  };

  const sendEmoji = ({ emoji, receiverId, senderName }: SendEmojiParams) => {
    track('click send emotional emoji', { emoji });
    localDataTrack.send(
      JSON.stringify({
        emoji,
        receiverId,
        senderName,
        event: InMeetingEvent.PublishEmitionalEmoji,
      })
    );

    // adding it here, since we won't get the message of onEmojiReceived this participant is the sender
    setRemoteParticipantsEmojisQueue(prevQueue => ({
      ...prevQueue,
      [receiverId]: [
        ...(prevQueue[receiverId] || []),
        {
          senderName,
          emoji: EmotionalEmojis[emoji.toString() as keyof typeof EmotionalEmojis],
          active: true,
          key: `${senderName}-${Date.now()}`,
        },
      ],
    }));
  };

  return {
    emojisQueue,
    remoteParticipantsEmojisQueue,
    onEmojiReceived,
    emojiDisplayed,
    remoteEmojiDisplayed,
    sendEmoji,
  };
}
