import { useEffect, useState, useCallback } from 'react';

import { RemoteParticipant, LocalParticipant, Room } from 'twilio-video';

import { InMeetingEvent } from '../../../state/useApi/api.types';
import { UseMainApiProps } from '../../../state/useApi/useMainApi';
import { Group } from '../../../state/useGroup/useGroup';

export type ExtendedDominantSpeaker = RemoteParticipant | LocalParticipant | null | undefined;

interface UseDominantSpeakerProps {
  room: Room | null;
  mainApi: UseMainApiProps | null;
  group: Group | null;
}

export default function useDominantSpeaker({ room, mainApi, group }: UseDominantSpeakerProps) {
  const [dominantSpeaker, setDominantSpeaker] = useState<ExtendedDominantSpeaker>(room?.dominantSpeaker ?? null);

  const reportDominantSpeakerChange = useCallback(
    (speaker: RemoteParticipant) => {
      if (room?.name) {
        const participantName = group?.members[speaker!.identity]?.name || 'Anon';

        mainApi!.sendInMeetingEvent({
          groupInstanceId: room.name, // room.name is group instance id
          userId: speaker!.sid,
          name: participantName,
          event: InMeetingEvent.Speaker,
          timestamp: Date.now(),
        });
      }
    },
    [mainApi, room, group]
  );

  useEffect(() => {
    if (room) {
      // Sometimes, the 'dominantSpeakerChanged' event can emit 'null', which means that
      // there is no dominant speaker. If we change the main participant when 'null' is
      // emitted, the effect can be jarring to the user. Here we ignore any 'null' values
      // and continue to display the previous dominant speaker as the main participant.
      const handleDominantSpeakerChanged = (newDominantSpeaker: RemoteParticipant) => {
        if (newDominantSpeaker !== null) {
          reportDominantSpeakerChange(newDominantSpeaker);
        }
      };

      // Since 'null' values are ignored, we will need to listen for the 'participantDisconnected'
      // event, so we can set the dominantSpeaker to 'null' when they disconnect.
      const handleParticipantDisconnected = (participant: RemoteParticipant) => {
        setDominantSpeaker(prevDominantSpeaker => {
          return prevDominantSpeaker === participant ? null : prevDominantSpeaker;
        });
      };

      room.on('dominantSpeakerChanged', handleDominantSpeakerChanged);
      room.on('participantDisconnected', handleParticipantDisconnected);
      return () => {
        room.off('dominantSpeakerChanged', handleDominantSpeakerChanged);
        room.off('participantDisconnected', handleParticipantDisconnected);
      };
    }
  }, [room, reportDominantSpeakerChange]);

  return { dominantSpeaker, setDominantSpeaker };
}
