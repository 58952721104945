import React, { useState, useMemo } from 'react';
import { LocalParticipant, RemoteParticipant } from 'twilio-video';
import { makeStyles, createStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import { MAX_PARTICIPANTS_PER_SCREEN } from '../../utils/galleryView';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import ParticipantList from '../ParticipantList/ParticipantList';
import ParticipantListScreenSwitch from '../ParticipantListScreenSwitch/ParticipantListScreenSwitch';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      gap: '10px',
      background: theme.colors.BLACK,
      padding: '8px 8px 16px 8px',
      zIndex: 5,
      [theme.breakpoints.down('sm')]: {
        overflowY: 'auto',
      },
    },
    displayNone: {
      display: 'none',
    },
  })
);

export interface Screen {
  index: number;
  participants: Array<LocalParticipant | RemoteParticipant>;
}

interface ParticipantListScreensProps {
  participants: RemoteParticipant[];
}

export default function ParticipantListScreens({ participants }: ParticipantListScreensProps) {
  const classes = useStyles();

  const screenShareParticipant = useScreenShareParticipant();
  const { room } = useVideoContext();

  const localParticipant = room!.localParticipant;

  const [currentScreenIndex, setCurrentScreenIndex] = useState<number>(0);

  const allParticipants: Array<LocalParticipant | RemoteParticipant> = useMemo(() => {
    return [localParticipant, ...participants];
  }, [localParticipant, participants]);

  const shouldShowParticipants = useMemo(
    () => !(room && screenShareParticipant && screenShareParticipant.sid !== room.localParticipant.sid),
    [room, screenShareParticipant]
  );

  const handleSetCurrentScreenIndex = (index: number) => setCurrentScreenIndex(index);

  const screens: Screen[] = useMemo(() => {
    if (allParticipants.length <= MAX_PARTICIPANTS_PER_SCREEN) {
      return [
        {
          index: 0,
          participants: allParticipants,
        },
      ];
    } else {
      const tempScreens: Screen[] = [];

      for (let i = 0; i < allParticipants.length; i += MAX_PARTICIPANTS_PER_SCREEN) {
        tempScreens.push({
          index: tempScreens.length,
          participants: allParticipants.slice(i, i + MAX_PARTICIPANTS_PER_SCREEN),
        });
      }

      return tempScreens;
    }
  }, [allParticipants]);

  return (
    <aside className={clsx(classes.root, { [classes.displayNone]: !shouldShowParticipants })}>
      {screens.map(({ index, participants: screenParticipants }) => (
        <ParticipantList
          key={index}
          participants={screenParticipants}
          visible={shouldShowParticipants ? index === currentScreenIndex : false}
        />
      ))}
      <ParticipantListScreenSwitch
        screens={screens}
        currentScreenIndex={currentScreenIndex}
        handleSetCurrentScreenIndex={handleSetCurrentScreenIndex}
      />
    </aside>
  );
}
