import { createMuiTheme } from '@material-ui/core';

export const COLORS = {
  WHITE: '#FFFFFF',
  BLACK: '#1B1C1E',
  LIGHT_BLACK: '#2B2B2B',
  MID_BLACK: '#303030',
  LIGHT_GRAY: '#F0F0F2',
  BRAND_PURPLE: {
    DARK: '#625FA6',
    NORMAL: '#8C87ED',
    LIGHT: '#BAB7F4',
  },
  VIBRANT_BLUE: {
    DARK: '#246BB3',
    NORMAL: '#3399FF',
    LIGHT: '#99CCFF',
  },
  LIGHT_ORANGE: '#FCC787',
  SALMON: '#FF8C78',
  BLUCH_PINK: '#F5BAC4',
  VIVID_PURPLE: '#C39DFF',
  DULL_TURQUOISE: '#A1CCCF',
  GRAY: {
    50: '#FAFAFA',
    100: '#F5F5F5',
    200: '#EEEEEE',
    300: '#E0E0E0',
    400: '#BDBDBD',
    500: '#9E9E9E',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121',
  },
  GREEN: {
    50: '#E8F5E9',
    100: '#C8E6C9',
    200: '#A5D6A7',
    300: '#81C784',
    400: '#66BB6A',
    500: '#4CAF50',
    600: '#43A047',
    700: '#388E3C',
    800: '#2E7D32',
    900: '#1B5E20',
  },
  RED: {
    50: '#FFEBEE',
    100: '#FFCDD2',
    200: '#EF9A9A',
    300: '#E57373',
    400: '#EF5350',
    500: '#F44336',
    600: '#E53935',
    700: '#D32F2F',
    800: '#C62828',
    900: '#B71C1C',
  },
};

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    sidebarWidth: number;
    sidebarMobileHeight: number;
    brand: string;
    footerHeight: number;
    mobileTopBarHeight: number;
    mobileFooterHeight: number;
    sidebarMobilePadding: number;
    participantVideoMinWidth: number;
    participantVideoMinHeight: number;
    participantBorderWidth: number;
    dominantSpeakerBorderWidth: number;
    chatWindowWidth: number;
    asidePanelWidth: number;
    avatarCircleSize: number;
    colors: typeof COLORS;
  }

  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    sidebarWidth?: number;
    sidebarMobileHeight?: number;
    brand: string;
    footerHeight: number;
    mobileTopBarHeight: number;
    mobileFooterHeight: number;
    sidebarMobilePadding: number;
    participantVideoMinWidth: number;
    participantVideoMinHeight: number;
    participantBorderWidth: number;
    dominantSpeakerBorderWidth: number;
    chatWindowWidth?: number;
    asidePanelWidth: number;
    avatarCircleSize: number;
    colors: typeof COLORS;
  }
}

export default createMuiTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        'html, body, #root': {
          height: '100%',
        },
      },
    },
    MuiButton: {
      root: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 500,
        textTransform: 'none',
        borderRadius: '100px',
        fontSize: 16,
        padding: '12px 24px',
        minHeight: 52, // for safari mobile small screen
      },
      contained: {
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none',
        },
      },
      containedPrimary: {
        color: COLORS.WHITE,
      },
      outlinedPrimary: {
        border: '2px solid #027AC5',
        '&:hover': {
          border: '2px solid rgb(1, 85, 137)',
        },
      },
      startIcon: {
        marginRight: '6px',
      },
    },
    MuiTypography: {
      // Header
      h1: {
        color: COLORS.MID_BLACK,
        fontFamily: 'Raleway',
        fontWeight: 600,
        fontSize: 40,
      },
      // SubHeader
      h2: {
        fontFamily: 'Poppins',
        color: COLORS.MID_BLACK,
        fontWeight: 400,
        fontSize: 25,
      },
      // Cta
      button: {
        fontFamily: 'Poppins',
        color: COLORS.MID_BLACK,
        fontWeight: 600,
        fontSize: 18,
        textTransform: 'none',
      },
      // Paragraph
      body1: {
        fontFamily: 'Poppins',
        color: COLORS.MID_BLACK,
        fontWeight: 400,
        fontSize: 18,
      },
      // Label1
      subtitle1: {
        fontFamily: 'Poppins',
        color: COLORS.MID_BLACK,
        fontWeight: 500,
        fontSize: 16,
      },
      // Label2
      subtitle2: {
        fontFamily: 'Poppins',
        color: COLORS.MID_BLACK,
        fontWeight: 300,
        fontSize: 14,
      },
      // Link
      caption: {
        fontFamily: 'Poppins',
        textDecoration: 'underline',
        color: COLORS.MID_BLACK,
        fontWeight: 500,
        fontSize: 16,
      },
    },
    MuiInputBase: {
      root: {
        fontSize: '0.9rem',
      },
    },
    MuiSelect: {
      root: {
        padding: '0.85em',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '16px',
      },
    },
    MuiTextField: {
      root: {
        color: 'rgb(40, 42, 43)',
      },
    },
    MuiInputLabel: {
      root: {
        color: 'rgb(40, 42, 43)',
        fontSize: '1.1rem',
        marginBottom: '0.2em',
        fontWeight: 500,
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: 'rgb(136, 140, 142)',
      },
    },
  },
  typography: {
    fontFamily: 'Raleway, Poppins, sans-serif',
  },
  palette: {
    primary: {
      main: '#3399FF',
    },
    secondary: {
      main: '#8C87ED',
    },
  },
  colors: COLORS,
  brand: COLORS.BRAND_PURPLE.NORMAL,
  footerHeight: 100,
  mobileFooterHeight: 100,
  sidebarWidth: 355,
  sidebarMobileHeight: 90,
  sidebarMobilePadding: 8,
  participantVideoMinWidth: 260,
  participantVideoMinHeight: 186,
  participantBorderWidth: 2,
  dominantSpeakerBorderWidth: 3,
  mobileTopBarHeight: 52,
  chatWindowWidth: 320,
  asidePanelWidth: 360,
  avatarCircleSize: 62,
});
