import React from 'react';
import { LocalParticipant, RemoteParticipant } from 'twilio-video';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid, GridSize } from '@material-ui/core';
import Participant from '../Participant/Participant';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { calcMaxParticipantsPerRow, calcColumns } from '../../utils/galleryView';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      height: '100%',
    },
    container: {
      display: 'flex',
      background: theme.colors.BLACK,
      padding: '8px 8px 16px 8px',
      zIndex: 5,
      [theme.breakpoints.down('sm')]: {
        overflowY: 'auto',
      },
    },
    participantsContainer: {
      overflowY: 'auto',
      justifyContent: 'flex-start',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'center',
      },
    },
    participantContainer: {
      minWidth: theme.participantVideoMinWidth,
      minHeight: theme.participantVideoMinHeight,
    },
    scrollContainer: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
    },
    innerScrollContainer: {
      width: `calc(${theme.sidebarWidth}px - 4em)`,
      padding: '2em 0',
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
        padding: `${theme.sidebarMobilePadding}px`,
        display: 'flex',
      },
    },
  })
);

interface Props {
  participants: Array<LocalParticipant | RemoteParticipant>;
  visible: boolean;
}

export default function ParticipantList({ visible, participants }: Props) {
  const classes = useStyles();

  const { joinedParticipants, dominantSpeaker, emotionalEmojisService, room } = useVideoContext();

  const { remoteParticipantsEmojisQueue, remoteEmojiDisplayed } = emotionalEmojisService;

  const maxParticipantPerRow = calcMaxParticipantsPerRow(participants.length);

  if (!visible) {
    return (
      <>
        {participants.map((participant, idx) => (
          <Participant key={`part-${idx}`} participant={participant} hideParticipant audioOnly />
        ))}
      </>
    );
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={2} className={classes.participantsContainer}>
        {participants.map((participant, idx) => {
          const xs = calcColumns({
            numOfParticipants: participants.length,
            maxParticipantPerRow,
            participantIdx: idx,
          });

          return (
            <Grid key={`part-${idx}`} item xs={Number(xs) as GridSize} className={classes.participantContainer}>
              <Participant
                participant={participant}
                isLocalParticipant={participant.sid === room?.localParticipant.sid}
                isDominantSpeaker={participants.length > 2 && participant.sid === dominantSpeaker?.sid}
                joinedTime={idx > 0 ? joinedParticipants[participant.sid] : undefined}
                emotionalEmojis={remoteParticipantsEmojisQueue[participant.sid]}
                onEmojiDisplayed={remoteEmojiDisplayed}
              />
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
