import React, { useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { Text, Button } from '../../../DesignSystem';
import RestartGameIcon from '../../../../icons/RestartGameIcon';
import SkipParticipantIcon from '../../../../icons/SkipParticipantIcon';

import { track } from '../../../../services/analytics';

import { COLORS } from '../../../../theme';

import { CardGame } from '../../../../state/useApi/api.types';
import { NextMember } from '../../../VideoProvider/useCardGame/useCardGame';

export enum GameState {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

interface GameItemProps {
  game: CardGame;
  state: GameState;
  onStart?: () => void;
  onEnd?: () => void;
  onRestart?: () => void;
  nextMember?: NextMember | null;
  shuffleNextMember?: () => void;
  canPlay: boolean;
  canShuffleNextMember?: boolean;
}

interface StyleProps {
  state: GameState;
  canPlay: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  container: {
    backgroundColor: theme.colors.BLACK,
    borderRadius: '8px',
    padding: '20px 12px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  gameName: {
    flex: 1,
    color: theme.colors.WHITE,
  },
  toggleStateContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '12px',
  },
  toggleStateButton: {
    borderRadius: '4px',
    padding: '8px 12px',
    minWidth: '90px',
    cursor: 'pointer',
    backgroundColor: ({ state, canPlay }) =>
      state === GameState.ACTIVE
        ? theme.colors.RED[400]
        : canPlay
        ? theme.colors.VIBRANT_BLUE.NORMAL
        : theme.colors.VIBRANT_BLUE.LIGHT,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  toggleStateButtonText: {
    color: theme.colors.WHITE,
  },
  activeGameContainer: {
    marginTop: '16px',
  },
  nextMemberContainer: {
    marginTop: '16px',
    marginBottom: '48px',
  },
  nextCardContainer: {
    marginBottom: '8px',
  },
  nextCardCta: {
    borderRadius: '4px',
    marginBottom: '8px',
    '&:hover': {
      backgroundColor: theme.colors.VIBRANT_BLUE.NORMAL,
    },
  },
  nextCardExplanation: {
    color: theme.colors.GRAY[700],
  },
  nextMemberTitle: {
    color: theme.colors.GRAY[500],
  },
  nextMemberBox: {
    marginTop: '4px',
    padding: '2px 8px',
    borderRadius: '4px',
    border: `1px solid ${theme.colors.GRAY[800]}`,
    width: 'fit-content',
  },
  nextMemberName: {
    color: theme.colors.VIBRANT_BLUE.NORMAL,
  },
  skipNextMemberContainer: {
    marginTop: '16px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  skipNextMemberText: {
    color: theme.colors.VIBRANT_BLUE.NORMAL,
    marginLeft: '2px',
  },
  restartGameCta: {
    '&:hover': {
      backgroundColor: theme.colors.VIBRANT_BLUE.NORMAL,
    },
  },
  restartGameCtaText: {
    color: theme.colors.WHITE,
    marginLeft: '4px',
  },
  restartGameExplanation: {
    marginTop: '8px',
    color: theme.colors.GRAY[800],
  },
}));

export default function GameItem({
  game,
  state,
  onStart = () => null,
  onEnd = () => null,
  onRestart = () => null,
  nextMember,
  shuffleNextMember = () => null,
  canPlay,
  canShuffleNextMember = false,
}: GameItemProps) {
  const classes = useStyles({ state, canPlay });

  useEffect(() => {
    if (canShuffleNextMember && !nextMember) {
      shuffleNextMember();
    }
  }, [nextMember, canShuffleNextMember, shuffleNextMember]);

  return (
    <>
      <div className={classes.container}>
        <Text preset="Label2" text={game.displayName} className={classes.gameName} />
        <div className={classes.toggleStateContainer}>
          <div
            className={classes.toggleStateButton}
            onClick={() => {
              if (state === GameState.INACTIVE) {
                track('click start game', { game: game.displayName });
                onStart();
              } else {
                track('click end game', { game: game.displayName });
                onEnd();
              }
            }}
          >
            <Text
              preset="Label1"
              tx={`gfDashboard.cardGames.${state === GameState.INACTIVE ? 'startGame' : 'endGame'}`}
              className={classes.toggleStateButtonText}
            />
          </div>
        </div>
      </div>
      {state === GameState.ACTIVE && (
        <div className={classes.activeGameContainer}>
          <div className={classes.nextMemberContainer}>
            {canPlay && canShuffleNextMember && (
              <div className={classes.nextCardContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  tx="gfDashboard.cardGames.nextCard"
                  onClick={() => {
                    track('click next card game', { game: game.displayName });
                    shuffleNextMember();
                  }}
                  className={classes.nextCardCta}
                />
                <Text
                  preset="Label2"
                  tx="gfDashboard.cardGames.nextCardExplanation"
                  className={classes.nextCardExplanation}
                />
              </div>
            )}
            {nextMember && (
              <>
                <Text preset="Label2" tx="gfDashboard.cardGames.nextMember" className={classes.nextMemberTitle} />
                <div className={classes.nextMemberBox}>
                  <Text preset="Label1" text={nextMember.name} className={classes.nextMemberName} />
                </div>
                {canShuffleNextMember && (
                  <div
                    className={classes.skipNextMemberContainer}
                    onClick={() => {
                      track('click skip member card game', { game: game.displayName });
                      shuffleNextMember();
                    }}
                  >
                    <SkipParticipantIcon color={COLORS.VIBRANT_BLUE.NORMAL} />
                    <Text
                      preset="Link"
                      tx="gfDashboard.cardGames.shuffleNextMember"
                      className={classes.skipNextMemberText}
                    />
                  </div>
                )}
              </>
            )}
          </div>
          {canPlay && (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  track('click restart card game', { game: game.displayName });
                  onRestart();
                }}
                className={classes.restartGameCta}
              >
                <RestartGameIcon color={COLORS.WHITE} />
                <Text preset="Label1" tx="gfDashboard.cardGames.restartGame" className={classes.restartGameCtaText} />
              </Button>
              <Text
                preset="Label2"
                tx="gfDashboard.cardGames.restartGameParagraph"
                className={classes.restartGameExplanation}
              />
            </>
          )}
        </div>
      )}
    </>
  );
}
