import React, { useEffect, useState } from 'react';
import { Text } from '../DesignSystem/Text';
import { Button, Modal, TextareaAutosize, createStyles, makeStyles, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Rate from '../../components/SessionSurvay/Rate';
import { Survey, SurveyAnswer } from './survey.types';
import { track } from '../../services/analytics';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modalContainer: {
      background: theme.colors.WHITE,
      paddingTop: '2em',
      paddingBottom: '2em',
      width: 500,
      position: 'relative',
      borderRadius: 4,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        height: 447,
        width: 280,
      },
    },
    modalInnerContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
    },
    close: {
      position: 'absolute',
      right: '8px',
      top: '8px',
    },
    textArea: {
      padding: '0.75em 1em',
      marginTop: '1em',
      width: '100%',
      border: '0.5',
      paddingLeft: 'auto',
      paddingRight: 'auto',
      resize: 'none',
      fontFamily: 'Poppins',
      color: theme.colors.MID_BLACK,
      fontWeight: 400,
      fontSize: 18,
    },
    surveyTitle: {
      marginBottom: '24px',
      fontWeight: 'bold',
    },
    button: {
      marginTop: '1.5em',
      width: '60%',
    },
    survayCtaText: {
      color: theme.colors.WHITE,
      fontWeight: 'bold',
    },
  })
);

interface SurveyPopUpProps {
  survey: Survey | null;
  groupInstanceId: string | null;
  handleSurveyPopUpClose: () => void;
  handleSurveyPopUpSubmit: (surveyAnswers: SurveyAnswer[]) => void;
}

const SurveyPopUp = ({
  survey,
  groupInstanceId,
  handleSurveyPopUpClose,
  handleSurveyPopUpSubmit,
}: SurveyPopUpProps) => {
  const classes = useStyles();
  const [surveyAnswers, setSurveyAnswers] = useState<SurveyAnswer[]>([]);

  useEffect(() => {
    let answers: SurveyAnswer[] = [];
    for (let i = 0; i < survey!.questions.length; i++) {
      answers[i] = { rate: 0, feedback: '' };
    }
    setSurveyAnswers(answers);
  }, [survey]);

  const handleClose = () => {
    track('click session survey close', { groupInstanceId });
    handleSurveyPopUpClose();
  };

  const handleSubmit = () => {
    track('click session survey submit', { groupInstanceId });
    handleSurveyPopUpSubmit(surveyAnswers);
  };

  return (
    <Modal
      open={true}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.modal}>
        <div className={classes.modalContainer}>
          <div className={classes.close} onClick={handleClose}>
            <CloseIcon fontSize="small" />
          </div>
          <Text preset="SubHeader" text={survey?.header} className={classes.surveyTitle} />
          {survey?.questions.map((u, idx) => {
            return (
              <div key={`QContainer-${idx}`} className={classes.modalInnerContainer}>
                <Text key={'QConetent_' + idx} preset="Paragraph" text={u.content} gutterBottom />
                <Rate
                  key={`QRate-${idx}`}
                  highName={u?.lowName}
                  lowName={u?.highName}
                  scale={u?.scale}
                  onChange={newRate => {
                    track('click session survey rate', { groupInstanceId, rate: newRate });
                    let localSurveyAnswers = surveyAnswers;
                    localSurveyAnswers[idx].rate = newRate;
                    setSurveyAnswers(localSurveyAnswers);
                  }}
                />
                <TextareaAutosize
                  key={'QFeedback_' + idx}
                  rowsMin={1}
                  rowsMax={3}
                  className={classes.textArea}
                  aria-label="feedback"
                  placeholder="Do you have anything else to add..."
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    track('click session survey feedback', { groupInstanceId, feedback: e.target.value });
                    let localSurveyAnswers = surveyAnswers;
                    localSurveyAnswers[idx].feedback = e.target.value;
                    setSurveyAnswers(localSurveyAnswers);
                  }}
                />
              </div>
            );
          })}
          <Button variant="contained" color="secondary" onClick={handleSubmit} className={classes.button}>
            <Text preset="Label1" tx="survey.cta" className={classes.survayCtaText} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SurveyPopUp;
