import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { AsidePanel } from '../AsidePanel/AsidePanel';
import ScreenShareParticipant from '../ScreenShareParticipant/ScreenShareParticipant';
import ReceivedEmoji from '../EmotionalEmojis/ReceivedEmoji';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { useAppState } from '../../state';
import { useDataTrackListener } from '../../services/eventListeners/dataTrackListener';
import { useMeetingAnalyzerSocket } from '../../hooks/useMeetingAnalyzerSocket/useMeetingAnalyzerSocket';
import ParticipantListScreens from '../ParticipantListScreens/ParticipantListScreens';
import RoomNotifications from '../RoomNotifications/RoomNotifications';
import useCirclesChatContext from '../../hooks/useCirclesChatContext/useCirclesChatContext';

import { ROLE } from '../../state/useAccount/useAccount';

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      position: 'relative',
      height: '100%',
      display: 'grid',
      gridTemplateColumns: `1fr`,
      gridTemplateRows: '100%',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: `100%`,
      },
    },
    asidePanelOpen: {
      gridTemplateColumns: `1fr ${theme.asidePanelWidth}px`,
    },
    emotionalEmojisContainer: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    },
  };
});

export default function Room() {
  const classes = useStyles();

  const { URLRoomId } = useParams<{ URLRoomId: string }>();

  const [fetchingGroup, setFetchingGroup] = useState(false);

  const { account, group, mainApi, setGroup } = useAppState();
  const { isMemberChatSelected } = useCirclesChatContext();
  const {
    emotionalEmojisService: { emojisQueue, emojiDisplayed },
    room,
    setDominantSpeaker,
  } = useVideoContext();
  const { currentActiveUser } = useMeetingAnalyzerSocket();
  const participants = useParticipants();

  // initialize the data track event listener
  useDataTrackListener({ participants });

  // set new dominant speaker if needed
  useEffect(() => {
    if (currentActiveUser && room) {
      const allParticipants = [room.localParticipant, ...participants];
      const newDominantSpeaker = allParticipants.find(participant => participant.sid === currentActiveUser.userId);
      if (newDominantSpeaker) {
        setDominantSpeaker(newDominantSpeaker);
      }
    }
  }, [currentActiveUser, participants, room, setDominantSpeaker]);

  useEffect(() => {
    const fetchGroup = async (groupId: string) => {
      setFetchingGroup(true);
      const res = await mainApi?.getGroupDetails(groupId);
      if (res?.data) {
        setGroup(res.data);
      }
    };

    if (URLRoomId && mainApi?.isReady && !group?.id && !fetchingGroup) {
      fetchGroup(URLRoomId);
    }
  }, [URLRoomId, mainApi, setGroup, group, fetchingGroup]);

  const GFRole = [ROLE.ADMIN, ROLE.GROUP_LEADER, ROLE.PEER_GROUP_LEADER].includes(account?.role || ROLE.MEMBER);

  return (
    <div className={clsx(classes.container, { [classes.asidePanelOpen]: GFRole || isMemberChatSelected })}>
      <ScreenShareParticipant />
      <ParticipantListScreens participants={participants} />
      <AsidePanel participants={participants} />
      {emojisQueue.map(e => {
        if (!e.active) {
          return null;
        }
        return (
          <div key={e.key} className={classes.emotionalEmojisContainer}>
            <ReceivedEmoji
              key={e.key}
              emoji={e.emoji}
              senderName={e.senderName}
              centerOffset={e.centerOffset!}
              onAnimationEnd={() => emojiDisplayed(e.key)}
            />
          </div>
        );
      })}
      <RoomNotifications />
    </div>
  );
}
