import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import Box from '@material-ui/core/Box';
import { Text } from '../../DesignSystem';
import { useAppState } from '../../../state';
import useCirclesChatContext from '../../../hooks/useCirclesChatContext/useCirclesChatContext';
import { translate } from '../../../i18n';

const useStyles = makeStyles(theme => ({
  clickable: {
    cursor: 'pointer',
  },
  colorGray500: {
    color: theme.colors.GRAY[500],
  },
  contentContainer: {
    display: 'flex',
  },
  message: {
    color: theme.colors.GRAY[500],
    padding: theme.typography.pxToRem(5),
    width: '300px',
    wordWrap: 'break-word',
  },
  smallSize: {
    height: theme.typography.pxToRem(14),
    width: theme.typography.pxToRem(14),
  },
  verticalDivider: {
    backgroundColor: theme.colors.BRAND_PURPLE.NORMAL,
    border: `${theme.typography.pxToRem(3)} solid ${theme.colors.BRAND_PURPLE.NORMAL}`,
    borderRadius: theme.typography.pxToRem(5),
    width: theme.typography.pxToRem(1),
    height: 'auto',
  },
  wrapper: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
    padding: `${theme.typography.pxToRem(5)} ${theme.typography.pxToRem(10)}`,
  },
}));

interface IReplyText {
  replyMessageId: string;
  setReplyMessageId: React.Dispatch<React.SetStateAction<string>>;
}

export const ReplyText = ({ replyMessageId, setReplyMessageId }: IReplyText) => {
  const classes = useStyles();

  const { account } = useAppState();
  const currentUserId = account ? account.id : null;

  const { members, messages } = useCirclesChatContext();

  const messageToReply = useMemo(
    () => (replyMessageId !== '' ? messages.find(message => message.id === replyMessageId) || null : null),
    [messages, replyMessageId]
  );

  const authorName = useMemo(() => {
    if (messageToReply) {
      if (messageToReply.senderId === currentUserId) {
        return translate('circlesChat.you');
      }
      const sender = members[messageToReply.senderId];
      if (sender) {
        return sender.name;
      }
      return null;
    }
    return null;
  }, [currentUserId, members, messageToReply]);

  return (
    <>
      {messageToReply ? (
        <Box className={classes.wrapper}>
          <Box className={classes.contentContainer}>
            <Box className={classes.verticalDivider} />
            <Box className={classes.message}>
              {authorName ? <Text className={classes.colorGray500} preset="Label1" text={authorName} /> : null}
              {messageToReply.message ? (
                <Text className={classes.colorGray500} preset="Label2" text={messageToReply.message} />
              ) : null}
            </Box>
          </Box>
          <Box className={classes.clickable} onClick={() => setReplyMessageId('')}>
            <CloseIcon className={classes.smallSize} />
          </Box>
        </Box>
      ) : null}
    </>
  );
};
