import React from 'react';

export default function HangupIcon({ color }: { color: string }) {
  return (
    <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.45453 19.049L4.45713 24.5862C4.45627 24.9778 4.5328 25.3658 4.68232 25.7278C4.83185 26.0897 5.05141 26.4186 5.32842 26.6955C5.8928 27.2599 6.64444 27.5694 7.4403 27.5694L13.4014 27.5694C13.7931 27.5702 14.181 27.4937 14.543 27.3442C14.905 27.1947 15.2339 26.9751 15.5107 26.6981C16.0751 26.1337 16.3846 25.3821 16.3846 24.5862L16.3846 20.2272C18.0193 19.6128 19.7516 19.2992 21.4979 19.3013C23.2462 19.297 24.981 19.608 26.619 20.2194L26.619 24.5784C26.6181 24.97 26.6946 25.358 26.8441 25.72C26.9937 26.0819 27.2132 26.4108 27.4902 26.6877C28.0546 27.2521 28.8063 27.5616 29.6021 27.5616L35.5633 27.5668C35.9555 27.5675 36.344 27.4907 36.7065 27.3407C37.0689 27.1907 37.3981 26.9706 37.6752 26.6929C38.2395 26.1285 38.549 25.3769 38.549 24.581L38.549 19.0516C38.5516 17.7798 38.0367 16.5418 37.1368 15.6419C36.9469 15.452 36.7493 15.2856 36.5386 15.1373C32.4553 12.2088 27.1131 10.5962 21.5031 10.5936C15.8983 10.591 10.5613 12.2036 6.45978 15.1347C5.20877 16.032 4.45973 17.4937 4.45453 19.049Z"
        fill={color}
      />
    </svg>
  );
}
