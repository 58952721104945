import React, { createContext, useContext, useReducer, useState } from 'react';
import { TwilioError } from 'twilio-video';
import { settingsReducer, initialSettings, Settings, SettingsAction } from './settings/settingsReducer';
import useMainApi, { UseMainApiProps } from './useApi/useMainApi';
import useAnalyzerApi, { UseAnalyzerApiProps } from './useApi/useAnalyzerApi';
import useAccount, { Account } from './useAccount/useAccount';
import useGroup, { Group } from './useGroup/useGroup';
import useActiveSinkId from './useActiveSinkId/useActiveSinkId';
import { useChatApi, UseChatApiProps } from './useApi/useChatApi';

export interface ExtendedError extends TwilioError {
  tx?: string;
  txOptions?: object;
}

export interface StateContextType {
  error: ExtendedError | Error | null;
  setError(error: ExtendedError | Error | null): void;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  settings: Settings;
  dispatchSetting: React.Dispatch<SettingsAction>;
  account: Account | null;
  setAccount: (account: Account) => void;
  group: Group | null;
  setGroup: (group: Group) => void;
  isAuthReady: boolean;
  isMeetingHost: boolean;
  mainApi: UseMainApiProps | null;
  chatApi: UseChatApiProps | null;
  analyzerApi: UseAnalyzerApiProps | null;
  endedByHost: boolean;
  setEndedByHost: (endedByHost: boolean) => void;
}

export const StateContext = createContext<StateContextType>(null!);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks fron being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<ExtendedError | null>(null);
  const [endedByHost, setEndedByHost] = useState<boolean | null>(false);

  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);

  const contextValue = {
    error,
    setError,
    endedByHost,
    setEndedByHost,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
    ...useAccount(),
    ...useGroup(),
    mainApi: useMainApi(),
    chatApi: useChatApi(),
    analyzerApi: useAnalyzerApi(),
  } as StateContextType;

  contextValue.isMeetingHost =
    (contextValue.account && contextValue.group && contextValue.account.id === contextValue.group.leaderAccountId) ||
    false;

  return <StateContext.Provider value={{ ...contextValue }}>{props.children}</StateContext.Provider>;
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
