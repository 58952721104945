import React, { PropsWithChildren } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import enhanceMessage from './enhanceMessage';
import { translate } from '../../i18n/translate';

import { ExtendedError } from '../../state';

interface ErrorDialogProps {
  dismissError: Function;
  error: ExtendedError | Error | null;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorCode: {
      fontSize: '12px',
    },
  })
);

function ErrorDialog({ dismissError, error }: PropsWithChildren<ErrorDialogProps>) {
  const classes = useStyles();
  const { message, code } = error || {};
  const { tx, txOptions } = (error as ExtendedError) || {};
  const i18nText = tx && translate(tx, txOptions);
  const enhancedMessage = enhanceMessage(i18nText || message, code);

  return (
    <Dialog open={error !== null} onClose={() => dismissError()} fullWidth={true} maxWidth="xs">
      <DialogTitle>{enhancedMessage.title || translate('errors.errorTitle')}</DialogTitle>
      <DialogContent>
        <DialogContentText>{enhancedMessage.message}</DialogContentText>
        {Boolean(code) && (
          <pre>
            <code className={classes.errorCode}>{translate('errors.errorCode', { code })}</code>
          </pre>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => dismissError()} color="primary" autoFocus>
          {translate('common.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ErrorDialog;
