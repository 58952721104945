import isPlainObject from 'is-plain-object';
import { COLORS } from '../theme';

export const isMobile = (() => {
  if (typeof navigator === 'undefined' || typeof navigator.userAgent !== 'string') {
    return false;
  }
  return /Mobile/.test(navigator.userAgent);
})();

export const strSnakeToCamel = (str: string) =>
  str.replace(/([-_][a-z])/g, group => group.toUpperCase().replace('_', ''));

// Recursively removes any object keys with a value of undefined
export function removeUndefineds<T>(obj: T): T {
  if (!isPlainObject(obj)) return obj;

  const target: { [name: string]: any } = {};

  for (const key in obj) {
    const val = obj[key];
    if (typeof val !== 'undefined') {
      target[key] = removeUndefineds(val);
    }
  }

  return target as T;
}

export const getParticipantName = (fullName: string) => {
  if (!fullName) {
    return '';
  }

  const names = fullName.split(' ');
  const shortName = names.length > 1 ? `${names[0].charAt(0)}${names[names.length - 1].charAt(0)}` : names[0].charAt(0);
  return shortName.toUpperCase();
};

export const getParticipantBkgColor = (name: string) => {
  const colors = [
    COLORS.VIVID_PURPLE,
    COLORS.VIBRANT_BLUE.NORMAL,
    COLORS.DULL_TURQUOISE,
    COLORS.LIGHT_ORANGE,
    COLORS.RED[500],
    COLORS.BLUCH_PINK,
  ];
  const index = (name?.charCodeAt(0) || 0) % colors.length;
  return colors[index];
};
