import React from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core';
import ReactCardFlip from 'react-card-flip';
import { Text } from '../DesignSystem';
import ClosedCardVertical from '../../assets/images/closedCardVertical.png';
import ClosedCardHorizontal from '../../assets/images/closedCardHorizontal.png';

import { CardWithStatus, CardStatus } from '../VideoProvider/useCardGame/useCardGame';

interface GameCardProps {
  card: CardWithStatus;
  onClick?: () => void;
  clickable: boolean;
}

const CARD_WIDTH = 96;
const CARD_HEIGHT = 145;

interface StyleProps {
  isVertical: boolean;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  container: {
    width: ({ isVertical }) => (isVertical ? CARD_WIDTH : CARD_HEIGHT),
    height: ({ isVertical }) => (isVertical ? CARD_HEIGHT : CARD_WIDTH),
    borderRadius: '4px',
  },
  clickable: {
    cursor: 'pointer',
  },
  image: {
    width: ({ isVertical }) => (isVertical ? CARD_WIDTH : CARD_HEIGHT),
    height: ({ isVertical }) => (isVertical ? CARD_HEIGHT : CARD_WIDTH),
  },
  cardTitleContainer: {
    width: CARD_HEIGHT - 4, // because its horizontal
    height: CARD_WIDTH - 4, // because its horizontal
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
    padding: '32px 16px',
    boxShadow: '0px 0px 0px 4px rgba(255, 255, 255, 0.6)',
    borderRadius: '4px',
  },
}));

export default function GameCard({ card, onClick = () => null, clickable }: GameCardProps) {
  const isVertical = !!card.picture;
  const ClosedCard = isVertical ? ClosedCardVertical : ClosedCardHorizontal;

  const classes = useStyles({ isVertical });

  return (
    <ReactCardFlip isFlipped={card.status === CardStatus.Open} flipDirection={isVertical ? 'horizontal' : 'vertical'}>
      <div
        className={clsx(classes.container, { [classes.clickable]: clickable })}
        onClick={() => {
          if (clickable) {
            onClick();
          }
        }}
      >
        <img src={ClosedCard} className={classes.image} alt="" />
      </div>
      <div className={classes.container}>
        {card.picture && <img src={card.picture} className={classes.image} alt="" />}
        {card.title && !card.picture && (
          <div className={classes.cardTitleContainer}>
            <Text preset="Cta" text={card.title} />
          </div>
        )}
      </div>
    </ReactCardFlip>
  );
}
