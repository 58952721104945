import { useCallback, useEffect, useRef, useState } from 'react';
import Video, { ConnectOptions, LocalTrack, Room } from 'twilio-video';
import { Callback } from '../../../types';
import { isMobile } from '../../../utils';

import { UseMainApiProps } from '../../../state/useApi/useMainApi';
import { InMeetingStatus } from '../../../state/useApi/api.types';
import { Group } from '../../../state/useGroup/useGroup';

import { CirclesConnectOptions } from '../../../state/useApi/api.types';

// @ts-ignore
window.TwilioVideo = Video;

interface UseRoomProps {
  localTracks: LocalTrack[];
  onError: Callback;
  options?: ConnectOptions;
  mainApi: UseMainApiProps | null;
  group: Group | null;
  isMeetingHost: boolean;
}

export default function useRoom({ localTracks, onError, options, mainApi, group, isMeetingHost }: UseRoomProps) {
  const [room, setRoom] = useState<Room | null>(null);
  const [isConnecting, setIsConnecting] = useState(false);
  const optionsRef = useRef(options);

  useEffect(() => {
    // This allows the connect function to always access the most recent version of the options object. This allows us to
    // reliably use the connect function at any time.
    optionsRef.current = options;
  }, [options]);

  const connect = useCallback(
    (token: string, circlesConnectOptions: CirclesConnectOptions) => {
      const connectOptions = { ...optionsRef.current, tracks: localTracks };

      if (circlesConnectOptions.bandwidthApi) {
        connectOptions.bandwidthProfile = { video: { ...circlesConnectOptions.bandwidthApi } };
      }

      setIsConnecting(true);
      return Video.connect(token, connectOptions).then(
        newRoom => {
          setRoom(newRoom);
          const disconnect = () => newRoom.disconnect();

          const participantName = group?.members[newRoom.localParticipant.identity]?.name || 'Anon';

          mainApi?.sendInMeetingStatus({
            groupInstanceId: newRoom.name, // room.name is group instance id
            userId: newRoom.localParticipant.sid,
            name: participantName,
            isHost: isMeetingHost,
            status: InMeetingStatus.Joined,
          });

          // This app can add up to 13 'participantDisconnected' listeners to the room object, which can trigger
          // a warning from the EventEmitter object. Here we increase the max listeners to suppress the warning.
          newRoom.setMaxListeners(15);

          newRoom.once('disconnected', () => {
            mainApi?.sendInMeetingStatus({
              groupInstanceId: newRoom.name, // room.name is group instance id
              userId: newRoom.localParticipant.sid,
              name: participantName,
              isHost: isMeetingHost,
              status: InMeetingStatus.Left,
            });

            // Reset the room only after all other `disconnected` listeners have been called.
            setTimeout(() => setRoom(null));
            window.removeEventListener('beforeunload', disconnect);

            if (isMobile) {
              window.removeEventListener('pagehide', disconnect);
            }
          });

          // @ts-ignore
          window.twilioRoom = newRoom;

          newRoom.localParticipant.videoTracks.forEach(publication =>
            // All video tracks are published with 'low' priority because the video track
            // that is displayed in the 'MainParticipant' component will have it's priority
            // set to 'high' via track.setPriority()
            publication.setPriority('low')
          );

          setIsConnecting(false);

          // Add a listener to disconnect from the room when a user closes their browser
          window.addEventListener('beforeunload', disconnect);

          if (isMobile) {
            // Add a listener to disconnect from the room when a mobile user closes their browser
            window.addEventListener('pagehide', disconnect);
          }
        },
        error => {
          onError(error);
          setIsConnecting(false);
        }
      );
    },
    [localTracks, onError, mainApi, group, isMeetingHost]
  );

  return { room, isConnecting, connect };
}
