import React from 'react';

export const MessagesIconWithIndicator = () => {
  return (
    <svg width="27" height="27" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.779785 7.49112C0.779785 5.69619 2.23486 4.24112 4.02979 4.24112H23.3551C25.15 4.24112 26.6051 5.69619 26.6051 7.49112V19.5027C26.6051 21.2976 25.15 22.7527 23.3551 22.7527H5.85655C5.3108 22.7527 4.79629 23.0073 4.46525 23.4412L1.22855 27.6835C1.08345 27.8736 0.77979 27.771 0.77979 27.5318L0.779785 23.5027V7.49112Z"
        stroke="#9E9E9E"
        strokeWidth="1.5"
      />
      <path
        d="M5.75354 9.23508H22.105M5.75354 13.681H22.105M5.75354 18.127H13.1319"
        stroke="#9E9E9E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse cx="26.4228" cy="4.92765" rx="4.31781" ry="4.30744" fill="#F44336" />
    </svg>
  );
};
