import { useCallback, useEffect, useState } from 'react';
import { Room, RemoteParticipant, LocalDataTrack } from 'twilio-video';

import { CardGame, Card, InMeetingEvent } from '../../../state/useApi/api.types';
import { Group } from '../../../state/useGroup/useGroup';

export enum CardStatus {
  Open = 'open',
  Close = 'close',
}
export type CardWithStatus = Card & {
  status: CardStatus;
};
export interface NextMember {
  sid: string;
  name: string;
  card: CardWithStatus;
}

export interface UseCardGame {
  // public to everybody
  nextMember: NextMember | null;
  cards: Record<string, CardWithStatus>;
  flipCard: (sid: string, status: CardStatus, notifyOthers?: boolean) => void;
  // host props
  canPlay: boolean;
  canShuffleNextMember: boolean;
  game: CardGame | null;
  startGame: (g: CardGame) => void;
  endGame: () => void;
  restartGame: () => void;
  shuffleNextMember: () => void;
  onHostDisconnected: () => void;
  // participants props
  onCardsChange: (c: Record<string, CardWithStatus>, n: { userId: string; name: string } | null) => void;
  onNextMemberChange: (sid: string, name: string) => void;
  onGameEnd: () => void;
}

export default function useCardGame({
  room,
  localDataTrack,
  isMeetingHost,
  group,
}: {
  room: Room | null;
  localDataTrack: LocalDataTrack;
  isMeetingHost: boolean;
  group: Group | null;
}) {
  const [game, setGame] = useState<CardGame | null>(null);
  const [participants, setParticipants] = useState(Array.from(room?.participants.values() ?? []));
  const [nextMember, setNextMember] = useState<NextMember | null>(null);
  const [cards, setCards] = useState<Record<string, CardWithStatus>>({});
  // eslint-disable-next-line
  const [availableCards, setAvailableCards] = useState<Card[]>([]);
  const [availableMembers, setAvailableMembers] = useState<string[]>([]);

  useEffect(() => {
    if (room && game) {
      const participantConnected = (participant: RemoteParticipant) => {
        setParticipants(prevParticipants => [...prevParticipants, participant]);

        // set a timeout to let the new participant time to "publish/subscribe" its localDataTrack
        // in order to receive the PUBLISH_GAME_CARDS event
        setTimeout(() => {
          setAvailableCards(prevAvailableCards => {
            if (prevAvailableCards.length === 0) {
              return prevAvailableCards;
            }

            const avCards = [...prevAvailableCards];
            const random = Math.floor(Math.random() * avCards.length);
            const card = avCards.splice(random, 1)[0];
            setCards(prevCards => ({
              ...prevCards,
              [participant.sid]: { ...card, status: CardStatus.Close },
            }));

            return avCards;
          });
        }, 2000);
      };

      const participantDisconnected = (participant: RemoteParticipant) => {
        setParticipants(prevParticipants => prevParticipants.filter(p => p !== participant));
        setCards(prevCards => {
          const card = prevCards[participant.sid];
          setAvailableCards(prevAvailableCards => [...prevAvailableCards, card]);
          const pCards = { ...prevCards };
          delete pCards[participant.sid];
          return pCards;
        });
        setNextMember(prevNextMember => (prevNextMember?.sid === participant.sid ? null : prevNextMember));
      };

      room.on('participantConnected', participantConnected);
      room.on('participantDisconnected', participantDisconnected);
      return () => {
        room.off('participantConnected', participantConnected);
        room.off('participantDisconnected', participantDisconnected);
      };
    }
  }, [room, localDataTrack, game]);

  useEffect(() => {
    if (isMeetingHost) {
      localDataTrack.send(
        JSON.stringify({
          cards,
          nextMember: nextMember ? { userId: nextMember.sid, name: nextMember.name } : null,
          event: InMeetingEvent.PublishGameCards,
        })
      );

      setAvailableMembers(
        Object.keys(cards).filter(sid => sid !== nextMember?.sid && cards[sid].status === CardStatus.Close)
      );
    }
  }, [cards, nextMember, localDataTrack, isMeetingHost]);

  const setGameCards = useCallback(
    (g: CardGame, _participants: RemoteParticipant[]) => {
      const avCards = [...g.assets.cards];
      const pCards: Record<string, CardWithStatus> = {};
      _participants.forEach(p => {
        const random = Math.floor(Math.random() * avCards.length);
        const card = avCards.splice(random, 1)[0];
        pCards[p.sid] = { ...card, status: CardStatus.Close };
      });
      setCards(pCards);
      setAvailableCards(avCards);

      const randomNext = Math.floor(Math.random() * _participants.length);
      const nextP = _participants[randomNext];
      const participantName = group?.members[nextP.identity]?.name || 'Anon';
      setNextMember({ sid: nextP.sid, name: participantName, card: pCards[nextP.sid] });
      localDataTrack.send(
        JSON.stringify({
          userId: nextP.sid,
          name: nextP.identity,
          event: InMeetingEvent.NextMemberCard,
        })
      );
    },
    [localDataTrack, group]
  );

  const startGame = useCallback(
    (g: CardGame) => {
      const _participants = Array.from(room?.participants.values() ?? []);
      if (_participants.length === 0) {
        return;
      }
      setGame(g);
      setParticipants(_participants);
      setGameCards(g, _participants);
    },
    [room, setGameCards]
  );

  const restartGame = useCallback(() => {
    if (participants.length === 0) {
      return;
    }
    setGameCards(game!, participants);
  }, [game, participants, setGameCards]);

  const endGame = useCallback(() => {
    localDataTrack.send(
      JSON.stringify({
        event: InMeetingEvent.CardGameEnd,
      })
    );

    setGame(null);
    setCards({});
    setAvailableCards([]);
    setNextMember(null);
  }, [localDataTrack]);

  const onHostDisconnected = useCallback(() => {
    if (game) {
      endGame();
    }
  }, [game, endGame]);

  const onGameEnd = () => {
    setCards({});
    setNextMember(null);
  };

  const flipCard = useCallback(
    (sid: string, status: CardStatus, notifyOthers = false) => {
      if (cards[sid]) {
        const card = { ...cards[sid], status };
        setCards({
          ...cards,
          [sid]: card,
        });

        if (notifyOthers) {
          localDataTrack.send(
            JSON.stringify({
              userId: sid,
              status,
              event: InMeetingEvent.MemberFlipCard,
            })
          );
        }
      }
    },
    [cards, localDataTrack]
  );

  const shuffleNextMember = useCallback(() => {
    if (availableMembers.length > 0) {
      const randomNext = Math.floor(Math.random() * availableMembers.length);
      const nextP = participants.find(p => p.sid === availableMembers[randomNext]);
      if (nextP) {
        const participantName = group?.members[nextP.identity]?.name || 'Anon';
        setNextMember({ sid: nextP.sid, name: participantName, card: cards[nextP.sid] });
        localDataTrack.send(
          JSON.stringify({
            userId: nextP.sid,
            name: nextP.identity,
            event: InMeetingEvent.NextMemberCard,
          })
        );
      }
    }
  }, [availableMembers, cards, participants, localDataTrack, group]);

  const onCardsChange = (c: Record<string, CardWithStatus>, n: { userId: string; name: string } | null) => {
    setCards(c);
    if (n && c[n.userId]) {
      setNextMember({
        sid: n.userId,
        name: n.name,
        card: c[n.userId],
      });
    }
  };

  const onNextMemberChange = (sid: string, name: string) => {
    if (cards[sid]) {
      setNextMember({
        sid,
        name,
        card: cards[sid],
      });
    }
  };

  return {
    canPlay: Array.from(room?.participants.values() ?? []).length > 0,
    canShuffleNextMember: availableMembers.length > 0,
    game,
    startGame,
    endGame,
    onHostDisconnected,
    restartGame,
    nextMember,
    cards,
    flipCard,
    shuffleNextMember,
    onCardsChange,
    onNextMemberChange,
    onGameEnd,
  };
}
