import { ChatMentions } from '../../chat.types';
import { translate } from '../../../../i18n';

interface ISanitizeMessageText {
  message: string;
  deleted: boolean;
  isCurrentUserAnAuthor: boolean;
  mentions: ChatMentions | null;
}

export const sanitizeMessageText = ({ message, deleted, isCurrentUserAnAuthor, mentions }: ISanitizeMessageText) => {
  if (deleted) {
    return translate(
      isCurrentUserAnAuthor ? 'circlesChat.messageDeletedByCurrentUser' : 'circlesChat.messageDeletedByOtherUser'
    );
  }

  if (mentions && mentions.length > 0) {
    let messageWithBoldedMentions = message;

    const replacedMentions: string[] = [];

    mentions.forEach(({ display }) => {
      if (!replacedMentions.includes(display)) {
        replacedMentions.push(display);
        messageWithBoldedMentions = messageWithBoldedMentions.replace(
          `/${display}/gi`,
          `<span style='font-weight: bold'>${display}</span>`
        );
      }
    });

    return messageWithBoldedMentions;
  }

  return message;
};
