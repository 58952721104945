import React from 'react';

export default function RestartGameIcon({ color }: { color: string }) {
  return (
    <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="6.87512" y="5.39148" width="8.84244" height="12.1429" rx="0.5" stroke={color} />
      <rect x="11.2971" y="8.66748" width="9.84244" height="13.1429" rx="1" fill={color} />
      <path d="M16.8981 1.91064C20.5262 1.36867 23.9249 3.83704 24.4893 7.4239" stroke={color} strokeLinecap="round" />
      <path
        d="M21.5427 6.7755L24.5485 7.60215L25.9623 4.86709"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M9.99649 25.4945C6.31884 25.4945 3.33752 22.5585 3.33752 18.9367" stroke={color} strokeLinecap="round" />
      <path
        d="M6.15311 19.9996L3.30995 18.7423L1.48608 21.2311"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
