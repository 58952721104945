import React, { useState } from 'react';
import { Button } from '../../../DesignSystem';
import { makeStyles, Theme } from '@material-ui/core';

import ConnectionOptionsDialog from '../../../ConnectionOptionsDialog/ConnectionOptionsDialog';
import DeviceSelectionDialog from '../../../DeviceSelectionDialog/DeviceSelectionDialog';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

const useStyles = makeStyles((theme: Theme) => ({
  settingsButton: {
    color: theme.colors.VIBRANT_BLUE.NORMAL,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  settingsIcon: {
    color: theme.colors.VIBRANT_BLUE.NORMAL,
  },
}));

export default function SettingsMenu() {
  const classes = useStyles();
  const [deviceSettingsOpen, setDeviceSettingsOpen] = useState(false);
  const [connectionSettingsOpen, setConnectionSettingsOpen] = useState(false);

  return (
    <>
      <Button
        onClick={() => setDeviceSettingsOpen(true)}
        startIcon={<SettingsOutlinedIcon className={classes.settingsIcon} />}
        className={classes.settingsButton}
        tx="settings.advancedSettings"
      />
      <DeviceSelectionDialog
        open={deviceSettingsOpen}
        onClose={() => {
          setDeviceSettingsOpen(false);
        }}
      />
      <ConnectionOptionsDialog
        open={connectionSettingsOpen}
        onClose={() => {
          setConnectionSettingsOpen(false);
        }}
      />
    </>
  );
}
