import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import { Text } from '../../../DesignSystem';

import GameItem, { GameState } from './GameItem';

import { CardGame } from '../../../../state/useApi/api.types';

interface GameListProps {
  cardGames: CardGame[];
  startGame: (g: CardGame) => void;
  canPlay: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    overflowY: 'auto',
    marginTop: '8px',
    marginBottom: '8px',
  },
  gameListText: {
    color: theme.colors.GRAY[500],
    marginBottom: '16px',
  },
  gameItemContainer: {
    marginBottom: '8px',
  },
}));

export default function GameList({ cardGames, startGame, canPlay }: GameListProps) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Text preset="Label2" tx="gfDashboard.cardGames.gameList" className={classes.gameListText} />
      {cardGames.map(game => (
        <div key={game.id} className={classes.gameItemContainer}>
          <GameItem game={game} state={GameState.INACTIVE} onStart={() => startGame(game)} canPlay={canPlay} />
        </div>
      ))}
    </div>
  );
}
