import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { RemoteParticipant, RemoteAudioTrackPublication } from 'twilio-video';
import { makeStyles, Theme, Grid, Tooltip } from '@material-ui/core';
import { Text } from '../../DesignSystem';
import SilentIcon from '../../../icons/SilentIcon';
import MuteIcon from '../../../icons/MuteIcon';
import UnmuteIcon from '../../../icons/UnmuteIcon';

import { track } from '../../../services/analytics';

import { getParticipantBkgColor, getParticipantName } from '../../../utils';

import { COLORS } from '../../../theme';

import { SessionInfoUser } from '../../../state/useApi/api.types';

interface ParticipantProps {
  participant: SessionInfoUser;
  remoteParticipant?: RemoteParticipant;
  maxSeconds: number;
  silnetAlert: boolean;
  left: boolean;
  canMute: boolean;
  toggleAudioStatus: (userId: string, status: string) => void;
}

const PROGRESS_BAR_WIDTH = 152;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: '12px',
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: theme.avatarCircleSize,
    height: theme.avatarCircleSize,
    borderRadius: '50%',
  },
  avatarName: {
    color: theme.colors.WHITE,
  },
  prgressBarContainer: {
    width: PROGRESS_BAR_WIDTH,
  },
  prgressBar: {
    backgroundColor: theme.colors.GREEN[200],
    borderRadius: '100px',
    height: '8px',
  },
  prgressBarLeft: {
    backgroundColor: theme.colors.GRAY[400],
  },
  talkedMinText: {
    fontSize: '10px',
    marginBottom: '4px',
    color: theme.colors.GRAY[500],
  },
  silentAlertContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  alertText: {
    color: theme.colors.RED[400],
    fontSize: '10px',
  },
  audioStatusContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  clickable: {
    cursor: 'pointer',
  },
}));

export default function Participant({
  participant,
  remoteParticipant,
  maxSeconds,
  silnetAlert,
  left,
  canMute,
  toggleAudioStatus,
}: ParticipantProps) {
  const classes = useStyles();

  const [audioStatus, setAudioStatus] = useState<'muted' | 'unmuted' | 'unknown'>('unknown');

  useEffect(() => {
    if (remoteParticipant) {
      const isUnmuted = Array.from(remoteParticipant.audioTracks.values()).some(
        (t: RemoteAudioTrackPublication) => t.isTrackEnabled
      );
      setAudioStatus(isUnmuted ? 'unmuted' : 'muted');
    }
  }, [remoteParticipant]);

  const getProgressBarWidth = (activeSeconds: number) => {
    const factor = activeSeconds / maxSeconds;
    return factor < 1 ? PROGRESS_BAR_WIDTH * factor : PROGRESS_BAR_WIDTH;
  };

  const parseTalkingTime = (activeSeconds: number) => {
    const minutes = Math.floor(activeSeconds / 60);
    const seconds = activeSeconds % 60;
    return seconds < 10 ? `${minutes}:0${seconds}` : `${minutes}:${seconds}`;
  };

  const onToggleAudioStatus = () => {
    if (canMute) {
      const newStatus = audioStatus === 'muted' ? 'unmuted' : 'muted';
      track(`click ${newStatus} participant`);
      toggleAudioStatus(participant.userId, newStatus);
      setAudioStatus(newStatus);
    }
  };

  return (
    <Grid container alignItems="center" spacing={2} className={classes.container}>
      <Grid item>
        <Tooltip title={participant.name} placement="right-start">
          <div
            className={classes.avatarContainer}
            style={{
              backgroundColor: left ? COLORS.GRAY[400] : getParticipantBkgColor(participant.name),
            }}
          >
            <Text preset="Paragraph" text={getParticipantName(participant.name)} className={classes.avatarName} />
          </div>
        </Tooltip>
      </Grid>
      <Grid item>
        <div className={classes.prgressBarContainer}>
          <Text
            preset="Paragraph"
            tx="gfDashboard.participants.talkedMin"
            txOptions={{ minutes: parseTalkingTime(participant.totalActiveSeconds) }}
            className={classes.talkedMinText}
          />
          {maxSeconds > 0 && (
            <div
              className={clsx(classes.prgressBar, { [classes.prgressBarLeft]: left })}
              style={{ width: getProgressBarWidth(participant.totalActiveSeconds) }}
            />
          )}
        </div>
      </Grid>
      <Grid item xs={2}>
        {left && (
          <div className={classes.silentAlertContainer}>
            <Text preset="Paragraph" tx="gfDashboard.participants.leftFlag" className={classes.alertText} />
          </div>
        )}
        {silnetAlert && (
          <div className={classes.silentAlertContainer}>
            <SilentIcon color={COLORS.RED[400]} />
            <Text
              preset="Paragraph"
              tx="gfDashboard.participants.silentAlert"
              txOptions={{ alertMinutes: Math.floor((Date.now() - participant.lastActive) / (60 * 1000)) }}
              className={classes.alertText}
            />
          </div>
        )}
      </Grid>
      <Grid item xs={1}>
        {!left && audioStatus !== 'unknown' && (
          <div
            className={clsx(classes.audioStatusContainer, { [classes.clickable]: canMute })}
            onClick={onToggleAudioStatus}
          >
            {audioStatus === 'muted' ? <MuteIcon color={COLORS.RED[500]} /> : <UnmuteIcon color={COLORS.GRAY[500]} />}
          </div>
        )}
      </Grid>
    </Grid>
  );
}
