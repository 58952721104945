import React, { useEffect, useState } from 'react';
import { Text } from '../../DesignSystem/Text';
import moment from 'moment';
import { Group } from '../../../state/useGroup/useGroup';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { nextSession } from '../../../utils/nextGroupSession';
import CalendarModal from './CalendarModal';
import { CalendarEvent } from '../../../utils/calendar/calendar.types';
interface ThankYouScreenProps {
  group: Group | null;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nextSessionTitle: {
      marginTop: 24,
      color: theme.colors.GRAY[500],
    },
  })
);

const ThankYouScreen = ({ group }: ThankYouScreenProps) => {
  const classes = useStyles();
  const [nextSessionVar, setNextSessionVar] = useState<string>('');
  const [event, setEvent] = useState<CalendarEvent | null>(null);

  useEffect(() => {
    if (group) {
      setNextSessionVar(nextSession(group.schedule)[0]);
    }
  }, [group]);

  useEffect(() => {
    if (nextSessionVar !== '' && group) {
      const endDatetime = moment.utc(nextSessionVar).add(group?.schedule[0].duration, 'm');
      const startDatetime = moment.utc(nextSessionVar);
      setEvent({
        description: `${group?.groupTitle} with ${group?.groupLeaderName}`,
        duration: moment
          .duration(endDatetime.diff(startDatetime))
          .asHours()
          .toString(),
        endDatetime: endDatetime.format('YYYYMMDDTHHmmssZ'),
        startDatetime: startDatetime.format('YYYYMMDDTHHmmssZ'),
        timezone: group?.schedule[0].local?.tz,
        title: `Session with ${group?.groupLeaderName}`,
        recur: `RRULE:FREQ=WEEKLY;BYDAY=${startDatetime.format('dd').toUpperCase()};INTERVAL=1;COUNT=16`,
      });
    }
  }, [nextSessionVar, group]);

  return (
    <div>
      <Text preset="SubHeader" tx="thankYou.title" gutterBottom />
      <Text preset="Paragraph" tx="thankYou.subtitle_1" />
      <Text preset="Paragraph" tx="thankYou.subtitle_2" />

      <Text preset="Label2" tx="thankYou.nextSession" className={classes.nextSessionTitle} />
      <Text preset="Label1" text={moment(nextSessionVar).format('ddd, MMM DD, LT')} />
      {group && event && <CalendarModal event={event} />}
    </div>
  );
};

export default ThankYouScreen;
