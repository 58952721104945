import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';

import { identify } from '../../services/analytics';

export enum ROLE {
  MEMBER = 'member',
  GROUP_LEADER = 'group_leader',
  ADMIN = 'admin',
  PEER_GROUP_LEADER = 'peer_group_leader',
}

export interface Account {
  id: string;
  name: string;
  fullName: string;
  role: ROLE;
  picture?: string;
}

export default function useAccount() {
  const [account, setAccount] = useState<Account | null>(null);
  const [isAuthReady, setIsAuthReady] = useState(false);

  useEffect(() => {
    if (account?.id) {
      setIsAuthReady(true);
      identify(account.id);
      Sentry.setUser({ id: account.id });
    }
  }, [account]);

  return { account, setAccount, isAuthReady };
}
