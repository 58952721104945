import { VideoBandwidthProfileOptions } from 'twilio-video';

export interface CirclesConnectOptions {
  bandwidthApi?: VideoBandwidthProfileOptions;
}

export enum InMeetingStatus {
  Joined = 'joined',
  Left = 'left',
}

export enum InMeetingEvent {
  Speaker = 'meeting.user.video.speaker',
  EndedByHost = 'meetingEndedByHost',
  MemberFlipCard = 'memberFlipCard',
  NextMemberCard = 'nextMemberCard',
  PublishGameCards = 'publishGameCards',
  CardGameEnd = 'cardGameEnd',
  PublishEmitionalEmoji = 'publishEmitionalEmoji',
  ToggleAudioStatus = 'toggleAudioStatus',
  CirclesChatNewMessage = 'newChatMessage',
  CirclesChatDeleteMessage = 'chatMessageDeleted',
}

export interface SendInMeetingStatusParams {
  groupInstanceId: string;
  userId: string;
  name: string;
  status: InMeetingStatus;
  isHost: boolean;
}

export interface SendInMeetingEventParams {
  groupInstanceId: string;
  userId: string;
  name: string;
  event: InMeetingEvent;
  timestamp: number;
}

export type Card = {
  title?: string;
  picture?: string;
};
export interface CardGame {
  id: string;
  displayName: string;
  assets: {
    cards: Card[];
  };
}

/** Session Info */
interface ActiveUnit {
  from: number; // millis
  duration: number; // millis
  userId: string;
}

interface MeetingUser {
  name: string;
  userId: string;
}

export interface SessionInfoUser extends MeetingUser {
  totalActiveSeconds: number;
  activePercentage: number;
  lastActive: number; // millis
  joined: number | null;
  left: number | null;
  firstActive: number;
  activeUnits?: ActiveUnit[];
  accountId: string | null;
}

interface CurrentActiveUser extends MeetingUser {
  from: number; // millis
}

export interface SessionInfo {
  users: SessionInfoUser[];
  activeUnits: ActiveUnit[];
  currentActiveUser?: CurrentActiveUser;
  duration: number; // seconds
  ts: number;
}
