import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { COLORS } from '../../../theme';

import { Button } from '../../DesignSystem';
import ScreenShareIcon from '../../../icons/ScreenShareIcon';

import useScreenShareParticipant from '../../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

export const SCREEN_SHARE_TEXT = 'Share Screen';
export const SHARE_IN_PROGRESS_TEXT = 'Cannot share screen when another user is sharing';
export const SHARE_NOT_SUPPORTED_TEXT = 'Screen sharing is not supported with this browser';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    disabled: {
      backgroundColor: `${theme.colors.GRAY[500]} !important`,
      cursor: 'not-allowed !important',
    },
  })
);

interface ToggleScreenShareButtonProps {
  className: string;
  disabled: boolean;
}

export default function ToggleScreenShareButton({ className, disabled }: ToggleScreenShareButtonProps) {
  const classes = useStyles();
  const screenShareParticipant = useScreenShareParticipant();
  const { toggleScreenShare } = useVideoContext();
  const disableScreenShareButton = Boolean(screenShareParticipant);
  const isScreenShareSupported = navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;
  const isDisabled = disabled || disableScreenShareButton || !isScreenShareSupported;

  return (
    <Button
      className={clsx(className, { [classes.disabled]: isDisabled })}
      onClick={toggleScreenShare}
      disabled={isDisabled}
      data-cy-share-screen
    >
      <ScreenShareIcon color={COLORS.GRAY[isDisabled ? 800 : 500]} />
    </Button>
  );
}
