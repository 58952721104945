import React from 'react';

export default function SkipParticipantIcon({ color }: { color: string }) {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.4916 17.0864C16.6663 16.4806 17.4564 15.3009 17.4376 13.9498C17.4114 12.1166 15.8425 10.5765 13.8983 10.4811C11.7402 10.3752 9.95555 11.9894 9.95555 13.9975C9.95555 15.3309 10.7419 16.4895 11.9035 17.0882C9.24994 17.7575 7.22882 19.8751 6.85725 22.4854C6.78219 23.0135 7.22132 23.4851 7.78806 23.4851C10.7944 23.4868 19.6051 23.4886 19.6051 23.4886C20.1719 23.4886 20.6129 23.0188 20.5378 22.4907C20.1662 19.8787 18.1432 17.7575 15.4916 17.0864Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5244 15.0001C5.77075 10.3995 8.88927 6.05909 13.4898 5.30543C15.7526 4.93476 17.9524 5.50078 19.6934 6.71913"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M16.9543 8.37306L20.6545 7.43484L20.4603 3.62251"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
