export interface ApiConfig {
  url: string;
  timeout: number;
  unit: string;
}

export const DEFAULT_API_CONFIG: ApiConfig = {
  url: process.env.REACT_APP_MAIN_REST_BASE_URL || 'https://localhost:3443/api',
  timeout: 60000,
  unit: 'main',
};

export const CHAT_API_CONFIG: ApiConfig = {
  url: process.env.REACT_APP_CHAT_BASE_URL || 'https://localhost:8998/api/chat',
  timeout: 60000,
  unit: 'chat',
};

export const ANALYZER_API_CONFIG: ApiConfig = {
  url: process.env.REACT_APP_ANALYZER_REST_BASE_URL || 'https://api.circlesup.com/meeting-analyzer',
  timeout: 60000,
  unit: 'analyzer',
};
