import { useState, useEffect } from 'react';
import { Api } from '../../services/api';
import { ANALYZER_API_CONFIG } from '../../services/api/api-config';

import * as Types from './api.types';

// import { sessionInfo } from '../../__mocks__/@api/sessionInfo';

export interface UseAnalyzerApiProps {
  isReady: boolean;
  setAuthToken: (token: string) => void;
  getSessionInfo: (groupId: string) => Promise<{ kind: string; data: Types.SessionInfo | null }>;
}

export default function useAnalyzerApi(): UseAnalyzerApiProps {
  const [api, setApi] = useState<Api | null>(null);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    setApi(new Api(ANALYZER_API_CONFIG));
  }, []);

  const setAuthToken = (token: string) => {
    if (api) {
      api.setAuthorizationHeader(token);
      setIsReady(true);
    }
  };

  const getSessionInfo = async (groupId: string) => {
    // for local we return a mock answer
    // if (process.env.REACT_APP_ENV === 'local') {
    //   return Promise.resolve({ kind: 'ok', data: sessionInfo });
    // }
    const { kind, data } = await api!.get(`/session/info/${groupId}?provider=twilio`);
    return { kind, data: data?.info ? { ...data.info, ts: Date.now() } : null };
  };

  return {
    isReady,
    setAuthToken,
    getSessionInfo,
  };
}
