import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Text } from '../DesignSystem';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Box, Grid, Hidden } from '@material-ui/core';
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton';
import SettingsButton from '../Buttons/SettingsButton/SettingsButton';
import CirclesLogoSmall from '../../assets/svgComponents/CirclesLogoSmall';
import { COLORS } from '../../theme';
import { CirclesChatButton } from '../Buttons/CirclesChatButton/CirclesChatButton';
import ToggleScreenShareButton from '../Buttons/ToogleScreenShareButton/ToggleScreenShareButton';
import clsx from 'clsx';
import ScreenShareIcon from '../../icons/ScreenShareIcon';
import CloseIcon from '@material-ui/icons/Close';
import { useAppState } from '../../state';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionButtons: {
      backgroundColor: theme.colors.GRAY[800],
      margin: '4px',
      width: '44px',
      minWidth: 'unset',
      height: '44px',
      minHeight: 'unset',
      '&:hover': {
        backgroundColor: theme.colors.GRAY[800],
      },
    },
    circlesLogoContainer: {
      marginLeft: '16px',
    },
    container: {
      backgroundColor: theme.colors.MID_BLACK,
      bottom: 0,
      left: 0,
      right: 0,
      height: `${theme.footerHeight}px`,
      position: 'fixed',
      display: 'flex',
      padding: '0 1.43em',
      zIndex: 10,
      [theme.breakpoints.down('sm')]: {
        height: `${theme.mobileFooterHeight}px`,
        padding: 0,
      },
    },
    screenShareBanner: {
      position: 'fixed',
      zIndex: 10,
      bottom: `${theme.footerHeight}px`,
      left: 0,
      width: `calc(100vw - ${theme.asidePanelWidth}px)`,
      height: '104px',
      background: 'rgba(0, 0, 0, 0.5)',
    },
    screenShareBannerCell: {
      alignItems: 'center',
      display: 'flex',
      padding: '2px 8px',
    },
    screenShareBannerCellLeft: {
      backgroundColor: theme.colors.GREEN[400],
      borderRadius: '8px 0 0 8px',
    },
    screenShareBannerCellRight: {
      backgroundColor: theme.colors.RED[400],
      borderRadius: '0 8px 8px 0',
      cursor: 'pointer',
    },
    screenShareBannerText: {
      color: theme.colors.WHITE,
      marginLeft: '6px',
    },
  })
);

export default function MenuBar() {
  const classes = useStyles();

  const { isMeetingHost } = useAppState();
  const { isSharingScreen, toggleScreenShare } = useVideoContext();
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';

  return (
    <>
      {isSharingScreen && (
        <Grid container justify="center" alignItems="center" className={classes.screenShareBanner}>
          <Box className={clsx(classes.screenShareBannerCell, classes.screenShareBannerCellLeft)}>
            <ScreenShareIcon color={COLORS.WHITE} styleProps={{ marginBottom: '2px' }} />
            <Text preset="Label1" tx="menuBar.currentlySharingScreen" className={classes.screenShareBannerText} />
          </Box>
          <Box
            onClick={toggleScreenShare}
            className={clsx(classes.screenShareBannerCell, classes.screenShareBannerCellRight)}
          >
            <CloseIcon style={{ color: COLORS.WHITE }} />
            <Text preset="Label1" tx="menuBar.stopSharing" className={classes.screenShareBannerText} />
          </Box>
        </Grid>
      )}
      <footer className={classes.container}>
        <Grid container justify="space-around" alignItems="center">
          <Grid item className={classes.circlesLogoContainer}>
            <CirclesLogoSmall bkgCircleColor={COLORS.WHITE} textColor={COLORS.BRAND_PURPLE.NORMAL} />
          </Grid>
          <Grid item style={{ flex: 1 }}>
            <Grid container justify="center">
              <ToggleAudioButton
                iconColor={COLORS.WHITE}
                iconDisableLineColor={COLORS.RED[500]}
                className={classes.actionButtons}
                disabled={isReconnecting}
              />
              <EndCallButton className={classes.actionButtons} />
              <ToggleVideoButton
                iconColor={COLORS.WHITE}
                iconDisableLineColor={COLORS.RED[500]}
                className={classes.actionButtons}
                disabled={isReconnecting}
              />
              {isMeetingHost ? (
                <ToggleScreenShareButton
                  className={classes.actionButtons}
                  disabled={isReconnecting || isSharingScreen}
                />
              ) : null}
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid item>
              <Grid container justify="flex-end" spacing={4}>
                <Grid item>
                  <CirclesChatButton />
                </Grid>
                <Grid item>
                  <SettingsButton />
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </footer>
    </>
  );
}
