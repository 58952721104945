import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ReplyIcon from '@material-ui/icons/Reply';
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Grid,
  ClickAwayListener,
  Tooltip,
  Box,
} from '@material-ui/core';
import moment from 'moment';
import Fade from '@material-ui/core/Fade';
import { COLORS } from '../../../theme';
import { sanitizeMessageContent } from './utils/sanitizeMessageContent';
import { Text } from '../../DesignSystem';
import { ChatMessage, ChatMessageEnriched } from '../chat.types';
import { useAppState } from '../../../state';
import useCirclesChatContext from '../../../hooks/useCirclesChatContext/useCirclesChatContext';
import { InMeetingEvent } from '../../../state/useApi/api.types';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { translate } from '../../../i18n';
import { track } from '../../../services/analytics';

const useStyles = makeStyles(theme => ({
  avatar: {
    width: theme.typography.pxToRem(37),
    height: theme.typography.pxToRem(37),
  },
  avatarWrapper: {
    margin: `${theme.typography.pxToRem(7)} ${theme.typography.pxToRem(7)} auto ${theme.typography.pxToRem(7)}`,
    minWidth: 'auto',
  },
  colorBrandPurpleNormal: {
    color: theme.colors.BRAND_PURPLE.NORMAL,
  },
  colorGray500: {
    color: theme.colors.GRAY[500],
  },
  deletedMessage: {
    color: COLORS.GRAY[500],
    fontStyle: 'italic',
  },
  itemRTL: {
    direction: 'rtl',
  },
  link: {
    color: COLORS.VIBRANT_BLUE.NORMAL,
  },
  menuItemBase: {
    alignItems: 'center',
    backgroundColor: COLORS.GRAY[50],
    display: 'flex',
    height: theme.typography.pxToRem(50),
    justifyContent: 'space-between',
    padding: '12px 16px',

    '&:hover': {
      backgroundColor: COLORS.GRAY[300],
    },
  },
  menuCopyItem: {
    borderRadius: '4px 4px 0 0',
    borderBottom: `1px solid ${COLORS.GRAY[300]}`,
  },
  menuDeleteItem: {
    borderRadius: '0 0 4px 4px',
    borderTop: `1px solid ${COLORS.GRAY[300]}`,
    color: COLORS.RED[400],
  },
  messageBody: {
    fontSize: theme.typography.pxToRem(16),
    marginRight: theme.typography.pxToRem(48),
    whiteSpace: 'pre-line',
  },
  messageFooter: {
    color: COLORS.GRAY[400],
    fontSize: theme.typography.pxToRem(10),
    marginTop: theme.typography.pxToRem(2),
    textAlign: 'right',
  },
  messageHeader: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  },
  messageHeaderMenuIconContainer: {
    color: COLORS.GRAY[400],
    cursor: 'pointer',
    height: theme.typography.pxToRem(24),
    width: theme.typography.pxToRem(24),
  },
  messageWrapper: {
    color: COLORS.BLACK,
    direction: 'ltr',
    fontSize: theme.typography.pxToRem(16),
    margin: 0,
    overflowWrap: 'break-word',
    padding: theme.typography.pxToRem(8),
    textAlign: 'left',
    userSelect: 'text',
    wordBreak: 'break-word',
  },
  messageWrapperOthersVariant: {
    backgroundColor: COLORS.GRAY[50],
    borderRadius: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(8)} 0`,
    marginRight: theme.typography.pxToRem(48),
  },
  messageWrapperUserVariant: {
    backgroundColor: COLORS.GRAY[200],
    borderRadius: `${theme.typography.pxToRem(8)} ${theme.typography.pxToRem(8)} 0 ${theme.typography.pxToRem(8)}`,
    marginLeft: theme.typography.pxToRem(48),
  },
  replyMessage: {
    backgroundColor: COLORS.WHITE,
    borderRadius: theme.typography.pxToRem(4),
    flexBasis: '90%',
    padding: theme.typography.pxToRem(5),
    wordWrap: 'break-word',
  },
  replyMessageSpacer: {
    flexBasis: '10%',
  },
  replyMessageWrapper: {
    display: 'flex',
    flexDirection: 'row-reverse',
    margin: `${theme.typography.pxToRem(4)} 0`,
  },
}));

const MenuTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: 'transparent',
    boxShadow: '2px 2px 10px 0px rgba(0,0,0,0.6)',
    color: COLORS.BLACK,
    cursor: 'pointer',
    fontSize: theme.typography.pxToRem(16),
    padding: 0,
    width: theme.typography.pxToRem(220),
  },
}))(Tooltip);

export const Message = ({
  deleted,
  mentions,
  id,
  imageUrl,
  message,
  name,
  picture,
  replyTo,
  senderId,
  timestamp,
}: ChatMessageEnriched) => {
  const classes = useStyles();

  const { account, chatApi } = useAppState();
  const currentUserId = account ? account.id : null;

  const { groupInstanceId, members, messages, setMessages, setReplyMessageId } = useCirclesChatContext();

  const { localDataTrack } = useVideoContext();

  const [showMenuIcon, setShowMenuIcon] = useState<boolean>(false);
  const [showMenuList, setShowMenuList] = useState<boolean>(false);

  const time = moment(timestamp).fromNow();
  const isUser = senderId === currentUserId;

  const emitDeleteMessage = async (messageId: string) => {
    if (chatApi?.isReady) {
      await chatApi.deleteChatMessage(groupInstanceId, messageId).then(
        ({ kind }) => {
          if (kind === 'ok') {
            localDataTrack.send(
              JSON.stringify({
                data: { messageId },
                event: InMeetingEvent.CirclesChatDeleteMessage,
              })
            );
            setMessages((prevState: ChatMessage[]) =>
              prevState.map((chatMessage: ChatMessage) => {
                if (chatMessage.id === messageId) {
                  return { ...chatMessage, deleted: true };
                } else {
                  return chatMessage;
                }
              })
            );
          }
        },
        () => console.log('emitDeleteMessage - chatApi.deleteChatMessage - error')
      );
    }
  };

  const renderReplyMessage = () => {
    const messageRepliesTo = messages.find((m: ChatMessage) => m.id === replyTo);

    if (messageRepliesTo) {
      let authorName = null;

      if (messageRepliesTo.senderId === currentUserId) {
        authorName = translate('circlesChat.you');
      } else {
        const sender = members[messageRepliesTo.senderId];
        if (sender) {
          authorName = sender.name;
        }
      }

      return (
        <Box>
          <Box className={classes.replyMessageWrapper}>
            <Box className={classes.replyMessageSpacer} />
            <Box className={classes.replyMessage}>
              {authorName ? <Text className={classes.colorGray500} preset="Label1" text={authorName} /> : null}
              {messageRepliesTo.message ? (
                <Text
                  className={classes.colorGray500}
                  dangerouslySetInnerHTML={{
                    __html: sanitizeMessageContent({
                      message: messageRepliesTo.message,
                      deleted: messageRepliesTo.deleted,
                      imageUrl: messageRepliesTo.imageUrl,
                      isCurrentUserAnAuthor: isUser,
                      linkStyle: classes.link,
                      mentions: messageRepliesTo.mentions,
                    }),
                  }}
                  preset="Label2"
                />
              ) : null}
            </Box>
          </Box>
          <Text
            className={clsx(classes.messageBody, { [classes.deletedMessage]: deleted })}
            dangerouslySetInnerHTML={{
              __html: sanitizeMessageContent({
                message,
                deleted,
                imageUrl,
                isCurrentUserAnAuthor: isUser,
                linkStyle: classes.link,
                mentions,
              }),
            }}
            preset="Paragraph"
          />
        </Box>
      );
    }

    return (
      <Text
        className={clsx(classes.messageBody, { [classes.deletedMessage]: deleted })}
        dangerouslySetInnerHTML={{
          __html: sanitizeMessageContent({
            message,
            deleted,
            imageUrl,
            isCurrentUserAnAuthor: isUser,
            linkStyle: classes.link,
            mentions,
          }),
        }}
        preset="Paragraph"
      />
    );
  };

  return (
    <ListItem className={clsx({ [classes.itemRTL]: isUser })}>
      <ListItemAvatar className={classes.avatarWrapper}>
        <Avatar alt={name} src={picture} className={classes.avatar} />
      </ListItemAvatar>
      <Grid item onMouseEnter={() => setShowMenuIcon(true)} onMouseLeave={() => setShowMenuIcon(showMenuList)}>
        <ListItemText
          disableTypography
          className={clsx(
            classes.messageWrapper,
            isUser ? classes.messageWrapperUserVariant : classes.messageWrapperOthersVariant
          )}
          primary={
            <>
              <Box className={classes.messageHeader}>
                <Text
                  className={isUser ? classes.colorBrandPurpleNormal : classes.colorGray500}
                  preset="Label1"
                  text={isUser ? translate('circlesChat.you') : name}
                />
                <Box className={classes.messageHeaderMenuIconContainer}>
                  {showMenuIcon && !deleted && (
                    <ClickAwayListener
                      onClickAway={() => {
                        setShowMenuList(false);
                        setShowMenuIcon(false);
                      }}
                    >
                      <MenuTooltip
                        onClose={() => setShowMenuList(false)}
                        open={showMenuList}
                        disableFocusListener
                        disableHoverListener
                        interactive
                        placement="left-start"
                        title={
                          <>
                            <Box
                              className={clsx(classes.menuItemBase, classes.menuCopyItem)}
                              onClick={() => {
                                track('click copy chat message', { myMessage: isUser });
                                navigator.clipboard.writeText(imageUrl || message);
                              }}
                            >
                              <Text preset="Label1" text={'Copy'} />
                              <FileCopyIcon />
                            </Box>
                            <Box
                              className={classes.menuItemBase}
                              onClick={() => {
                                track('click reply chat message', { myMessage: isUser });
                                setReplyMessageId(id);
                              }}
                            >
                              <Text preset="Label1" text={'Reply'} />
                              <ReplyIcon />
                            </Box>
                            {isUser ? (
                              <Box
                                className={clsx(classes.menuItemBase, classes.menuDeleteItem)}
                                onClick={() => {
                                  track('click delete chat message');
                                  emitDeleteMessage(id);
                                }}
                              >
                                <Text preset="Label1" text={'Delete'} />
                                <DeleteOutlinedIcon />
                              </Box>
                            ) : null}
                          </>
                        }
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                      >
                        <MoreHorizIcon onClick={() => setShowMenuList(prevState => !prevState)} />
                      </MenuTooltip>
                    </ClickAwayListener>
                  )}
                </Box>
              </Box>
              {replyTo ? (
                <>{renderReplyMessage()}</>
              ) : (
                <Text
                  className={clsx(classes.messageBody, { [classes.deletedMessage]: deleted })}
                  dangerouslySetInnerHTML={{
                    __html: sanitizeMessageContent({
                      message,
                      deleted,
                      imageUrl,
                      isCurrentUserAnAuthor: isUser,
                      linkStyle: classes.link,
                      mentions,
                    }),
                  }}
                  preset="Paragraph"
                />
              )}
              <Text className={classes.messageFooter} preset="Label2" text={time} />
            </>
          }
        />
      </Grid>
    </ListItem>
  );
};
