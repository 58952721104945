import i18n from 'i18n-js';

const en = require('./en');

i18n.fallbacks = true;
i18n.translations = { en };

const fallback = { languageTag: 'en', isRTL: false };

const { languageTag } = fallback;
i18n.locale = languageTag;
