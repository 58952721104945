import React from 'react';

export default function MicOffIcon({ color }: { color: string }) {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.502 14.189C14.1062 14.189 15.4064 12.9042 15.4064 11.3188V7.49181C15.4064 5.90635 14.1062 4.62158 12.502 4.62158C10.8977 4.62158 9.59756 5.90635 9.59756 7.49181V11.3188C9.59756 12.9042 10.8977 14.189 12.502 14.189ZM18.1399 11.2846C18.1399 11.2094 18.0784 11.1479 18.0032 11.1479H16.9781C16.903 11.1479 16.8415 11.2094 16.8415 11.2846C16.8415 13.6816 14.8989 15.6241 12.502 15.6241C10.105 15.6241 8.16245 13.6816 8.16245 11.2846C8.16245 11.2094 8.10095 11.1479 8.02577 11.1479H7.00069C6.92552 11.1479 6.86401 11.2094 6.86401 11.2846C6.86401 14.1668 9.02694 16.545 11.8186 16.8816V18.631H9.33617C9.10211 18.631 8.91418 18.8753 8.91418 19.1777V19.7928C8.91418 19.868 8.96201 19.9295 9.0201 19.9295H15.9838C16.0419 19.9295 16.0897 19.868 16.0897 19.7928V19.1777C16.0897 18.8753 15.9018 18.631 15.6678 18.631H13.117V16.8901C15.9411 16.5826 18.1399 14.1907 18.1399 11.2846Z"
        fill={color}
      />
    </svg>
  );
}
