import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, Theme } from '@material-ui/core';

import { Text } from '../DesignSystem';

import { getParticipantBkgColor, getParticipantName } from '../../utils';

import HuggingEmoji from '../../assets/images/emotionalEmojis/hugging.png';
import RedHeartEmoji from '../../assets/images/emotionalEmojis/red-heart.png';
import AngryEmoji from '../../assets/images/emotionalEmojis/angry.png';
import AstonishedEmoji from '../../assets/images/emotionalEmojis/astonished.png';
import CryingEmoji from '../../assets/images/emotionalEmojis/crying.png';
import SmilingEyesEmoji from '../../assets/images/emotionalEmojis/smiling-eyes.png';
import TearsOfJoyEmoji from '../../assets/images/emotionalEmojis/tears-of-joy.png';

import { EmotionalEmojis } from '../VideoProvider/useEmotionalEmojis/useEmotionalEmojis';

interface RemoteReceivedEmojiProps {
  emoji: EmotionalEmojis;
  senderName: string;
  onAnimationEnd: () => void;
}

const ANIMATION_DURATION = 1000;

const useStyles = makeStyles<Theme>((theme: Theme) => ({
  container: {
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
    width: theme.avatarCircleSize,
    height: theme.avatarCircleSize,
  },
  avatarAnimation: {
    animation: `$avatarAnimation ${ANIMATION_DURATION}ms ease infinite`,
  },
  '@keyframes avatarAnimation': {
    '0%': {
      opacity: 0,
      transform: 'scale(0.3)',
    },
    '50%': {
      opacity: 1,
      transform: 'scale(1.05)',
    },
    '70%': { transform: 'scale(0.9)' },
    '100%': { transform: 'scale(1)' },
  },
  avatarName: {
    color: theme.colors.WHITE,
  },
  emojiAnimation: {
    animation: `$emojiAnimation ${ANIMATION_DURATION}ms ease infinite`,
  },
  '@keyframes emojiAnimation': {
    '0%': {
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      transform: 'scale(1)',
    },
    '25%': { transform: 'scale(0.95)' },
    '50%': {
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      transform: 'scale(1.1)',
      opacity: 1,
    },
    '100%': {
      backgroundColor: 'rgba(255, 255, 255, 0.2)',
      transform: 'scale(0.3)',
      opacity: 0,
    },
  },
  emoji: {
    width: '80%',
    height: '80%',
  },
}));

export default function RemoteReceivedEmoji({ emoji, senderName, onAnimationEnd }: RemoteReceivedEmojiProps) {
  const classes = useStyles();
  const [displayEmoji, setDisplayEmoji] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDisplayEmoji(true);
    }, ANIMATION_DURATION);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    if (displayEmoji) {
      const timeout = setTimeout(() => {
        onAnimationEnd();
      }, ANIMATION_DURATION);
      return () => clearTimeout(timeout);
    }
  }, [displayEmoji, onAnimationEnd]);

  const emojisMap = {
    [EmotionalEmojis.ANGRY]: AngryEmoji,
    [EmotionalEmojis.ASTONISHED]: AstonishedEmoji,
    [EmotionalEmojis.CRYING]: CryingEmoji,
    [EmotionalEmojis.HUGGING]: HuggingEmoji,
    [EmotionalEmojis.RED_HEART]: RedHeartEmoji,
    [EmotionalEmojis.SMILIING_EYES]: SmilingEyesEmoji,
    [EmotionalEmojis.TEARS_OF_JOY]: TearsOfJoyEmoji,
  };

  if (!displayEmoji) {
    return (
      <div
        className={clsx(classes.container, classes.avatarAnimation)}
        style={{ backgroundColor: getParticipantBkgColor(senderName) }}
      >
        <Text preset="Paragraph" text={getParticipantName(senderName)} className={classes.avatarName} />
      </div>
    );
  }

  return (
    <div className={clsx(classes.container, classes.emojiAnimation)}>
      <img src={emojisMap[emoji]} alt="" className={classes.emoji} />
    </div>
  );
}
