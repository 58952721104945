import React from 'react';

export default function AddEmojiIcon() {
  return (
    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_dd)">
        <circle cx="13.419" cy="14.9911" r="9.63241" fill="#FBFAFA" />
        <path
          d="M8.04382 12.5487C8.04382 11.5467 8.85615 10.7344 9.8582 10.7344C10.8602 10.7344 11.6726 11.5467 11.6726 12.5487V14.6606H8.04382V12.5487Z"
          fill="#CDCDCD"
        />
        <path
          d="M15.1655 12.5487C15.1655 11.5467 15.9778 10.7344 16.9799 10.7344C17.982 10.7344 18.7943 11.5467 18.7943 12.5487V14.6606H15.1655V12.5487Z"
          fill="#CDCDCD"
        />
        <path
          d="M17.7321 17.3545C17.7321 19.7364 15.8012 21.6674 13.4192 21.6674C11.0373 21.6674 9.10632 19.7364 9.10632 17.3545L13.4192 17.3548L17.7321 17.3545Z"
          fill="#CDCDCD"
        />
        <mask
          id="path-5-outside-1"
          maskUnits="userSpaceOnUse"
          x="16.2789"
          y="3.62329"
          width="9"
          height="9"
          fill="black"
        >
          <rect fill="white" x="16.2789" y="3.62329" width="9" height="9" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.7302 4.62329C20.3129 4.62329 19.9746 4.96159 19.9746 5.37889V7.31909H18.0345C17.6172 7.31909 17.2789 7.65738 17.2789 8.07468C17.2789 8.49199 17.6172 8.83028 18.0345 8.83028H19.9746V10.7703C19.9746 11.1876 20.3129 11.5259 20.7302 11.5259C21.1475 11.5259 21.4858 11.1876 21.4858 10.7703V8.83028H23.4259C23.8432 8.83028 24.1815 8.49199 24.1815 8.07468C24.1815 7.65738 23.8432 7.31909 23.4259 7.31909H21.4858V5.37889C21.4858 4.96158 21.1475 4.62329 20.7302 4.62329Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.7302 4.62329C20.3129 4.62329 19.9746 4.96159 19.9746 5.37889V7.31909H18.0345C17.6172 7.31909 17.2789 7.65738 17.2789 8.07468C17.2789 8.49199 17.6172 8.83028 18.0345 8.83028H19.9746V10.7703C19.9746 11.1876 20.3129 11.5259 20.7302 11.5259C21.1475 11.5259 21.4858 11.1876 21.4858 10.7703V8.83028H23.4259C23.8432 8.83028 24.1815 8.49199 24.1815 8.07468C24.1815 7.65738 23.8432 7.31909 23.4259 7.31909H21.4858V5.37889C21.4858 4.96158 21.1475 4.62329 20.7302 4.62329Z"
          fill="#E4E4E4"
        />
        <path
          d="M19.9746 7.31909V8.31909H20.9746V7.31909H19.9746ZM19.9746 8.83028H20.9746V7.83028H19.9746V8.83028ZM21.4858 8.83028V7.83028H20.4858V8.83028H21.4858ZM21.4858 7.31909H20.4858V8.31909H21.4858V7.31909ZM20.9746 5.37889C20.9746 5.51387 20.8652 5.62329 20.7302 5.62329V3.62329C19.7606 3.62329 18.9746 4.4093 18.9746 5.37889H20.9746ZM20.9746 7.31909V5.37889H18.9746V7.31909H20.9746ZM18.0345 8.31909H19.9746V6.31909H18.0345V8.31909ZM18.2789 8.07468C18.2789 8.20966 18.1695 8.31909 18.0345 8.31909V6.31909C17.0649 6.31909 16.2789 7.10509 16.2789 8.07468H18.2789ZM18.0345 7.83028C18.1695 7.83028 18.2789 7.93971 18.2789 8.07468H16.2789C16.2789 9.04428 17.0649 9.83028 18.0345 9.83028V7.83028ZM19.9746 7.83028H18.0345V9.83028H19.9746V7.83028ZM20.9746 10.7703V8.83028H18.9746V10.7703H20.9746ZM20.7302 10.5259C20.8652 10.5259 20.9746 10.6353 20.9746 10.7703H18.9746C18.9746 11.7399 19.7606 12.5259 20.7302 12.5259V10.5259ZM20.4858 10.7703C20.4858 10.6353 20.5952 10.5259 20.7302 10.5259V12.5259C21.6998 12.5259 22.4858 11.7399 22.4858 10.7703H20.4858ZM20.4858 8.83028V10.7703H22.4858V8.83028H20.4858ZM23.4259 7.83028H21.4858V9.83028H23.4259V7.83028ZM23.1815 8.07468C23.1815 7.93971 23.2909 7.83028 23.4259 7.83028V9.83028C24.3955 9.83028 25.1815 9.04428 25.1815 8.07468H23.1815ZM23.4259 8.31909C23.2909 8.31909 23.1815 8.20966 23.1815 8.07468H25.1815C25.1815 7.10509 24.3955 6.31909 23.4259 6.31909V8.31909ZM21.4858 8.31909H23.4259V6.31909H21.4858V8.31909ZM20.4858 5.37889V7.31909H22.4858V5.37889H20.4858ZM20.7302 5.62329C20.5952 5.62329 20.4858 5.51387 20.4858 5.37889H22.4858C22.4858 4.4093 21.6998 3.62329 20.7302 3.62329V5.62329Z"
          fill="white"
          mask="url(#path-5-outside-1)"
        />
        <path
          d="M19.9746 7.31909V8.31909H20.9746V7.31909H19.9746ZM19.9746 8.83028H20.9746V7.83028H19.9746V8.83028ZM21.4858 8.83028V7.83028H20.4858V8.83028H21.4858ZM21.4858 7.31909H20.4858V8.31909H21.4858V7.31909ZM20.9746 5.37889C20.9746 5.51387 20.8652 5.62329 20.7302 5.62329V3.62329C19.7606 3.62329 18.9746 4.4093 18.9746 5.37889H20.9746ZM20.9746 7.31909V5.37889H18.9746V7.31909H20.9746ZM18.0345 8.31909H19.9746V6.31909H18.0345V8.31909ZM18.2789 8.07468C18.2789 8.20966 18.1695 8.31909 18.0345 8.31909V6.31909C17.0649 6.31909 16.2789 7.10509 16.2789 8.07468H18.2789ZM18.0345 7.83028C18.1695 7.83028 18.2789 7.93971 18.2789 8.07468H16.2789C16.2789 9.04428 17.0649 9.83028 18.0345 9.83028V7.83028ZM19.9746 7.83028H18.0345V9.83028H19.9746V7.83028ZM20.9746 10.7703V8.83028H18.9746V10.7703H20.9746ZM20.7302 10.5259C20.8652 10.5259 20.9746 10.6353 20.9746 10.7703H18.9746C18.9746 11.7399 19.7606 12.5259 20.7302 12.5259V10.5259ZM20.4858 10.7703C20.4858 10.6353 20.5952 10.5259 20.7302 10.5259V12.5259C21.6998 12.5259 22.4858 11.7399 22.4858 10.7703H20.4858ZM20.4858 8.83028V10.7703H22.4858V8.83028H20.4858ZM23.4259 7.83028H21.4858V9.83028H23.4259V7.83028ZM23.1815 8.07468C23.1815 7.93971 23.2909 7.83028 23.4259 7.83028V9.83028C24.3955 9.83028 25.1815 9.04428 25.1815 8.07468H23.1815ZM23.4259 8.31909C23.2909 8.31909 23.1815 8.20966 23.1815 8.07468H25.1815C25.1815 7.10509 24.3955 6.31909 23.4259 6.31909V8.31909ZM21.4858 8.31909H23.4259V6.31909H21.4858V8.31909ZM20.4858 5.37889V7.31909H22.4858V5.37889H20.4858ZM20.7302 5.62329C20.5952 5.62329 20.4858 5.51387 20.4858 5.37889H22.4858C22.4858 4.4093 21.6998 3.62329 20.7302 3.62329V5.62329Z"
          fill="url(#paint0_linear)"
          mask="url(#path-5-outside-1)"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd"
          x="0.786621"
          y="0.623291"
          width="29.3949"
          height="29.0002"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dx="-1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dx="1" dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="effect1_dropShadow" result="effect2_dropShadow" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear"
          x1="20.7302"
          y1="4.62329"
          x2="20.7302"
          y2="11.5259"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
