import React, { useState, useEffect, useCallback } from 'react';
import Video from 'twilio-video';
import { useParams, useLocation } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { isAndroid, isIOS } from 'react-device-detect';

import { useAppState } from '../../state';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { track } from '../../services/analytics';

import JoinMeetingScreen from './JoinMeetingScreen/JoinMeetingScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import PreflightTest from './PreflightTest/PreflightTest';
import ThankYouScreen from './ThankYouScreen/ThankYouScreen';
import AuthFailScreen from './AuthFailScreen/AuthFailScreen';
import BlockMobileScreen from './BlockMobileScreen/BlockMobileScreen';
import { Survey, SurveyAnswer } from '../../components/SessionSurvay/survey.types';
import SurveyPopUp from '../../components/SessionSurvay/SurveyPopUp';
import SurveySubmittedPopUp from '../../components/SessionSurvay/SurveySubmittedPopUp';
import moment from 'moment';

import { APP_STORE_URL, GOOGLE_PLAY_URL } from '../../constants';

export enum Steps {
  LoadingStep,
  JoinMeetingStep,
  ThankYouStep,
  AuthFailStep,
  MobileScreenStep,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingStepContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    loadingSpinner: {
      color: theme.colors.VIVID_PURPLE,
      width: '56px',
      height: '56px',
    },
  })
);

export default function PreJoinScreens() {
  const classes = useStyles();
  const location = useLocation();
  const { account, setAccount, group, setGroup, mainApi, endedByHost, chatApi } = useAppState();
  const isMobile = isAndroid || isIOS ? true : false;
  const storeUrl = isAndroid ? GOOGLE_PLAY_URL : isIOS ? APP_STORE_URL : undefined;

  const { getAudioAndVideoTracks } = useVideoContext();
  // @ts-ignore
  const { URLRoomId, URLAccessToken } = useParams();
  const [step, setStep] = useState(Steps.LoadingStep);

  const [name, setName] = useState<string>(account?.name || '');

  const [mediaError, setMediaError] = useState<Error>();

  const [fetchingAccount, setFetchingAccount] = useState<boolean>(!!account?.id);
  const [fetchingGroup, setFetchingGroup] = useState(false);
  const [fetchingSurvey, setFetchingSurvey] = useState(false);
  const [survey, setSurvey] = useState<Survey | null>(null);
  const [surveySubmitted, setSurveySubmitted] = useState<boolean>(false);

  useEffect(() => {
    let stepName;
    switch (step) {
      case Steps.LoadingStep:
        stepName = 'loading';
        break;
      case Steps.AuthFailStep:
        stepName = 'auth fail';
        break;
      case Steps.MobileScreenStep:
        stepName = 'mobile block';
        break;
      case Steps.ThankYouStep:
        stepName = 'session participated';
        break;
      case Steps.JoinMeetingStep:
        stepName = 'join meeting';
        break;
      default:
        stepName = 'unrecognized';
        break;
    }
    track(`view ${stepName} screen`);
  }, [step]);

  const fetchAccount = useCallback(
    async (token: string) => {
      mainApi?.setAuthToken(token);
      chatApi?.setAuthToken(token);
      setFetchingAccount(true);
      const res = await mainApi?.getAccount();
      if (res?.data) {
        setAccount(res.data);
        if (location.pathname.indexOf('/token') > -1) {
          const newPath = location.pathname.replace(`/token/${token}`, '');
          window.history.replaceState(null, '', newPath);
        }
        if (res?.kind === 'cannot-connect' || res?.kind === 'unauthorized') {
          setStep(Steps.AuthFailStep);
        }
      }
      if (res?.kind === 'cannot-connect' || res?.kind === 'unauthorized') {
        setStep(Steps.AuthFailStep);
      }
    },
    [mainApi, setAccount, location.pathname, chatApi]
  );

  const fetchGroup = useCallback(
    async (groupId: string) => {
      setFetchingGroup(true);
      const res = await mainApi?.getGroupDetails(groupId);
      if (res?.data) {
        setGroup(res.data);
      }
    },
    [mainApi, setGroup]
  );

  const fetchSessionSurvey = useCallback(
    async (groupId: string) => {
      setFetchingSurvey(true);
      const resSurvey = await mainApi?.getGroupSurvey(groupId);
      if (resSurvey?.survey) {
        setSurvey(resSurvey.survey);
      }
    },
    [mainApi]
  );

  useEffect(() => {
    if (mainApi?.isReady && !fetchingAccount && !isMobile) {
      // prevent fetching a token / account if the user is on mobile
      // or the account is already being fetched
      const token = URLAccessToken || window.localStorage.getItem('accessToken');
      if (typeof token === 'string') {
        fetchAccount(token);
      } else {
        setStep(Steps.AuthFailStep);
      }
    }
  }, [URLAccessToken, mainApi?.isReady, fetchAccount, fetchingAccount, isMobile]);

  useEffect(() => {
    if (account?.id && !endedByHost && !isMobile) {
      // prevent this step change if the user is on mobile
      // or the session was already ended by the host
      setStep(Steps.JoinMeetingStep);
      setName(account.name);
    }
  }, [account, endedByHost, isMobile]);

  useEffect(() => {
    if (URLRoomId && mainApi?.isAuthenticated && !group?.id && !fetchingGroup) {
      fetchGroup(URLRoomId);
    }
  }, [URLRoomId, mainApi?.isAuthenticated, fetchGroup, group, fetchingGroup]);

  useEffect(() => {
    if (group?.id && !fetchingSurvey) {
      const joinedSessions = window.localStorage.getItem('joinedSessions');
      // if the user was in the meeting for more then 10 minutes - ask the backend for the survey
      if (joinedSessions && moment().diff(moment(JSON.parse(joinedSessions)[group.id]), 'minutes') > 10) {
        fetchSessionSurvey(group.id);
      }
    }
  }, [group, fetchSessionSurvey, fetchingSurvey]);

  useEffect(() => {
    if (step === Steps.JoinMeetingStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  useEffect(() => {
    if (isMobile) {
      setStep(Steps.MobileScreenStep);
    }
  }, [isMobile]);

  useEffect(() => {
    if (endedByHost) {
      setStep(Steps.ThankYouStep);
    }
  }, [endedByHost]);

  const handleSurveyPopUpClose = () => {
    setSurvey(null);
  };

  const handleSurveySubmittedPopUpClose = () => {
    setSurveySubmitted(false);
  };

  const handleSurveyPopUpSubmit = (surveyAnswers: SurveyAnswer[]) => {
    const sendSurveyAnswers = async (groupInstanceId: string) => {
      await mainApi?.sendSurveyAnswers({
        sessionId: survey ? survey?.sessionId : '',
        pieceId: survey ? survey?.pieceId : '',
        groupInstanceId,
        surveyAnswers,
      });
      setSurveySubmitted(true);
      handleSurveyPopUpClose();
    };

    sendSurveyAnswers(group!.id);
  };

  const SubContent = (
    <>
      {!!Video.testPreflight && <PreflightTest />}
      <MediaErrorSnackbar error={mediaError} />
    </>
  );

  return (
    <IntroContainer subContent={step === Steps.JoinMeetingStep && SubContent}>
      {step === Steps.LoadingStep && (
        <div className={classes.loadingStepContainer}>
          <CircularProgress className={classes.loadingSpinner} />
        </div>
      )}
      {step === Steps.JoinMeetingStep && (
        <JoinMeetingScreen name={name} roomName={group?.groupTitle} roomId={group?.id} />
      )}
      {step === Steps.ThankYouStep && <ThankYouScreen group={group} />}
      {step === Steps.AuthFailStep && <AuthFailScreen />}
      {step === Steps.MobileScreenStep && <BlockMobileScreen isAndroid={isAndroid} storeUrl={storeUrl} />}
      {survey?.questions && (
        <SurveyPopUp
          survey={survey}
          groupInstanceId={group ? group.id : null}
          handleSurveyPopUpClose={handleSurveyPopUpClose}
          handleSurveyPopUpSubmit={handleSurveyPopUpSubmit}
        />
      )}
      {surveySubmitted && <SurveySubmittedPopUp handleSurveySubmittedPopUpClose={handleSurveySubmittedPopUpClose} />}
    </IntroContainer>
  );
}
