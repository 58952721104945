import React from 'react';

interface ScreenShareIconProps {
  color?: string;
  styleProps?: { [key: string]: string };
}

export default function ScreenShareIcon({ color = '#9E9E9E', styleProps }: ScreenShareIconProps) {
  return (
    <svg style={styleProps} width="25" height="19" viewBox="0 0 32 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.2168" y="1.7478" width="29.583" height="22.388" rx="1" stroke={color} strokeWidth="2" />
      <rect x="15.0078" y="7.79688" width="2" height="10.9807" fill={color} />
      <path d="M20.5219 11.62L16.0078 7.10596L11.4938 11.62" stroke={color} strokeWidth="2" />
    </svg>
  );
}
