import React from 'react';
import { Button as MuiButton } from '@material-ui/core';
import { ButtonProps } from './button.props';

import { translate } from '../../../i18n/translate';

export function Button(props: ButtonProps) {
  const { children, text, tx, txOptions, ...rest } = props;

  const i18nText = tx && translate(tx, txOptions);
  const content = i18nText || text || children;

  return <MuiButton {...rest}>{content}</MuiButton>;
}
