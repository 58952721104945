import { Schedule } from '../state/useGroup/useGroup';

const gapMs = 5 * 60 * 1000; // time before end of session considered as end
const weekMs = 7 * 24 * 60 * 60 * 1000;
const scheduleToMs = (s: Schedule) => s.weekday * 24 * 60 * 60 * 1000 + s.hour * 60 * 60 * 1000 + s.minute * 60 * 1000;

// return next session start and end time from schedule list
// weekdays [0:sun, mon, ..., 6:sat]
// schedule ex [{"weekday":3, "hour":7, "minute":30,"duration": 15}, ... ];

export const nextSession = (schedule: Schedule[], startFrom = 0) => {
  const WEEK_MS = 7 * 24 * 60 * 60 * 1000;
  const n = new Date();
  const nowMs = n.getTime();

  // normalize date to 0 ms of current day
  n.setUTCHours(0);
  n.setUTCMinutes(0);
  n.setUTCSeconds(0);
  n.setUTCMilliseconds(0);
  const weekStart = n.getTime() - n.getUTCDay() * 24 * 60 * 60 * 1000;

  const weekSchedule: any = {};
  for (let i = 0; i < schedule.length; i++) {
    const durationMs = schedule[i].duration * 60 * 1000;
    let sessionStart = weekStart + scheduleToMs(schedule[i]);
    if (sessionStart + durationMs - gapMs < nowMs) {
      sessionStart += weekMs;
    }
    weekSchedule[sessionStart] = sessionStart + durationMs;
  }

  const nextStart = Math.min(Number(...Object.keys(weekSchedule)));

  let additionalMs = 0;
  while (typeof startFrom === 'number' && nextStart + additionalMs < startFrom) {
    additionalMs += WEEK_MS;
  }

  return [nextStart + additionalMs, weekSchedule[nextStart] + additionalMs];
};
