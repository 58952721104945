import React from 'react';

export default function MicOffIcon({ color }: { color: string }) {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.2772 16.8826V16.4393L11.8371 16.3862C9.41152 16.0938 7.50635 14.1091 7.33442 11.648H7.63387C7.81959 14.1518 9.90939 16.125 12.4607 16.125C15.0121 16.125 17.1019 14.1518 17.2876 11.648H17.587C17.4136 14.1303 15.4769 16.1268 13.0218 16.3941L12.5759 16.4427V16.8912V18.6324V19.1324H13.0759H15.5439C15.5469 19.1447 15.5491 19.1603 15.5491 19.1792V19.4311H9.3723V19.1792C9.3723 19.1603 9.37456 19.1447 9.37754 19.1324H11.7772H12.2772V18.6324V16.8826ZM14.8656 11.3189C14.8656 12.6229 13.7947 13.6896 12.4607 13.6896C11.1267 13.6896 10.0558 12.6229 10.0558 11.3189V7.49123C10.0558 6.1872 11.1267 5.12049 12.4607 5.12049C13.7947 5.12049 14.8656 6.1872 14.8656 7.49123V11.3189Z"
        stroke={color}
      />
      <rect
        x="5.64355"
        y="4.74017"
        width="20.283"
        height="1.20021"
        rx="0.600106"
        transform="rotate(45 5.64355 4.74017)"
        fill={color}
      />
    </svg>
  );
}
